import React from "react";
import _ from "lodash";

const CarouselArrow = (props = {}) => {
  const arrowProps = _.omit(props, "currentSlide", "slideCount");
  arrowProps.className +=
    arrowProps.direction === "left" ? " s-left-arrow" : " s-right-arrow";
  arrowProps.src = require("keyboard-arrow-right.svg").default;

  return <img {...arrowProps} />;
};

export default CarouselArrow;
