import React, { useEffect } from "react";

import { declOfNum } from "utils";
import {
  isWidgetMode,
  onHeightChange,
  scrollWindow,
  onScrollTo,
} from "utils/frameService";
import { useWindowSize } from "utils/windowSizeHook";

import Car from "components/DealersPage/Car";

import "./styles.less";
import { getLocalTextWithNumbers } from "app/utils/i18nService";

const MobileSelectedDealer = ({ dealer, setPopupSettings }) => {
  useEffect(() => {
    if (isWidgetMode) {
      let element = document.getElementsByClassName(
        "selected-dealer_container",
      )[0];
      if (element) {
        const newHeight = element.scrollHeight + 50;
        onHeightChange(
          newHeight < window.innerHeight ? window.innerHeight : newHeight,
        );
      }
    }

    return () => {
      scrollWindow(0);
      if (isWidgetMode) {
        let element = document.getElementById("main");
        if (element) {
          const newHeight = element.scrollHeight + 50;
          onHeightChange(
            newHeight < window.innerHeight ? window.innerHeight : newHeight,
          );
        }
        onScrollTo(0);
      }
    };
  }, []);

  return (
    <div className="selected-dealer_container">
      <div className="header">
        <div className="label">{dealer.node.name}</div>
        <div className="count-cars">
          {getLocalTextWithNumbers("${variants} ${changeableText}", [
            {
              id: "variants",
              value: dealer.variants.length,
            },
            {
              id: "changeableText",
              value: declOfNum(dealer.variants.length, [
                "Предложение",
                "Предложения",
                "Предложений",
              ]),
            },
          ])}
        </div>
      </div>
      <div className="cars-list">
        {dealer.variants.map(car => {
          return (
            <Car
              car={car}
              setPopupSettings={setPopupSettings}
              key={`${car.picture}_${car.year}_${car.price.low}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MobileSelectedDealer;
