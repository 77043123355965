import React from "react";
import Link from "components/ui/Link";

import TextButton from "components/ui/TextButton";

import "./styles.less";

const LinkButton = ({
  disabled = false,
  onClick = null,
  url = null,
  ...props
}) => {
  return (
    <Link
      className="link-button"
      to={url}
      onClick={(event) => {
        if (!disabled && onClick) {
          onClick(event);
        }
      }}
    >
      <TextButton disabled={disabled} {...props} />
    </Link>
  );
};

export default LinkButton;
