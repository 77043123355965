import React from "react";

import { REQUEST_STATUS } from "app/constants";

import graphqlClient from "app/graphql/services/client";
import { getOfferDetail, getOfferDetailPrices } from "app/graphql/queries";
import { getLocalText } from "app/utils/i18nService";

export const OfferContext = React.createContext();

class Offer extends React.Component {
  state = {
    offer: this.props.offer || {},
    status: this.props.status || REQUEST_STATUS.NONE,
  };

  resetStatus = () => {
    this.setState({ status: REQUEST_STATUS.NONE });
  };

  getOffer = vars => {
    this.setState({ status: REQUEST_STATUS.LOADING });
    loadOffer(vars).then(
      result => {
        this.setState(result);
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  getOfferPrices = vars => {
    loadOfferPrices(vars, this.state.offer).then(
      result => {
        this.setState(result);
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  render() {
    return (
      <OfferContext.Provider
        value={{
          ...this.state,
          functions: {
            getOffer: this.getOffer,
            getOfferPrices: this.getOfferPrices,
            resetStatus: this.resetStatus,
          },
        }}
      >
        {this.props.children}
      </OfferContext.Provider>
    );
  }
}

export const loadOffer = vars => {
  return new Promise((resolve, reject) => {
    graphqlClient.fetch(getOfferDetail, vars).then(
      response => {
        if (!response.prices) {
          console.log(
            `[ERROR] Get offer detail with vars "${JSON.stringify(vars)}"`,
          );
        }

        const node = response.nodes[0];
        if (node) {
          const priceAndCount = response.prices[0].offers;
          node.offers = {
            ...node.offers,
            ...priceAndCount,
          };
          const result = {
            offer: {
              ...node,
              title: `${node.brand.name} ${getLocalText(
                node.model.name,
              )} ${getLocalText(node.versionName)}`,
              modificationName: node.modificationName
                ? node.modificationName.replace("0.0", "")
                : null,
              similarTotalCount: node.similar.totalCount,
              similarExpressions: node.similar.expressions,
              competitors:
                node.offers.competitors && node.offers.competitors.edges
                  ? {
                      edges: node.offers.competitors.edges.filter(
                        competitor => {
                          return !!competitor.node;
                        },
                      ),
                    }
                  : null,
            },
            status: REQUEST_STATUS.SUCCESS,
          };
          resolve(result);
        }

        resolve({
          status: REQUEST_STATUS.FAIL,
        });
      },
      () => {
        reject();
      },
    );
  });
};
export const loadOfferPrices = (vars, offer) => {
  return new Promise((resolve, reject) => {
    graphqlClient.fetch(getOfferDetailPrices, vars).then(
      response => {
        const priceAndCount = response.prices[0].offers;
        offer.offers = {
          ...offer.offers,
          ...priceAndCount,
        };
        const result = {
          offer,
          status: REQUEST_STATUS.SUCCESS,
        };
        resolve(result);
      },
      () => {
        reject();
      },
    );
  });
};

export const OfferProvider = Offer;
