import React, { useContext } from "react";

import { FiltersContext } from "app/context/Filters";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

const MobileFiltersLabel = props => {
  const { functions: filtersContextFunctions } = useContext(FiltersContext);
  const { filtersNumber, filters, filtersLocation } = props;

  const toggleFilters = () => {
    const { onClick } = props;
    filtersContextFunctions.changeState(
      {
        previousFilters: filters,
      },
      filtersLocation,
    );
    filtersContextFunctions.changeState(null, null, { isPopupShown: true });
    onClick();
  };

  return (
    <div className="c-mobile-filters-label" onClick={() => toggleFilters()}>
      <div className="s-icon">
        <i className="icon icon-filter-default" />
      </div>

      <div className="s-label">{getLocalText("Фильтры")}</div>

      {filtersNumber > 0 && (
        <div className="s-count">
          <span>{filtersNumber}</span>
        </div>
      )}
    </div>
  );
};

export default MobileFiltersLabel;
