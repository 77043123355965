import React from "react";

import { setContextCookie } from "utils/cookieService";
import graphqlClient from "app/graphql/services/client";
import { getCompareData, getCompareSpecification } from "app/graphql/queries";
import { REQUEST_STATUS } from "app/constants";

export const CompareContext = React.createContext();

class Compare extends React.Component {
  state = {
    carsIdList: this.props.carsIdList || [],
    carsList: this.props.carsList || [],
    specificationsList: this.props.specificationsList || [],
    status: this.props.status || REQUEST_STATUS.NONE,
  };

  getCarsList = vars => {
    this.setState({ status: REQUEST_STATUS.LOADING });
    loadCars(vars).then(
      response => {
        this.setState({
          carsList: response,
          status: REQUEST_STATUS.SUCCESS,
        });
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  addToCarsIdList = carId => {
    let { carsIdList } = this.state;
    carsIdList.push(carId);
    setContextCookie({ compare: { carsIdList } });
    this.setState({
      carsIdList: carsIdList,
    });
  };

  removeFromCarsIdList = carId => {
    let { carsIdList, carsList } = this.state;
    carsList = carsList.filter(car => {
      return car.id !== carId;
    });
    carsIdList = carsIdList.filter(id => {
      return id !== carId && id !== carId + "==";
    });
    setContextCookie({ compare: { carsIdList } });
    this.setState({
      carsIdList: carsIdList,
      carsList: carsList,
    });
  };

  removeAllFromCarsIdList = () => {
    setContextCookie({ compare: { carsIdList: [] } });
    this.setState({
      carsIdList: [],
      carsList: [],
    });
  };

  initCompareData = getCarsVars => {
    this.setState({ status: REQUEST_STATUS.LOADING });
    Promise.all([loadCars(getCarsVars), loadSpecification()])
      .then(responsesList => {
        this.setState({
          carsList: responsesList[0],
          specificationsList: responsesList[1].specification,
          status: REQUEST_STATUS.SUCCESS,
        });
      })
      .catch(() => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      });
  };

  resetStatus = () => {
    this.setState({ status: REQUEST_STATUS.NONE });
  };

  render() {
    return (
      <CompareContext.Provider
        value={{
          ...this.state,
          functions: {
            getCarsList: this.getCarsList,
            initCompareData: this.initCompareData,
            addToCarsIdList: this.addToCarsIdList,
            removeFromCarsIdList: this.removeFromCarsIdList,
            removeAllFromCarsIdList: this.removeAllFromCarsIdList,
            resetStatus: this.resetStatus,
          },
        }}
      >
        {this.props.children}
      </CompareContext.Provider>
    );
  }
}

export const loadCars = vars => {
  return new Promise(async resolve => {
    let result = await graphqlClient.fetch(getCompareData, vars);
    result = result.nodes.filter(item => !!item);

    resolve(result);
  });
};

export const loadSpecification = () => {
  return graphqlClient.fetch(getCompareSpecification);
};

export const CompareProvider = Compare;
