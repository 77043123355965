import React, { useState } from "react";
import { withRouter } from "react-router";

import graphqlClient from "app/graphql/services/client";
import { sendEquipmentRequestInput } from "app/graphql/mutations";

import {
  parseUrlToFilter,
  getQueryParams,
  getUpdatedExpressionValuesString,
} from "utils";
import { isWidgetMode, parentUrl } from "utils/frameService";

import IconButton from "components/ui/IconButton";
import TextButton from "components/ui/TextButton";
import NumberOfRemain from "components/popup/NumberOfRemain";
import BookForm from "components/popup/BookForm";

import "./popup.less";
import { getLocalText } from "app/utils/i18nService";
import Loading from "components/Loading";

let formIsSubmitted = false;
const Popup = ({
  equipmentId,
  selectedDealersCollection,
  hidePopup,
  location,
}) => {
  const [state, setState] = useState({
    error: false,
    isSubmitted: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getExpressions = () => {
    const query = getQueryParams(location.search);

    if (query && query.expressions) {
      return query.expressions.split(";");
    }

    return [];
  };

  const submitForm = data => {
    if (!formIsSubmitted) {
      formIsSubmitted = true;
      const expressions = getExpressions();
      const filters = parseUrlToFilter(location.pathname, location.search);
      const expressionsString = getUpdatedExpressionValuesString(
        "1002",
        selectedDealersCollection.map(dealer => dealer.databaseId),
        location.search,
      );

      const variables = {
        input: {
          email: data.email || "",
          equipment: equipmentId,
          dealers: selectedDealersCollection.map(dealer => dealer.id),
          name: data.name,
          phoneNumber: data.phone,
          comment: data.comment || "",
          expressions,
          webViewLink: `${
            isWidgetMode
              ? parentUrl
              : `${window.location.protocol}//${window.location.host}`
          }/${filters.region ? filters.region + "/" : ""}cars/new/${
            filters.brand ? filters.brand + "/" : ""
          }${filters.model ? filters.model + "/" : ""}${filters.version ||
            ""}/offers${
            expressionsString ? `?expressions=${expressionsString}` : ""
          }`,
        },
      };

      setIsLoading(true);

      graphqlClient
        .fetch(sendEquipmentRequestInput, variables)
        .then(() => setState({ ...state, isSubmitted: true }))
        .catch(() => setState({ ...state, error: true }))
        .finally(() => {
          formIsSubmitted = false;
          setIsLoading(false);
        });
    }
  };

  const { error, isSubmitted } = state;

  let popupContent;

  if (isLoading) {
    popupContent = (
      <div className="s-wrapper">
        <IconButton icon="icon-close-bold" onClick={hidePopup} />
        <div className="loading_container">
          <img src={require("loader.gif").default} />
        </div>
      </div>
    );
  }

  if (!isSubmitted && !error && !isLoading) {
    popupContent = (
      <div className="s-wrapper">
        <IconButton icon="icon-close-bold" onClick={hidePopup} />
        <div>
          <div className="s-text">
            <div className="s-title">
              {getLocalText("Заявка на обратный звонок")}
            </div>

            <NumberOfRemain selectedDealers={selectedDealersCollection} />
          </div>

          <BookForm submitForm={submitForm} />
        </div>
      </div>
    );
  }

  if (error) {
    popupContent = (
      <div className="s-wrapper">
        <div className="s-text">
          <div className="s-title error">
            {getLocalText("Ошибка передачи данных.")}
            <br />
            {getLocalText(
              "Попробуйте снова отправить заявку на обратный звонок.",
            )}
          </div>

          <div className="s-error">
            <i className="icon icon-error" />
          </div>

          <TextButton
            text={getLocalText("Закрыть")}
            color="default"
            onClick={hidePopup}
          />
        </div>
      </div>
    );
  }

  if (isSubmitted) {
    popupContent = (
      <div className="s-wrapper">
        <div className="s-text">
          <div className="s-title success">
            {getLocalText("Спасибо!")}
            <br />
            {getLocalText("Ваша заявка успешно отправлена!")}
          </div>

          <div className="s-success">
            <i className="icon icon-success" />
          </div>

          <TextButton
            text={getLocalText("Закрыть")}
            color="default"
            onClick={hidePopup}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="popup active">
      <div className="overlay" onClick={hidePopup} />
      <div className="s-popup-content form">{popupContent}</div>
    </div>
  );
};

export default withRouter(Popup);
