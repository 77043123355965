import {
  dealer,
  filters,
  compare,
  offerDetailEquipment,
  region,
  offerDetailPrices,
} from "../fragments";

export const getOfferDetail = `
query ViewEquipment(
  $equipmentIds: [ID!]!,
  $expressions: [String!]!,
  $expressionsWithColors: [String!]!,
  $domain: Domain!,
  $geo: GeoInput
) {
  nodes(ids: $equipmentIds) {
    ... on Equipment {
      ${offerDetailEquipment}
    }
  }
  prices: nodes(ids: $equipmentIds) {
    ... on Equipment {
      ${offerDetailPrices}
    }
  }
}
`;

export const getOfferDetailPrices = `
query ViewEquipment(
  $equipmentIds: [ID!]!,
  $expressionsWithColors: [String!]!,
  $domain: Domain!,
  $geo: GeoInput
) {
  prices: nodes(ids: $equipmentIds) {
    ... on Equipment {
      ${offerDetailPrices}
    }
  }
}
`;

export const getCompareData = `
query ViewEquipment(
  $equipmentIds: [ID!]!,
  $domain: Domain!,
  $geo: GeoInput
) {
  nodes(ids: $equipmentIds) {
    ... on Equipment {
      ${compare}
      filters {
        i
        name
        value
        ins {
          description
        }
      }
    }
  }
}
`;

export const getDealersList = `
query ViewDealers(
  $equipmentId: ID!,
  $limit: Int!,
  $after: String!,
  $expressions: [String!],
  $domain: Domain!,
  $geo: GeoInput
) {
  node(id: $equipmentId) {
    ... on Equipment {
      id
      photo(height: 50)
      brand {
        name
      }
      model {
        name
        resourcePath
      }
      offers (expressions: $expressions, domain: $domain, geo: $geo, first:0) {
        colors {
          name
          code
        }
        price {
          low
          high
        }
        marketingPrice {
          low
          high
        }
        totalCount
        dealerCount
      }
      modificationName
      versionName
      dealers (first: $limit, after: $after, expressions: $expressions, domain: $domain, geo: $geo) {
        pageInfo {
          endCursor
        }
        edges {
          ${dealer}
        }
      }
      filter (expressions: $expressions, domain: $domain, geo: $geo) {
        ${filters}
      }
    }
  }
}
`;

export const getRegions = `
query getRegions ($query: String, $first: Int, $after: String, $corpus: RegionCorpus) {
  regions(query: $query, after: $after, first: $first, corpus: $corpus) {
    edges {
      node {
        ${region}
      }
    }
  }
}
`;

export const getPopularAndSelectedRegionsQuery = `
query getRegions ($query: String, $first: Int, $after: String, $corpus: RegionCorpus, $geo: GeoInput, $geoPath: String, $ids: [ID!]!, $filtersIds: [ID!]!) {
  favorite: regions(query: $query, after: $after, first: $first, corpus: $corpus) {
    edges {
      node {
        ${region}
      }
    }
  }
  fromUrl: context(geoPath: $geoPath, geo: $geo) {
    region {
      ${region}
    }
  }
  fromCookie: nodes(ids: $ids) {
    ... on Region {
      ${region}
    }
  }
  fromFilters: nodes(ids: $filtersIds) {
    ... on Region {
      ${region}
    }
  }
}
`;

export const getOffersList = `
query context(
  $after: String,
  $expressions: [String!],
  $domain: Domain!,
  $geo: GeoInput,
  $geoPath: String,
  $filterSet: FilterSet,
  $resourcePath: String,
  $sortBy: CatalogOrder,
  $sortDir: OrderDirection,
) {
  context(resourcePath: $resourcePath, geoPath: $geoPath, expressions: $expressions, domain: $domain, geo: $geo) {
    search(
      after: $after,
      sortBy: $sortBy,
      sortDir: $sortDir
    ) {
      offerCount
      dealerCount
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          ... on Equipment {
            id
            photo(width: 220)
            brand {
              name
            }
            model {
              name
              resourcePath
            }
            interior
            offers (expressions: $expressions, domain: $domain, geo: $geo, first:0) {
              colors {
                hex
              }
              price {
                low
                high
              }
              marketingPrice {
                low
                high
              }
              totalCount
              dealerCount
            }
            modificationName
            versionName
            headline
          }
        }
      }
    }
    filters(set: $filterSet) {
      ${filters}
    }
  }
}
`;

export const getOffersCount = `
query context(
  $after: String,
  $expressions: [String!],
  $domain: Domain!,
  $geo: GeoInput,
  $geoPath: String,
  $filterSet: FilterSet,
  $resourcePath: String,
  $sortBy: CatalogOrder,
  $sortDir: OrderDirection,
) {
  context(resourcePath: $resourcePath, geoPath: $geoPath, expressions: $expressions, domain: $domain, geo: $geo) {
    search(
      after: $after,
      sortBy: $sortBy,
      sortDir: $sortDir
    ) {
      offerCount
      dealerCount
      totalCount
    }
    filters(set: $filterSet) {
      ${filters}
    }
  }
}
`;

export const getCompareSpecification = `
query {
  specification {
    name
    features(is: COMPARABLE) {
      i
      name
    }
  }
}
`;

export const getOfferById = `
query context($slug: String!) {
  offer(slug: $slug) {
    expressions
    edition {
      id
      model {
        resourcePath
      }
      filters {
        i
        enum
      }
    }
  }
}
`;

export const getMainPageData = `
query context($geo: GeoInput, $expressions: [String!], $domain: Domain!) {
  carsData: context(expressions: $expressions, domain: $domain, geo: $geo) {
    search {
      totalCount
      offerCount
    }
    filters(custom: ["1101", "1201"], set: CUSTOM) {
      id
      __typename
      ... on NumberFilter {
        min
        max
      }
      ... on EnumFilter {
        multiple
        depend
        values {
          id
          name
          count
          offerFound
          checked
          slug
        }
      }
    }
  }
  statistic(domain: $domain)  {
    regionCount
    dealerCount
  }
}
`;

export const getWidgetOffersCount = `
query context($expressions: [String!], $domain: Domain!) {
  carsData: context(expressions: $expressions, domain: $domain) {
    search {
      offerCount
    }
  }
}
`;
