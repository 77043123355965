import React, { useContext, useEffect } from "react";

import { loadMainPageData, MainPageContext } from "app/context/Main";
import { getPopularAndSelectedRegions } from "app/context/Regions";

import Header from "components/Header";
import Footer from "components/Footer";
import Seo from "components/Seo";
import { REQUEST_STATUS } from "app/constants";

const TITLE = "Информация для официальных дилеров";
const DESCRIPTION = "";

const ForDealersPage = () => {
  const { status, functions } = useContext(MainPageContext);

  useEffect(() => {
    if (status !== REQUEST_STATUS.SUCCESS) {
      const vars = {
        expressions: [],
        geo: null,
        domain: {},
      };
      functions.getData(vars);
    } else {
      functions.resetStatus();
    }
  }, []);

  return (
    <div className="skeleton c-static-page">
      <Seo description={DESCRIPTION} title={TITLE} />
      <Header />
      <div id="main" className="content">
        <h1>О компании ILSA</h1>

        <p className="content--row">
          <a
            href="https://ilsa.ru/"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            ILSA
          </a>{" "}
          &mdash;&nbsp;
          <b>I</b>nnovative&nbsp;<b>L</b>eading /&nbsp;<b>S</b>ource of&nbsp;
          <b>A</b>utomobiles (инновационный лидер, первоисточник информации об
          автомобилях)
        </p>

        <p className="content--row">
          Основная наша цель &mdash;{" "}
          <strong>давать клиентов и продажи, а не рекламу</strong>. И с этим мы
          хорошо справляемся, работая в нескольких направлениях.
        </p>

        <div className="content--row">
          <ol>
            <li>
              С нами работает более <strong>700 официальных дилеров</strong> и
              более <strong>200 дилеров параллельного импорта</strong>.
            </li>

            <li>
              Мы используем <strong>высокоточные заводские каталоги</strong>{" "}
              комплектаций, учитывающие даже технологические конвейерные опции
              производителей. Мы поддерживаем в каталоге более 80 марок (в т.ч.
              более 30 марок из Китая).
            </li>

            <li>
              Мы принимаем данные от дилеров{" "}
              <strong>в любом удобном формате</strong>. Интеграция возможна{" "}
              <strong>за один день</strong>. В результате мы формируем
              высококачественные объявления, отслеживаем логистические статусы
              автомобилей, изменение цен и скидок на конкретные автомобили в
              наличии. Дилерам не надо заполнять текст объявления, это делаем
              мы.
            </li>

            <li>
              Мы размещаем информацию{" "}
              <strong>на ведущих автомобильных интернет-порталах</strong>{" "}
              федерального уровня (auto.ru, avito.ru, drom.ru, autospot.ru,
              sberauto.ru и др.)
            </li>

            <li>
              На базе собственного API (graphql) настроена{" "}
              <strong>интеграция с ведущими лизинговыми компаниями</strong>{" "}
              (Европлан, Сберлизинг, Каркаде, Альфализинг, Балтийский лизинг,
              Газпромбанк Автолизинг, ВТБ Лизинг, Тинькофф, Развивай.рф).
            </li>

            <li>
              Как <strong>разработчик и интегратор IT-решений </strong> мы
              обеспечиваем дилерам возможность показывать автомобили в продаже
              <ul>
                <li>
                  на собственных сайтах, в том числе{" "}
                  <strong>
                    с онлайн-подтверждением наличия и бронирования
                  </strong>
                  ;
                </li>
                <li>
                  в социальных сетях (приложения для Facebook и ВКонтакте);
                </li>
                <li>в мобильных приложениях;</li>
                <li>в рекламных системах (Я.Директ, Я.Бизнес).</li>
              </ul>
            </li>
          </ol>
        </div>

        <div className="content--row">
          <a
            href="https://media.ilsa.ru/ILSA_Presentation.pdf"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Презентация компании ILSA
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

ForDealersPage.fetchData = (dataContext, match, request) => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(null, null, selectedRegions).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };
      const vars = {
        expressions: [],
        geo: null,
        domain: {},
      };

      loadMainPageData(vars)
        .then(response => {
          const brandFilter = response.carsData.filters.find(
            filter => filter.id === "1101",
          );
          resolve({
            isDataContext: true,
            regions,
            mainPage: {
              dealersCount: response.statistic.dealerCount,
              brandsCount: brandFilter.values.length,
              regionsCount: response.statistic.regionCount,
              offerCount: response.carsData.search.offerCount,
              status: REQUEST_STATUS.SUCCESS,
            },
          });
        })
        .catch(() => {
          reject();
        });
    });
  });
};

export const ForDealersPageSSRLoad = ForDealersPage.fetchData;
export default ForDealersPage;
