import React, { forwardRef } from "react";
import classNames from "classnames";

import { declOfNum } from "utils";
import { useWindowSize } from "app/utils/windowSizeHook";

import Color from "components/ui/Color";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const ColorPicker = ({
  className,
  colors,
  selected,
  onChange,
  onResetSelectedColors,
}) => {
  const { isTabletWidth } = useWindowSize();
  const isDoubleColorSelect = selected.some(
    color => colors.filter(item => color === item.key).length > 1,
  );
  const selectedLength = selected.length + (isDoubleColorSelect ? 1 : 0);
  colors = colors.filter(
    (color, index) =>
      !colors.some(
        (item, index2) =>
          item.name === color.name &&
          item.code !== color.code &&
          index2 > index,
      ),
  ); //Grouping colors with same name and different code

  return (
    <div className={classNames("color-picker", className)}>
      <div className="label">
        <span className="main-label">{getLocalText("Цвет кузова")}:</span>
        <span
          className={
            isTabletWidth
              ? "selected-colors-label mobile"
              : "selected-colors-label"
          }
        >
          {selectedLength === 0
            ? getLocalText("Неважно")
            : getLocalTextWithNumbers(
                "${changeableText} ${selectedLength} ${changeableText2}",
                [
                  {
                    id: "selectedLength",
                    value: selectedLength,
                  },
                  {
                    id: "changeableText",
                    value: declOfNum(selectedLength, [
                      "Выбран",
                      "Выбрано",
                      "Выбрано",
                    ]),
                  },
                  {
                    id: "changeableText2",
                    value: declOfNum(selectedLength, [
                      "цвет",
                      "цвета",
                      "цветов",
                    ]),
                  },
                ],
              )}
        </span>
        {selectedLength !== 0 && (
          <span className="reset-icon-container">
            <i
              className="icon icon-clear"
              onClick={() => {
                onResetSelectedColors();
              }}
            />
          </span>
        )}
      </div>
      <div className="colors-list">
        {colors.map(color => (
          <Color
            key={color.key}
            tooltipContent={color.name}
            className={selected.some(code => code === color.key) && "selected"}
            hex={color.hex}
            onClick={() => {
              onChange(color.key);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
