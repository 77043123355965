import React from "react";
import IconButton from "components/ui/IconButton";

import "./styles.less";

const Popup = ({ title, children, hidePopup }) => {
  return (
    <div className="popup_container">
      <div className="overlay" onClick={() => hidePopup()} />
      <div className="popup-content">
        <div className="title">
          {title}
          <IconButton icon="icon-close-bold" onClick={() => hidePopup()} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popup;
