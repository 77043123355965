export default {
  common: {
    dealersContacts: "КОНТАКТЫ ДИЛЕРОВ",
    header: { emptyRegion: "Все регионы" },
    pricesBlock: {
      creditPriceLabel: "кредит от",
    },
  },
  main: {
    h1:
      "Продажа новых автомобилей в России, ориентировочные цены и наличие у официальных дилеров",
    h1Widget:
      "Продажа новых автомобилей в России, цены и наличие у официальных дилеров",
  },
  offersList: {
    searchInformationPrefix: "",
  },
  errors: {
    notFound: {
      title: "Страница не найдена",
      text: "Неправильно набран адрес или такая страница больше не существует",
      button: "Перейти на главную страницу",
    },
    internalServerError: {
      title: "Неопознанная ошибка",
      text: `Кажется, что-то сломалось,<br class="hide-on-desktop"/> возьмите паузу и попробуйте еще раз <br/> или сообщите об ошибке нам по адресу <a class="link" href="mailto:info@ilsa.ru">info@ilsa.ru</a>, и мы постараемся быстро её устранить`,
      button: "Перейти на главную страницу",
    },
  },
};
