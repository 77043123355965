import React, { useMemo } from "react";
import Select from "react-select";

import { DEFAULT_SORTING_OPTIONS } from "app/constants";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

const Sorting = ({ value, onChange }) => {
  const options = useMemo(() =>
    DEFAULT_SORTING_OPTIONS.map(
      item => ({ ...item, label: getLocalText(item.label) }),
      [DEFAULT_SORTING_OPTIONS],
    ),
  );
  return (
    <div className="sorting">
      <Select
        instanceId={"react-select-input"}
        classNamePrefix={"select"}
        value={options.find(option => option.value === value)}
        options={options}
        onChange={item => onChange(item.value)}
        isSearchable={false}
        isClearable={false}
      />
    </div>
  );
};

export default Sorting;
