import React, { useEffect } from "react";

import {
  getOfferPageUrl,
  getDealersPageUrl,
  getMapPageUrl,
  getUpdatedUrlSearch,
} from "utils";
import { scrollWindow } from "app/utils/frameService";

import Link from "components/ui/Link";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

const tabs = [
  {
    id: "short",
    label: "Краткая комплектация",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getOfferPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        getUpdatedUrlSearch("selectedTab", ["short"], search),
      );
    },
    onClick: (props, tab) => {
      props.changeTab(tab);
    },
  },
  {
    id: "full",
    label: "Полная комплектация",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getOfferPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        getUpdatedUrlSearch("selectedTab", ["full"], search),
      );
    },
    onClick: (props, tab) => {
      props.changeTab(tab);
    },
  },
  {
    id: "technical",
    label: "Характеристики",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getOfferPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        getUpdatedUrlSearch("selectedTab", ["technical"], search),
      );
    },
    onClick: (props, tab) => {
      props.changeTab(tab);
    },
  },
  {
    id: "prices",
    label: "Цены",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getDealersPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        search,
      );
    },
  },
  {
    id: "map",
    label: "Карта",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getMapPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        search,
      );
    },
  },
];

const Tabs = props => {
  const { offerId, offerPath, selectedRegion, active, search } = props;

  return (
    <div className="tabs">
      <div className="tabs-nav">
        {tabs.map(tab => {
          return (
            <Link
              key={tab.id}
              data-key={tab.id}
              className={active === tab.id ? "tab active" : "tab"}
              to={tab.linkTo(offerId, offerPath, selectedRegion, search)}
              onClick={() => {
                if (tab.onClick && props.changeTab) {
                  tab.onClick(props, tab.id);
                } else {
                  scrollWindow(0);
                }
              }}
            >
              <span>{getLocalText(tab.label)}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
