import React from "react";
import classNames from "classnames";

import "./styles.less";

const Loading = () => (
  <div className="c-loading active">
    <div className="s-content">
      <img src={require("loader.gif").default} />
    </div>
  </div>
);

export default Loading;
