import React from "react";
import classNames from "classnames";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

const CleanAllButton = ({
  itemsCount = 0,
  onClick,
  text = getLocalText("Сбросить всё"),
}) => {
  return (
    <div
      className={classNames("clean-btn", itemsCount !== 0 && "active")}
      onClick={() => {
        if (itemsCount !== 0) {
          onClick();
        }
      }}
    >
      {text}
    </div>
  );
};

export default CleanAllButton;
