import React, { useState, useEffect } from "react";
import { MOBILE_WIDTH_MAX, TABLET_WIDTH_MAX } from "app/constants";

export const useWindowSize = () => {
  const [widthState, setWidthState] = useState({
    isMobileWidth: false,
    isTabletWidth: true,
  });

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    updateWindowWidth();
  }, []);

  const updateWindowWidth = () => {
    let windowWidth = document.documentElement.clientWidth;
    setWidthState({
      isMobileWidth: windowWidth <= MOBILE_WIDTH_MAX,
      isTabletWidth: windowWidth <= TABLET_WIDTH_MAX,
    });
  };

  return widthState;
};
