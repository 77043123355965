import React from "react";

import {
  isWidgetMode,
  widgetConfig,
  getContactBtnUrl,
} from "utils/frameService";
import { getText } from "utils/i18nService";

import LinkButton from "components/ui/LinkButton";
import Link from "components/ui/Link";
import PricesBlock from "components/shared/PricesBlock";
import CreditButton from "components/shared/CreditButton";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

const Results = ({
  offer,
  dealersPageUrl,
  offerCountText,
}) => (
  <div className="s-results">
    <CreditButton
      offer={offer}
      dealersPageUrl={dealersPageUrl}
      lowPrice={offer.offers.price.low}
    >
      <LinkButton
        color="orange"
        text={getLocalText(getText("common.dealersContacts"))}
        url={dealersPageUrl}
      />
    </CreditButton>

    <div className="s-numbers">
      {isWidgetMode && widgetConfig && widgetConfig.contactBtn ? (
        <Link className="link" to={dealersPageUrl}>
          {offerCountText}
        </Link>
      ) : (
        offerCountText
      )}
    </div>
    <PricesBlock
      price={offer.offers.price}
      marketingPrice={offer.offers.marketingPrice}
      onlyCreditPrice={true}
    />
  </div>
);
export default Results;
