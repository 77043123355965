import React from "react";
import classNames from "classnames";
import { getLocalText } from "app/utils/i18nService";

class ParametersCategory extends React.Component {
  state = {
    openDescription: false,
  };

  changeDescriptionState = isNotDisable => {
    if (isNotDisable) {
      this.setState({ openDescription: !this.state.openDescription });
    }
  };

  render() {
    const { openDescription } = this.state;
    const { category, pageOffset } = this.props;

    return (
      <div className="category_container">
        <div className="category-name_container" style={{ left: pageOffset }}>
          <span className="category-name">{getLocalText(category.name)}</span>
        </div>
        <div className="parameters-list_container">
          {category.values.map(item => {
            return (
              <div
                className={classNames(
                  "parameters_container",
                  category.values.length === 1 && "single",
                )}
                key={item.key}
              >
                {item.value ? (
                  <>
                    <span
                      className={classNames(
                        "value",
                        item.description && "active",
                      )}
                      onClick={() => {
                        this.changeDescriptionState(item.description);
                      }}
                    >
                      {getLocalText(item.value)}
                    </span>
                    <div className="description_container">
                      {item.description &&
                        openDescription &&
                        item.description.map((text, index) => {
                          return (
                            <div className="description" key={item.key + index}>
                              {`• ${getLocalText(text)}`}
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <div className="empty-value">
                    {getLocalText("нет данных")}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ParametersCategory;
