import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";

import { LOCAL_STORAGE_PHONENUMBER_KEY, REQUEST_STATUS } from "app/constants";

import { RegionsContext, getPopularAndSelectedRegions } from "context/Regions";
import { OffersListContext } from "app/context/OffersList";
import { FiltersContext, getFiltersData } from "app/context/Filters";
import { DealersContext, loadDealers } from "context/Dealers";

import {
  getQueryStringValueFromLocation,
  removeURLParameter,
  parseUrlToFilter,
  getSelectedRegionName,
  getDealersPageUrl,
  getMapPageUrl,
  getUpdatedUrlSearch,
  getExpressionValue,
  generateCorrectUrl,
  declOfNum,
  getContextRequestParamsFromFilters,
  generateQueryString,
} from "utils";
import {
  isWidgetMode,
  widgetConfig,
  getReturnToContactsModeEnable,
  onSessionStorageChange,
  scrollWindow,
  onScrollTo,
} from "utils/frameService";
import {
  getDefaultDomainFilters,
  getDefaultExpressionsAndRegions,
} from "app/utils/filtersService";

import Filters from "components/shared/Filters";
import PriceCard from "components/shared/PriceCard";
import List from "components/DealersPage/List";
import Map from "components/DealersPage/Map";
import MobileSelectedDealer from "components/DealersPage/MobileSelectedDealer";
import Header from "components/Header";
import Footer from "components/Footer";
import Loading from "components/Loading";
import CallbackPopup from "components/popup/popup";
import Seo from "components/Seo";
import MobileFiltersLabel from "components/shared/MobileFiltersLabel";
import SelectedDealers from "components/shared/SelectedDealers";
import IconButton from "components/ui/IconButton";
import Popup from "components/ui/Popup";
import AddToCompareButton from "components/shared/AddToCompareButton";
import Tabs from "components/shared/Tabs";

import "./styles.less";
import moment from "moment";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";
import NotFoundPage from "pages/not-found";

let prevMobileFiltersResults = null;
let regionWasChangedInFilterPopup = false;
let savedScrollPosition = 0;

const DealersPage = props => {
  const { regions, radius, selectedRegions } = useContext(RegionsContext);
  const {
    dealers: dealersFilters,
    isPopupShown,
    functions: filtersContextFunctions,
  } = useContext(FiltersContext);
  const {
    dealers,
    equipment,
    status,
    functions: dealersContextFunctions,
  } = useContext(DealersContext);
  const { sorting } = useContext(OffersListContext);
  const [mobileSelectedDealer, setMobileSelectedDealer] = useState(null);
  const [selectedDealersIdList, setSelectedDealersIdList] = useState(
    getQueryStringValueFromLocation("selectedDealers", props.location.search)
      ? getQueryStringValueFromLocation(
          "selectedDealers",
          props.location.search,
        ).split(",")
      : [],
  );
  const [popupSettings, setPopupSettings] = useState({
    show: false,
    title: null,
  });
  const [callbackPopupVisible, setCallbackPopupVisible] = useState(false);

  useEffect(() => {
    const { history, location } = props;

    history.replace(
      generateCorrectUrl(location, regions, selectedRegions, radius),
    );

    scrollWindow(0);

    if (status === "NONE") {
      getDealersListFromServer(false, true);
    }

    if (isWidgetMode && getReturnToContactsModeEnable()) {
      onSessionStorageChange("ilsa_ReturnToContactsModeEnable", "true");
    }

    if (!isWidgetMode) {
      let phoneNumbersList =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_PHONENUMBER_KEY)) || [];
      if (phoneNumbersList.length > 0) {
        phoneNumbersList = phoneNumbersList.filter(
          item => !moment().isAfter(moment(item.expireTime)),
        );
        localStorage.setItem(
          LOCAL_STORAGE_PHONENUMBER_KEY,
          JSON.stringify(phoneNumbersList),
        );
      }
    }

    //componentWillUnMount
    return () => {
      dealersContextFunctions.resetStatus();
      filtersContextFunctions.dropFilters("dealers");
    };
  }, []);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      getDealersListFromServer(false, true);
      if (isPopupShown) {
        regionWasChangedInFilterPopup = true;
      }
    } else {
      didMountRef.current = true;
    }
  }, [selectedRegions, radius]);

  useEffect(() => {
    if (didMountRef.current && mobileSelectedDealer === null) {
      scrollWindow(savedScrollPosition);
      if (isWidgetMode) {
        onScrollTo(savedScrollPosition);
      }
    }
  }, [mobileSelectedDealer]);

  const onBookDealer = (equipmentId, selectedDealers, title) => {
    setCallbackPopupVisible(true);
  };

  const onSelectDealer = dealerId => {
    const { history } = props;
    const isSelected = selectedDealersIdList.some(id => id === dealerId);
    let newSelectedDealersIdList = selectedDealersIdList;
    if (isSelected) {
      newSelectedDealersIdList = newSelectedDealersIdList.filter(
        id => id !== dealerId,
      );
    } else {
      newSelectedDealersIdList.push(dealerId);
    }
    let search = getUpdatedUrlSearch(
      "selectedDealers",
      newSelectedDealersIdList,
    );
    history.replace(search);
    setSelectedDealersIdList(newSelectedDealersIdList);
  };

  const getDealersListFromServer = requestParamsFromFilters => {
    const { history, location } = props;
    const filters = parseUrlToFilter(location.pathname, location.search);

    const requestParams = {
      after: "null",
      expressions: filters.expressions ? filters.expressions : [],
      geo: Object.assign(
        { ids: selectedRegions },
        radius ? { radius: radius } : {},
      ),
      equipmentId: filters.version,
    };

    const defaultExpressions = getDefaultExpressionsAndRegions(
      requestParamsFromFilters
        ? requestParamsFromFilters.expressions
        : requestParams.expressions,
    );
    requestParams.expressions = defaultExpressions.expressions;
    requestParams.domain = getDefaultDomainFilters();

    if (requestParamsFromFilters && requestParamsFromFilters.expressions) {
      let search = `?${generateQueryString({
        filters: requestParamsFromFilters.expressions.map(param => {
          const parsedParam = param.split("=");
          return {
            id: parsedParam[0],
            value: parsedParam[1],
          };
        }),
        ...(radius && radius !== 200 ? { radius } : {}),
        ...(selectedDealersIdList.length > 0
          ? { selectedDealers: selectedDealersIdList.join(",") }
          : {}),
      })}`;

      history.replace(search);
    }

    dealersContextFunctions.getDealersList(
      requestParams,
      filtersContextFunctions.setFiltersData,
    );
  };

  const unSelectAllDealers = () => {
    const { location } = props;

    const newLocation =
      removeURLParameter(location.search, "selectedDealers") ||
      location.pathname;
    props.history.replace(newLocation);
    setSelectedDealersIdList([]);
  };

  const onBackButtonClick = () => {
    filtersContextFunctions.changeState(
      { filters: dealersFilters.previousFilters },
      "dealers",
      { isPopupShown: false },
    );
    const requestParams = getContextRequestParamsFromFilters({
      filters: dealersFilters.previousFilters,
      sorting,
      selectedRegions,
      radius,
    });
    getDealersListFromServer(requestParams);

    scrollWindow(0);
    if (isWidgetMode) {
      onScrollTo(0);
    }
  };

  const openMobileDealerCarsList = selectedDealerId => {
    savedScrollPosition = window.scrollY;
    const selectedDealer = dealers.find(
      dealer => dealer.node.id === selectedDealerId,
    );
    setMobileSelectedDealer(selectedDealer);
    scrollWindow(0);
    if (isWidgetMode) {
      onScrollTo(0);
    }
  };

  const getSeoData = () => {
    // Title: Официальные дилеры-продавцы новых Lada Granta лифтбек Standard 1.6 87hp 5MT в России/Москве, цена от 415 055 руб
    // Description: Официальные дилеры-продавцы новых автомобилей Lada Granta лифтбек Standard 1.6 87hp 5MT в России/Москве, 1.6 механика (87 л.с.) бензин, передний привод, хэтчбек, цена от 415 055 руб. Заявка на обратный звонок. Контакты дилеров
    // H1: Официальные дилеры-продавцы новых автомобилей Lada Granta лифтбек Standard 1.6 87hp 5MT в России/Москве, цена от 415 055 руб

    if (!equipment) {
      return { title: "", description: "", h1: "" };
    }
    const { price } = equipment.offers;

    const carNameString = `${equipment.brand.name} ${getLocalText(
      equipment.model.name,
    )}${
      equipment.versionName ? " " + getLocalText(equipment.versionName) : ""
    }`;
    const carModelString = `${getLocalText(equipment.model.name)}${
      equipment.versionName ? " " + getLocalText(equipment.versionName) : ""
    }`;
    const region = getSelectedRegionName(regions, selectedRegions);
    const modificationName = equipment.modificationName
      ? `, ${getLocalText(equipment.modificationName)}`
      : "";
    const priceString = price && price.low ? `, цена от ${price.low} руб` : "";

    return {
      title: `Официальные дилеры новых ${carNameString} в ${region ||
        "России"}${priceString}`,
      description: `Официальные дилеры новых автомобилей ${carNameString} в ${region ||
        "России"}${modificationName}${priceString}. Заявка на обратный звонок. Контакты дилеров`,
      h1: `${getLocalText("Официальные дилеры")} ${
        equipment.brand.name
      } ${getLocalText("по продаже новых")} ${carModelString} ${getLocalText(
        "в",
      )} ${region || getLocalText("России")}`,
      canonical: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
    };
  };

  const { history, location, match } = props;

  const selectedDealersCollection = dealers
    .filter(dealer => selectedDealersIdList.indexOf(dealer.node.id) > -1)
    .map(dealer => ({
      id: dealer.node.id,
      databaseId: dealer.node.databaseId,
      name: dealer.node.name,
    }));

  const numberOfSelectedRegions = selectedRegions.length;
  const selectedRegion =
    numberOfSelectedRegions === 1
      ? regions.find(region => selectedRegions[0] === region.id).resourcePath
      : "";

  const selectedFilters = dealersFilters.filters.filter(f => f.selected);

  const seoData = getSeoData();

  const dealersPageUrl = equipment
    ? getDealersPageUrl(
        equipment.id,
        equipment.model.resourcePath
          ? equipment.model.resourcePath.replace(/^\//, "")
          : "",
        selectedRegion,
        location.search.substr(1),
      )
    : "";

  const isOffersPage = match.params.mode === "offers";
  const changeLinkMode = equipment
    ? isOffersPage
      ? getMapPageUrl(
          equipment.id,
          equipment.model.resourcePath.replace(/^\//, ""),
          selectedRegion,
          location.search,
        )
      : getDealersPageUrl(
          equipment.id,
          equipment.model.resourcePath.replace(/^\//, ""),
          selectedRegion,
          location.search,
        )
    : "";

  if (status === REQUEST_STATUS.FAIL) {
    return <NotFoundPage />;
  }

  return (
    <div
      className={classNames("skeleton", "dealers-page", !isOffersPage && "map")}
    >
      <Seo
        description={seoData.h1}
        title={seoData.title}
        isNoIndex={!isOffersPage}
        canonical={seoData.canonical}
      />
      <Header
        showBackButton={true}
        onBackButtonClick={
          isPopupShown
            ? () => {
                onBackButtonClick();
              }
            : mobileSelectedDealer
            ? () => {
                setMobileSelectedDealer(null);
              }
            : null
        }
      />
      <div id="main" className="content">
        {(status === REQUEST_STATUS.LOADING ||
          status === REQUEST_STATUS.NONE) &&
          !isPopupShown && <Loading />}
        {equipment && (
          <>
            <div className="header">
              <div className="title_container">
                <div className="title-text_container">
                  <div className="title">
                    <h1>{seoData.h1}</h1>
                  </div>
                  <div className="title-description">
                    {getLocalText(equipment.modificationName)}
                  </div>
                </div>
                <AddToCompareButton offerId={equipment.id} />
              </div>
              <Tabs
                offerId={equipment.id}
                offerPath={equipment.model.resourcePath.replace(/^\//, "")}
                selectedRegion={selectedRegion}
                active={isOffersPage ? "prices" : "map"}
                search={location.search}
              />
            </div>
            <div
              className={classNames(
                "dealers-page-content",
                isWidgetMode &&
                  !widgetConfig.showDealersFilters &&
                  "hide-filters-mode",
              )}
            >
              <div className="cars-count-mobile">
                {getLocalTextWithNumbers(
                  "${dealerCount} ${changeableText} ${totalCount} ${changeableText2}",
                  [
                    {
                      id: "dealerCount",
                      value: equipment.offers.dealerCount,
                    },
                    {
                      id: "changeableText",
                      value: declOfNum(equipment.offers.dealerCount, [
                        "дилер",
                        "дилеров",
                        "дилеров",
                      ]),
                    },
                    {
                      id: "totalCount",
                      value: equipment.offers.totalCount,
                    },
                    {
                      id: "changeableText2",
                      value: declOfNum(equipment.offers.totalCount, [
                        "автомобиль",
                        "автомобиля",
                        "автомобилей",
                      ]),
                    },
                  ],
                )}
                <IconButton
                  icon={isOffersPage ? "icon-map" : "icon-list"}
                  onClick={() => {
                    history.replace(changeLinkMode);
                  }}
                />
              </div>

              {(!isWidgetMode || widgetConfig.showDealersFilters) && (
                <MobileFiltersLabel
                  onClick={() => {
                    prevMobileFiltersResults = equipment.offers;
                  }}
                  filtersNumber={selectedFilters.length}
                  filtersLocation="dealers"
                  filters={dealersFilters.filters}
                />
              )}

              {!isPopupShown && (
                <>
                  {dealers.length > 0 ? (
                    <>
                      {isOffersPage ? (
                        <List
                          style={{
                            display: !!mobileSelectedDealer ? "none" : "block",
                          }}
                          dealers={dealers}
                          equipmentId={equipment.id}
                          pathname={history.location.pathname}
                          selectedDealersIdList={selectedDealersIdList}
                          onSelectDealer={onSelectDealer}
                          openMobileDealerCarsList={openMobileDealerCarsList}
                          setPopupSettings={setPopupSettings}
                        />
                      ) : (
                        <Map
                          history={history}
                          dealers={dealers}
                          equipmentId={equipment.id}
                          pathname={history.location.pathname}
                          selectedDealersIdList={selectedDealersIdList}
                          unSelectAllDealers={unSelectAllDealers}
                          onSelectDealer={onSelectDealer}
                          openMobileDealerCarsList={openMobileDealerCarsList}
                          setPopupSettings={setPopupSettings}
                        />
                      )}
                    </>
                  ) : (
                    <div className="zero-results">
                      {getLocalText(
                        "Дилеры не найдены. Измените регион или начните поиск сначала.",
                      )}
                    </div>
                  )}
                </>
              )}

              {mobileSelectedDealer && (
                <MobileSelectedDealer
                  dealer={mobileSelectedDealer}
                  setPopupSettings={setPopupSettings}
                />
              )}

              {equipment && !mobileSelectedDealer && (
                <PriceCard
                  isShowOnMobiles={false}
                  isShowFiltersWhenOffersIsNull={true}
                  isFiltersPopupShown={isPopupShown}
                  isDealersPage={true}
                  offer={equipment}
                  dealersPageUrl={dealersPageUrl}
                  btnComponent={
                    <SelectedDealers
                      history={history}
                      equipment={equipment}
                      title={`${equipment.brand.name} ${equipment.model.name} ${equipment.versionName}`}
                      selectedDealers={selectedDealersCollection}
                      onBookDealer={onBookDealer}
                      unSelectAllDealers={unSelectAllDealers}
                    />
                  }
                  filtersComponent={
                    <Filters
                      filters={dealersFilters.filters}
                      onChange={getDealersListFromServer}
                      selectedFilters={selectedFilters}
                      results={equipment.offers}
                      status={status}
                      isCarInsteadVariants={true}
                      filtersLocation="dealers"
                    />
                  }
                ></PriceCard>
              )}
            </div>
            {callbackPopupVisible && (
              <CallbackPopup
                equipmentId={equipment.id}
                selectedDealersCollection={selectedDealersCollection}
                hidePopup={() => setCallbackPopupVisible(false)}
              />
            )}
            {popupSettings.show && (
              <Popup
                title={popupSettings.title}
                hidePopup={() => setPopupSettings({ show: false, title: null })}
              />
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

DealersPage.fetchData = (dataContext, match, request) => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;
  const params = parseUrlToFilter(
    request.originalUrl.split("?")[0],
    request.originalUrl.split("?")[1],
  );

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(
      params.region,
      params.radius,
      selectedRegions,
    ).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };
      const vars = {
        equipmentId: params.version,
        after: "null",
        expressions: params.expressions ? params.expressions : [],
        domain: {},
        geo: Object.assign(
          { ids: regions.selectedRegions },
          regions.radius ? { radius: regions.radius } : {},
        ),
      };

      loadDealers(vars, true)
        .then(result => {
          const filters = {
            dealers: getFiltersData(result.filters),
          };
          resolve({
            isDataContext: true,
            regions,
            filters,
            dealers: result,
          });
        })
        .catch(() => {
          resolve({
            isDataContext: true,
            regions,
            is404Error: true,
          });
        });
    });
  });
};
export const DealersSSRLoad = DealersPage.fetchData;
export default DealersPage;
