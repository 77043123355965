import React from "react";
import classNames from "classnames";

import "./styles.less";

const CheckBox = ({ checked = false, disabled = false, onClick, ...props }) => {
  return (
    <div
      className={classNames(
        "checkbox",
        disabled && "disabled",
        checked && "checked",
        props.className && props.className,
      )}
      onClick={event => {
        onClick(event);
      }}
      {...props}
    >
      <i className={classNames("icon icon-checkbox", checked && "checked")} />
    </div>
  );
};

export default CheckBox;
