import React from "react";

import graphqlClient from "app/graphql/services/client";
import { getDealersList } from "app/graphql/queries";
import {
  REQUEST_STATUS,
  DEALER_FILTER_ID,
  DEALERS_PER_PAGE_LIMIT,
} from "app/constants";

export const DealersContext = React.createContext();

class Dealers extends React.Component {
  state = {
    dealers: this.props.dealers || [],
    sameColorsList: this.props.sameColorsList || [],
    equipment: this.props.equipment || null,
    status: this.props.status || REQUEST_STATUS.NONE,
  };

  resetStatus = () => {
    this.setState({ status: REQUEST_STATUS.NONE });
  };

  getDealersList = (vars, setFiltersData) => {
    const isFirstLoading = this.state.status === REQUEST_STATUS.NONE;
    this.setState({ status: REQUEST_STATUS.LOADING });
    loadDealers(vars, isFirstLoading, setFiltersData).then(
      result => {
        this.setState(result);
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  render() {
    return (
      <DealersContext.Provider
        value={{
          ...this.state,
          functions: {
            getDealersList: this.getDealersList,
            resetStatus: this.resetStatus,
          },
        }}
      >
        {this.props.children}
      </DealersContext.Provider>
    );
  }
}

export const loadDealers = (vars, isFirstLoading, setFiltersData) => {
  vars.limit = DEALERS_PER_PAGE_LIMIT;

  return new Promise((resolve, reject) => {
    graphqlClient.fetch(getDealersList, vars).then(
      response => {
        if (!response.node) {
          console.log(
            `[ERROR] Get dealer list with vars "${JSON.stringify(vars)}"`,
          );
          reject();
          return;
        }
        let expandedFilters = {};
        response.node.filter.forEach(filter => {
          filter.separate = false;
          filter.depend = null;
          let isOnceValue = filter.values
            ? filter.values.filter(value => value.count > 0).length <= 1
            : filter.max === filter.min;
          if (
            (filter.id !== DEALER_FILTER_ID || filter.values.length < 11) &&
            !isOnceValue
          ) {
            expandedFilters[filter.id] = true;
          }
        });
        if (setFiltersData) {
          setFiltersData(
            response.node.filter,
            vars.expressions || [],
            vars.filterSet,
            isFirstLoading ? expandedFilters : null,
            "dealers",
          );
        }

        const sameColorsList = response.node.offers.colors.filter(color =>
          response.node.offers.colors.some(
            item => item.name === color.name && item.code !== color.code,
          ),
        );

        const result = {
          dealers: response.node.dealers.edges.map(dealer => ({
            map: {
              position: {
                lat: parseFloat(dealer.node.location.latitude),
                lng: parseFloat(dealer.node.location.longitude),
              },
            },
            variants: dealer.variants.sort((a, b) => {
              if (a.price.low < b.price.low) {
                return -1;
              }
              if (a.price.low > b.price.low) {
                return 1;
              }
              return 0;
            }),
            ...dealer,
          })),
          sameColorsList: sameColorsList,
          equipment: {
            ...response.node,
            modificationName:
              response.node && response.node.modificationName
                ? response.node.modificationName.replace("0.0", "")
                : null,
          },
          status: REQUEST_STATUS.SUCCESS,
          filters: {
            filters: response.node.filter,
            expressions: vars.expressions || [],
            filtersSet: vars.filterSet,
            expanded: isFirstLoading ? expandedFilters : {},
          },
        };
        resolve(result);
      },
      () => {
        reject();
      },
    );
  });
};

export const DealersProvider = Dealers;
