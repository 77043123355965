import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";

import TextButton from "components/ui/TextButton";
import PhonePopup from "components/popup/PhonePopup";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";

let patterns = {
  ru: {
    placeholder: "+7 (___) ___-__-__",
    mask: "+7 (999) 999-99-99",
  },
  by: {
    placeholder: "+375 (__) ___-__-__",
    mask: "+375 (99) 999-99-99",
  },
  kz: {
    placeholder: "+77 (__) ___-__-__",
    mask: "+77 (99) 999-99-99",
  },
  md: {
    placeholder: "+373 (___) ___-__",
    mask: "+373 (999) 999-99",
  },
  uz: {
    placeholder: "+998 (___) ___-__-__",
    mask: "+\\9\\98 (999) 999-99-99",
  },
  ua: {
    placeholder: "+380 (__) ___-__-__",
    mask: "+380 (99) 999-99-99",
  },
  ot: {
    placeholder: "______________________",
    mask: "9999999999999999999999",
  },
};

const BookForm = ({ submitForm }) => {
  const { register, getValues, setValue, trigger, errors } = useForm();
  patterns =
    isWidgetMode && widgetConfig.phoneMaskPatterns
      ? widgetConfig.phoneMaskPatterns
      : patterns;
  const [phonePattern, setPhonePattern] = useState(
    patterns[Object.keys(patterns)[0]],
  );
  const onSubmit = data => {
    submitForm(data);
  };

  return (
    <form>
      <input
        className="s-input"
        name="name"
        ref={register({ required: true })}
        placeholder={getLocalText("Ваше имя")}
        style={{ borderColor: errors.name && "red" }}
        onChange={() => trigger("name")}
      />

      <div className="phone_container">
        <PhonePopup
          setPhonePattern={code => {
            setPhonePattern(patterns[code]);
            setValue("phone", "");
          }}
        />

        <InputMask
          mask={phonePattern.mask}
          onChange={() => {
            trigger("phone");
          }}
        >
          {() => (
            <input
              className="s-input"
              name="phone"
              ref={register({
                required: true,
                validate: value => {
                  return (
                    value.indexOf("_") === -1 ||
                    phonePattern.placeholder === "______________________"
                  );
                },
              })}
              style={{ borderColor: errors.phone && "red" }}
              placeholder={phonePattern.placeholder}
            />
          )}
        </InputMask>
      </div>

      <input
        className="s-input"
        name="email"
        type="email"
        ref={register}
        placeholder={getLocalText("Эл. почта (не обязательно)")}
      />

      <textarea
        className="s-input comment"
        name="comment"
        ref={register}
        placeholder={getLocalText("Комментарий (не обязательно)")}
      />

      <div className="book-form_container">
        <TextButton
          text={getLocalText("Отправить")}
          color="orange"
          onClick={async () => {
            const result = await trigger();
            if (result) {
              onSubmit(getValues());
            }
          }}
        />
      </div>
    </form>
  );
};

export default BookForm;
