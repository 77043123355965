import React, { PureComponent } from "react";

import { formatNumber, getFilterValueFromExpressions } from "utils";
import { DATE_FILTERS_ID } from "app/constants";
import { getLocalText } from "app/utils/i18nService";

export default class FilterSelectedValue extends PureComponent {
  render() {
    const { filter } = this.props;

    let content = "";

    switch (filter.__typename) {
      case "EnumFilter":
        content = filter.values.reduce((res, filterValue) => {
          res += filterValue.checked
            ? `${res ? "; " : ""}${filterValue.name}`
            : "";
          return res;
        }, "");
        break;

      case "NumberFilter":
        const { from, to } = filter;
        if (DATE_FILTERS_ID.some(item => item === filter.id)) {
          //if date filter
          content = `${from ? `${getLocalText("от")} ${from}` : ""} ${
            to ? `${getLocalText("до")} ${to}` : ""
          }`;
        } else {
          content = `${from ? getLocalText("от") : ""} ${formatNumber(from)} ${
            to ? getLocalText("до") : ""
          } ${formatNumber(to)}`;
        }
        break;

      default:
        return null;
    }

    return (
      <>{content && <div className="c-filter-selected-value">{content}</div>}</>
    );
  }
}
