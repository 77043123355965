import ru from "app/locale/ru";
import { widgetConfig } from "utils/frameService";
import { isWidgetMode, localization } from "app/utils/frameService";
import { declOfNum } from "app/utils/index";

export const getText = (path, defaultString) => {
  let string = null;
  if (widgetConfig && widgetConfig.newTextStrings) {
    string = path.split(".").reduce(function(prev, curr) {
      return prev ? prev[curr] : null;
    }, widgetConfig.newTextStrings);
  }
  if (!string) {
    string = path.split(".").reduce(function(prev, curr) {
      return prev ? prev[curr] : null;
    }, ru);
  }
  return string || defaultString;
};

export const getLocalText = text => {
  return localization && text ? localization[text.toLowerCase()] || text : text;
};

export const hasLocalText = text => {
  return (
    !isWidgetMode || (localization && text && localization[text.toLowerCase()])
  );
};

export const getLocalTextWithNumbers = (text, paramsList) => {
  let resultText = text;
  if (localization && localization[text]) {
    resultText = localization[text].text;
    paramsList = [...paramsList, ...localization[text].paramsList];
  }
  paramsList.forEach(param => {
    resultText = resultText.replace("${" + param.id + "}", param.value);
  });
  return resultText;
};
