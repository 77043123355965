import React from "react";
import { withRouter } from "react-router";
import { renderRoutes } from "react-router-config";

import MainPage, { MainPageSSRLoad } from "pages/main";
import routes from "./routes";

import ErrorBoundary from "components/ErrorBoundary";
import Popup from "components/ui/Popup";
import AppProvider from "context";
import {
  isWidgetMode,
  createResizeListener,
  sendLocationChanged,
  widgetConfig,
  updateWindowHistory,
  sendParentEvent,
} from "utils/frameService";

import "../../less/normalize.less";
import "../../less/fonts.less";
import "./styles.less";
import InfoNotificationPopup from "components/shared/InfoNotificationPopup";

class App extends React.PureComponent {
  componentDidMount() {
    const { history } = this.props;
    updateWindowHistory(history);

    if (isWidgetMode) {
      sendParentEvent("ilsa_finishedLoadingWidget");
    }
  }

  componentDidUpdate() {
    if (isWidgetMode) {
      //Get window height after every rerender
      createResizeListener();
      //Get location changed
      sendLocationChanged(location);
    }
  }
  render() {
    const { hasError, errorText, context } = this.props;

    if (widgetConfig && widgetConfig.showFirstPage) {
      let route = routes.find(route => route.path === "/");
      if (route) {
        route.key = "MainPage";
        route.component = MainPage;
        route.componentSSRLoad = MainPageSSRLoad;
      }
    }
    return (
      <ErrorBoundary hasError={hasError} errorText={errorText}>
        <AppProvider context={context}>{renderRoutes(routes)}</AppProvider>
        {!isWidgetMode && <InfoNotificationPopup />}
      </ErrorBoundary>
    );
  }
}

export default withRouter(App);
