import React from "react";

import { getPopularAndSelectedRegions } from "app/context/Regions";

import Header from "components/Header";
import Footer from "components/Footer";
import Seo from "components/Seo";

import "./styles.less";

const TITLE = "Контакты и реквизиты";
const DESCRIPTION = "";

const ContactsPage = () => (
  <div className="skeleton c-static-page">
    <Seo description={DESCRIPTION} title={TITLE} />
    <Header />
    <div id="main" className="content">
      <h1>Контактная информация</h1>

      <p className="content--row">
        Общество с ограниченной ответственностью "Илса"
        <br />
        ОГРН 1127746565638
        <br />
        ИНН 7715929153, КПП 771701001
        <br />
        Юридический адрес: 129085, г.Москва, ул. Годовикова, д.9, стр.12, под.
        12.1, этаж 7, пом. 7.1
      </p>

      <p className="content--row">
        Мы работаем с понедельника по пятницу, с 9:00 до 18:00.
      </p>

      <p className="content--row">Телефон: +7&nbsp;(499)&nbsp;714-74-67</p>

      <p className="content--row">
        E-mail <a href="mailto:info@ilsa.ru">info@ilsa.ru</a>
      </p>
    </div>
    <Footer />
  </div>
);

ContactsPage.fetchData = dataContext => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(null, null, selectedRegions).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };

      resolve({
        isDataContext: true,
        regions,
      });
    });
  });
};

export default ContactsPage;
