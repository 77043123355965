import React from "react";

import graphqlClient from "app/graphql/services/client";
import { getDealersList } from "app/graphql/queries";
import { receiveFilters } from "reducers/filters";
import {
  REQUEST_STATUS,
  DEALER_FILTER_ID,
  DEALERS_PER_PAGE_LIMIT,
} from "app/constants";

export const SavedFiltersContext = React.createContext();

class SavedFilters extends React.Component {
  state = {
    savedFilters: [],
  };

  saveFilter = filter => {
    let newSavedFilter = this.state.savedFilters.slice();
    newSavedFilter.push(filter);
    this.setState({ savedFilters: newSavedFilter });
  };

  removeLastFilter = () => {
    this.setState({
      savedFilters:
        this.state.savedFilters.length > 1 ? this.state.savedFilters.slice(0, -1) : [],
    });
  };

  render() {
    return (
      <SavedFiltersContext.Provider
        value={{
          ...this.state,
          functions: {
            saveFilter: this.saveFilter,
            removeLastFilter: this.removeLastFilter,
          },
        }}
      >
        {this.props.children}
      </SavedFiltersContext.Provider>
    );
  }
}

export const SavedFiltersProvider = SavedFilters;
