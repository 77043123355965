import React from "react";
import classNames from "classnames";

import "./styles.less";

const RadioButton = ({
  checked = false,
  disabled = false,
  onClick,
  ...props
}) => {
  return (
    <div
      className={classNames("radiobutton-container", disabled && "disabled")}
      onClick={event => {
        if (!disabled) {
          onClick(event);
        }
      }}
      {...props}
    >
      <div
        className={classNames(
          "radiobutton",
          checked && "checked",
          props.className && props.className,
        )}
      />
    </div>
  );
};

export default RadioButton;
