import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

import { useWindowSize } from "utils/windowSizeHook";
import { isWidgetMode, widgetConfig } from "utils/frameService";

import Marker from "components/DealersPage/Map/Marker";
import DealerPopover from "components/DealersPage/Map/DealerPopover";
import Dealer from "components/DealersPage/Dealer";

let map = null;

const GoogleMap = ({
  height,
  onMarkerClick,
  onMapClick,
  onSelectDealer,
  onClosePopover,
  dealers,
  equipmentId,
  pathname,
  selectedDealersIdList,
  activeDealer,
  center,
  zoom = 11,
  setPopupSettings,
}) => {
  const { isTabletWidth } = useWindowSize();

  const [mapScrollEnabled, setMapScrollEnabled] = useState(true);

  useEffect(() => {
    updateMarkerBounds();
  }, [dealers]);

  useEffect(() => {
    if (!isTabletWidth && activeDealer) {
      map.panBy(
        0,
        !isWidgetMode || widgetConfig.showDealersFilters ? -170 : -50,
      );
    }
  }, [activeDealer]);

  const updateMarkerBounds = () => {
    if (map) {
      if (dealers.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        dealers.forEach(dealer => {
          bounds.extend(dealer.map.position);
        });
        map.fitBounds(bounds);
        if (map.getZoom() > 15) {
          map.setZoom(15);
        }
      }
    }
  };

  let isClickOnMarker = false;

  return (
    <div style={{ height: height + "px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBSMNP_wVjWdX8ELxC0AVLSeOE0MAWZhqc" }}
        center={center}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map: loadedMap }) => {
          map = loadedMap;
          updateMarkerBounds();
        }}
        yesIWantToUseGoogleMapApiInternals
        onClick={() => {
          if (isClickOnMarker) {
            isClickOnMarker = false;
          } else {
            onMapClick();
          }
        }}
        options={() => {
          return {
            clickableIcons: false,
            fullscreenControl: false,
            gestureHandling: "greedy",
            scrollwheel: mapScrollEnabled,
          };
        }}
      >
        {dealers.map(dealer => {
          const { position } = dealer.map;
          const isSelected = selectedDealersIdList.some(
            selectedDealerId => selectedDealerId === dealer.node.id,
          );
          const isActive =
            activeDealer && activeDealer.node.id === dealer.node.id;
          return (
            <Marker
              key={dealer.node.id}
              lat={position.lat}
              lng={position.lng}
              isSelected={isSelected}
              isActive={isActive}
              onClick={() => {
                isClickOnMarker = true;
                onMarkerClick(dealer);
              }}
              content={
                <DealerPopover
                  onSelectDealer={onSelectDealer}
                  onClosePopover={onClosePopover}
                  selectedDealersIdList={selectedDealersIdList}
                  dealer={dealer}
                  equipmentId={equipmentId}
                  pathname={pathname}
                  changeMapScroll={isEnable => {
                    setMapScrollEnabled(isEnable);
                  }}
                  setPopupSettings={setPopupSettings}
                />
              }
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
