import React from "react";
import { RouterHistoryListProvider } from "context/RouterHistoryList";
import { RegionsProvider } from "context/Regions";
import { CompareProvider } from "context/Compare";
import { FiltersProvider } from "context/Filters";
import { SavedFiltersProvider } from "context/SavedFilters";
import { MainPageProvider } from "context/Main";
import { DealersProvider } from "context/Dealers";
import { OffersListProvider } from "context/OffersList";
import { OfferProvider } from "context/Offer";

const AppProvider = ({ children, context }) => {
  return (
    <RouterHistoryListProvider>
      <RegionsProvider {...context.regions}>
        <CompareProvider {...context.compare}>
          <FiltersProvider {...context.filters}>
            <SavedFiltersProvider {...context.filters}>
              <DealersProvider {...context.dealers}>
                <OffersListProvider {...context.offersList}>
                  <OfferProvider {...context.offer}>
                    <MainPageProvider {...context.mainPage}>
                      {children}
                    </MainPageProvider>
                  </OfferProvider>
                </OffersListProvider>
              </DealersProvider>
            </SavedFiltersProvider>
          </FiltersProvider>
        </CompareProvider>
      </RegionsProvider>
    </RouterHistoryListProvider>
  );
};
export default AppProvider;
