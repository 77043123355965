import React from "react";

import { formatPhone, getUpdatedUrlSearch } from "utils";
import {
  isWidgetMode,
  widgetConfig,
  isContactsModeEnable,
} from "utils/frameService";

import Dealer from "components/DealersPage/Dealer";

import "./styles.less";

const List = ({
  style,
  dealers,
  equipmentId,
  pathname,
  selectedDealersIdList,
  onSelectDealer,
  openMobileDealerCarsList,
  setPopupSettings,
}) => {
  return (
    <div className="dealers-list" style={style}>
      <div className="dealers">
        {dealers.map(dealer => (
          <Dealer
            key={dealer.node.id}
            dealer={dealer}
            equipmentId={equipmentId}
            pathname={pathname}
            selectedDealers={selectedDealersIdList}
            selectDealer={onSelectDealer}
            openMobileDealerCarsList={openMobileDealerCarsList}
            setPopupSettings={setPopupSettings}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
