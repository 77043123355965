import React from "react";

import Specification from "components/OfferDetailPage/Specification";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";
import { localization } from "app/utils/frameService";

const TabContent = props => {
  const { description, tab, specifications, offerTitle, hideAdditionalOptions } = props;

  const createFeatureList = description => {
    return (
      <>
        {description.map((item, index) => (
          <div key={item.name} className="item">
            <div className="item-title">{getLocalText(item.name)}</div>
            <div className="item-list">
              <ul>
                {tab === "short"
                  ? item.features
                      .filter(
                        feature =>
                          feature.name &&
                          feature.name !== "Дополнительное описание",
                      )
                      .map((feature, index) => (
                        <li
                          key={index}
                          className={
                            feature.base === false && !hideAdditionalOptions
                              ? "add-feature"
                              : ""
                          }
                        >
                          {`${getLocalText(feature.name)}${
                            feature.value
                              ? `: ${getLocalText(feature.value)}`
                              : ""
                          }`}
                        </li>
                      ))
                  : item.features.map((feature, index) => (
                      <li
                        key={index}
                        className={
                          feature.ins.length === 0 &&
                          feature.base === false &&
                          !hideAdditionalOptions
                            ? "add-feature"
                            : ""
                        }
                      >
                        {feature.ins.length > 0 && (
                          <ul
                            className={
                              feature.name !== null ? "item-list--extra" : ""
                            }
                          >
                            {feature.ins.map((featureIns, insIndex) => (
                              <>
                                <li
                                  key={insIndex}
                                  className={
                                    featureIns.base === false && !hideAdditionalOptions
                                      ? "add-feature"
                                      : ""
                                  }
                                >
                                  {getLocalText(featureIns.description)}
                                </li>
                              </>
                            ))}
                          </ul>
                        )}

                        {feature.ins.length === 0 &&
                          `${getLocalText(feature.name)}${
                            feature.value
                              ? `: ${getLocalText(feature.value)}`
                              : ""
                          }`}
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="tab-content">
      {tab === "full" && localization && (
        <div className="translateProblems">{`The description of equipment and features for the purposes of this MVP has been translated into English using Google Translate and may therefore contain some inaccurate language.`}</div>
      )}
      {tab !== "technical" ? (
        <>
          <div className="columns">
            <div className="column">
              {createFeatureList(description.slice(0, 4))}
            </div>
            <div className="column right">
              {createFeatureList(description.slice(4))}
            </div>
          </div>
          {!hideAdditionalOptions && (
            <div className="tab-content-note">
              <div className="item">
                <ul>
                  <li className="add-feature">
                    {`${getLocalText(
                      "Дополнительно установленные опции к комплектации",
                    )} ${offerTitle}`}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      ) : (
        <Specification data={specifications} />
      )}
    </div>
  );
};

export default TabContent;
