export const filters = `
  id
  name
  separate
  __typename
  ... on NumberFilter {
    min
    max
    precision
  }
  ... on EnumFilter {
    multiple
    depend
    values {
      id
      name
      count
      checked
      slug
    }
  }
  ... on BooleanFilter {
    id
    name
  }
`;

export const region = `
  id
  name,
  namePre,
  cutoff
  resourcePath
`;

export const compare = `
  id
  photo(width: 166)
  brand {
    name
    resourcePath
  }
  model {
    name
    resourcePath
  }
  offers (domain: $domain, geo: $geo) {
    price {
      low
      high
    }
    totalCount
    dealerCount
    edges {
      node {
        id
      }
    }
  }
  versionName
`;

export const dealer = `
  offerCount
  price {
    low
    high
  }  
  marketingPrice {
    low
    high
  }
  availability
  node {
    id
    databaseId
    name
    address {
      formatted
    }
    phoneNumber
    region {
      name
    }
    tollFreePhoneNumber {
      extension
      number
    }
    location {
      latitude
      longitude
    }
  }
  variants {
    id
    token
    booking
    checkTime
    year
    picture(width: 100, height: 100)
    extraEquipment
    color {
      name
      code
      hex
    }
    price {
      low
      high
    }
    marketingPrice {
      low
      high
    }
    offers {
      availability
      offerCount
    }
  }
`;

export const offerDetailEquipment = `
  id
  brand {
    name
  }
  model {
    name
    resourcePath
  }
  photo(width: 220)
  similar(geo: $geo) {
    expressions
    totalCount
  }
  offers (expressions: $expressions, domain: $domain, geo: $geo, first:0) {
    colors {
      picture (width: 400, height: 300)
      code
      name
      base
      hex
      key
    }
    competitors(first:20) {
      edges {
        expressions
      }
      edges {
        offerCount
        price {
          low
          high
        }
        node {
          id
          name
          picture (width: 150, height: 100)
          resourcePath
          brand {
            name
          }
        }
      }
    }
  }
  modificationName
  versionName
  specifications {
    name
    value
    unit
    has
    ins {
      base
      description
    }
  }
  description {
    name
    features {
      name
      value
      base
      ins {
        base
        description
      }
    }
  }
`;

export const offerDetailPrices = `
  offers (expressions: $expressionsWithColors, domain: $domain, geo: $geo, first:0) {
    price {
      low
      high
    }
    marketingPrice {
      low
      high
    }
    totalCount
    dealerCount
  }
`;
