import React, { useState, useEffect, useContext, useRef } from "react";
import classNames from "classnames";

import { RegionsContext, getPopularAndSelectedRegions } from "context/Regions";
import { MainPageContext, loadMainPageData } from "context/Main";

import { REQUEST_STATUS } from "app/constants";
import {
  getBrandUrl,
  declOfNum,
  generateQueryString,
  getRegionUrl,
  getSelectedRegionName,
  parseUrlToFilter,
} from "utils";
import { getText } from "utils/i18nService";
import {
  isWidgetMode,
  widgetConfig,
  widgetConfigPromise,
} from "utils/frameService";
import {
  getDefaultDomainFilters,
  getDefaultExpressionsAndRegions,
} from "app/utils/filtersService";
import { FiltersContext } from "app/context/Filters";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";
import { useUpdateEffect } from "app/utils/useUpdateEffect";

import Header from "components/Header";
import Footer from "components/Footer";
import Seo from "components/Seo";
import Link from "components/ui/Link";
import LinkButton from "components/ui/LinkButton";
import PriceSlider from "components/MainPage/PriceSlider";
import Loading from "components/Loading";

import "./styles.less";

let priceRange = [];
let resetDefaultPriceRange = false;

const MainPage = props => {
  const { regions, radius, selectedRegions } = useContext(RegionsContext);
  const { functions: filtersContextFunctions } = useContext(FiltersContext);
  let sliderRef = useRef();
  let selectedRegionsRef = useRef(selectedRegions);
  let radiusRef = useRef(radius);
  const {
    dealersCount,
    brandsCount,
    regionsCount,
    totalCount,
    offerCount,
    filtersList,
    priceFilter,
    selectedPriceRange,
    status,
    functions,
  } = useContext(MainPageContext);
  const [defaultPriceRange, setDefaultPriceRange] = useState(null);

  useEffect(() => {
    (async () => {
      if (status !== REQUEST_STATUS.SUCCESS) {
        const vars = {
          expressions: [],
          geo: Object.assign(
            { ids: selectedRegions },
            radius ? { radius: radius } : {},
          ),
          domain: {},
        };

        if (isWidgetMode) {
          if (widgetConfigPromise) {
            await widgetConfigPromise;
          }
          const defaultExpressions = getDefaultExpressionsAndRegions(
            vars.expressions,
          );
          vars.expressions = defaultExpressions.expressions;
          vars.domain = getDefaultDomainFilters();
        }

        functions.getData(vars);
      } else if (!defaultPriceRange && selectedPriceRange) {
        setDefaultPriceRange(selectedPriceRange);
      }
    })();

    return () => {
      functions.resetData(defaultPriceRange);
      functions.resetStatus();
    };
  }, []);

  useEffect(() => {
    if (
      priceFilter &&
      (!defaultPriceRange ||
        defaultPriceRange.min !== priceFilter.min ||
        defaultPriceRange.max !== priceFilter.max)
    ) {
      priceRange = [priceFilter.min, priceFilter.max];
      setDefaultPriceRange(priceFilter);
      if (sliderRef.current) {
        sliderRef.current.reset();
      }
      resetDefaultPriceRange = false;
    }
  }, [priceFilter]);

  useUpdateEffect(() => {
    selectedRegionsRef.current = selectedRegions;
    radiusRef.current = radius;
    updateData({
      selected: selectedRegionsRef.current,
      radius: radiusRef.current,
    });
  }, [selectedRegions, radius]);

  const formatPriceRange = () => {
    if (priceRange.length === 0) {
      priceRange = selectedPriceRange
        ? [selectedPriceRange.min, selectedPriceRange.max]
        : [];
    }
  };

  const resetData = (isUpdateData = true) => {
    priceRange = defaultPriceRange
      ? [defaultPriceRange.min, defaultPriceRange.max]
      : [];

    if (sliderRef.current) {
      sliderRef.current.reset();
      if (isUpdateData) {
        updateData();
      }
    }
  };

  const changePriceRange = value => {
    priceRange = value;
    functions.updateSelectedPrice(value);
    updateData();
  };

  const updateData = geo => {
    const selected = geo ? geo.selected : selectedRegionsRef.current;
    const radius = geo ? geo.radius : radiusRef.current;

    if (geo) {
      resetDefaultPriceRange = true;
    }
    let vars = {
      expressions: geo ? [] : getExpressions(),
      geo: Object.assign({ ids: selected }, radius ? { radius } : {}),
    };
    const defaultExpressions = getDefaultExpressionsAndRegions(
      vars.expressions,
    );
    vars.expressions = defaultExpressions.expressions;
    vars.domain = getDefaultDomainFilters();
    functions.getData(vars);
  };

  const getFiltersUrl = () => {
    const filters = [];
    const minPriceRange = Number(priceRange[0]);
    const maxPriceRange = Number(priceRange[1]);
    if (
      defaultPriceRange &&
      (defaultPriceRange.min !== minPriceRange ||
        defaultPriceRange.max !== maxPriceRange)
    ) {
      filters.push({
        id: "1201",
        value: `${
          defaultPriceRange.min !== minPriceRange ? minPriceRange : ""
        }..${defaultPriceRange.max !== maxPriceRange ? maxPriceRange : ""}`,
      });
    }

    return generateQueryString({
      filters: filters,
      ...(radius && radius !== 200 ? { radius } : {}),
    });
  };

  const getExpressions = () => {
    let expressions = [];
    expressions.push(`1201=${priceRange[0]}..${priceRange[1]}`);

    return expressions;
  };

  const getSeoData = () => {
    // Title: Продажа новых автомобилей в России/Москве, цены и наличие у дилеров
    // Description: Продажа новых автомобилей в России/Москве. Проверенные цены на авто, комплектации и актуальное наличие машин на складах официальных дилеров
    // H1: Продажа новых автомобилей в России/Москве, цены и наличие у официальных дилеров

    const region = getSelectedRegionName(regions, selectedRegions);

    return {
      title:
        `Продажа новых автомобилей в ${region || "России"}` +
        `, ${!isWidgetMode ? "ориентировочные " : ""}цены и наличие у дилеров`,
      description:
        `Продажа новых автомобилей в ${region || "России"}.` +
        ` ${
          !isWidgetMode ? "Ориентировочные " : "Проверенные "
        }цены на авто, комплектации и актуальное наличие машин на складах официальных дилеров`,
      h1:
        `Продажа новых автомобилей в ${region || "России"}` +
        `, ${
          !isWidgetMode ? "ориентировочные " : ""
        }цены и наличие у официальных дилеров`,
    };
  };

  let selectedRegionResourcePath = "";
  if (regions.length > 0 && selectedRegions.length === 1) {
    selectedRegionResourcePath =
      regions.find(region => selectedRegions[0] === region.id).resourcePath ||
      "";
  }
  const seoData = getSeoData();

  let brandsList = [];
  if (filtersList.length > 0) {
    brandsList = filtersList.find(filter => filter.id === "1101").values;
  }
  formatPriceRange();
  const filterString = getFiltersUrl();

  const showCarCount =
    !isWidgetMode ||
    !isWidgetMode ||
    (isWidgetMode && widgetConfig && widgetConfig.showCarCountOnMainPage);

  return (
    <div className="skeleton main_page">
      <Seo description={seoData.description} title={seoData.title} />
      <Header
        onLogoClick={() => {
          resetData();
        }}
      />
      <div id="main" className="s-content clearfix">
        {(status === REQUEST_STATUS.LOADING ||
          status === REQUEST_STATUS.NONE) &&
        brandsList.length === 0 ? (
          <Loading />
        ) : (
          <>
            {(status === "LOADING" || status === "NONE") && <Loading />}
            <div className={classNames("gradient", isWidgetMode && "widget")} />
            <h1
              className={classNames(
                "title_container",
                isWidgetMode && "widget",
              )}
            >
              {getLocalText(
                getText(!isWidgetMode ? "main.h1" : "main.h1Widget"),
              )}
            </h1>
            <div
              className={classNames(
                "filters_container",
                isWidgetMode && "widget",
              )}
            >
              <ul className="brands_container">
                {brandsList.map(brand => {
                  return (
                    <li className="brands-list-item" key={brand.id}>
                      <Link
                        className={classNames(
                          "brand",
                          brand.count === 0 && "disabled",
                        )}
                        onClick={() => {
                          filtersContextFunctions.changeState(
                            { lastSelectedFilterId: "1101" },
                            "offersList",
                          );
                        }}
                        to={`${getBrandUrl(
                          selectedRegionResourcePath,
                          brand.slug,
                        )}${filterString ? `?${filterString}` : ""}`}
                        key={brand.id}
                      >
                        <div className="brand-name">{brand.name}</div>
                        {brand.count > 0 && (
                          <>
                            <div className="brand-count">{`${brand.count} (${
                              brand.offerFound
                            } ${getLocalText("а/м")})`}</div>
                          </>
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className="separator" />

              <div className="controls_container">
                {defaultPriceRange &&
                  totalCount !== null &&
                  defaultPriceRange.min !== 0 &&
                  defaultPriceRange.max !== 0 && (
                    <div className="price-slider_container">
                      <PriceSlider
                        ref={sliderRef}
                        min={defaultPriceRange.min}
                        max={defaultPriceRange.max}
                        priceRange={priceRange}
                        onChange={value => {
                          changePriceRange(value);
                        }}
                      />
                    </div>
                  )}
                <div
                  className={classNames(
                    "button_container",
                    isWidgetMode &&
                      widgetConfig &&
                      widgetConfig.allowSignature &&
                      "has-signature",
                    (!totalCount || !defaultPriceRange) &&
                      "price-slider-hidden",
                  )}
                >
                  <LinkButton
                    color="orange"
                    className="show-results-button"
                    text={getLocalTextWithNumbers(
                      "Показать ${totalCount} ${changeableText}${showCarCount}",
                      [
                        {
                          id: "totalCount",
                          value: totalCount ? totalCount : 0,
                        },
                        {
                          id: "changeableText",
                          value: declOfNum(totalCount ? totalCount : 0, [
                            "вариант",
                            "варианта",
                            "вариантов",
                          ]),
                        },
                        {
                          id: "showCarCount",
                          value: showCarCount ? "*" : "",
                        },
                      ],
                    )}
                    url={`${getRegionUrl(selectedRegionResourcePath)}${
                      filterString ? `?${filterString}` : ""
                    }`}
                  />
                  {showCarCount && (
                    <div className="offer-total-count">
                      {getLocalTextWithNumbers(
                        "*${offerCount} ${changeableText}",
                        [
                          {
                            id: "offerCount",
                            value: offerCount,
                          },
                          {
                            id: "changeableText",
                            value: declOfNum(offerCount ? offerCount : 0, [
                              "автомобиль",
                              "автомобиля",
                              "автомобилей",
                            ]),
                          },
                        ],
                      )}
                    </div>
                  )}
                </div>
                {isWidgetMode &&
                  widgetConfig &&
                  widgetConfig.allowSignature && (
                    <div
                      className={classNames(
                        "signature",
                        showCarCount && "show-car-count",
                      )}
                    >
                      Разработано ILSA
                    </div>
                  )}
              </div>
            </div>

            <div
              className={classNames(
                "feature-circles_container",
                (!totalCount || !defaultPriceRange) && "price-slider-hidden",
                showCarCount && "show-car-count",
              )}
            >
              <div className="feature_container">
                <div className="circle">{dealersCount}</div>
                <div className="text_container first">
                  <div className="title">Дилеров (официальных и пи)</div>
                  <div className="text">
                    Ежедневно отслеживаются на наличие предложений
                  </div>
                </div>
              </div>
              <div className="feature_container">
                <div className="circle">{regionsCount}</div>
                <div className="text_container">
                  <div className="title">Городов России</div>
                  <div className="text">
                    Входит в нашу базу актуальных предложений
                  </div>
                </div>
              </div>
              <div className="feature_container">
                <div className="circle">{brandsCount}</div>
                <div className="text_container">
                  <div className="title">Марок автомобилей</div>
                  <div className="text">Представлено на нашем сайте</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

MainPage.fetchData = (dataContext, match, request) => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;
  const params = parseUrlToFilter(
    request.originalUrl.split("?")[0],
    request.originalUrl.split("?")[1],
  );

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(
      params.region,
      params.radius,
      selectedRegions,
    ).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };
      const vars = {
        expressions: [],
        geo: Object.assign(
          { ids: regions.selectedRegions },
          regions.radius ? { radius: regions.radius } : {},
        ),
        domain: {},
      };

      loadMainPageData(vars)
        .then(response => {
          const brandFilter = response.carsData.filters.find(
            filter => filter.id === "1101",
          );
          const priceFilter = response.carsData.filters.find(
            filter => filter.id === "1201",
          );
          const priceRange = {
            min: Math.floor(priceFilter.min / 10000) * 10000,
            max: Math.ceil(priceFilter.max / 1000000) * 1000000,
          };
          resolve({
            isDataContext: true,
            regions,
            mainPage: {
              dealersCount: response.statistic.dealerCount,
              brandsCount: brandFilter.values.length,
              regionsCount: response.statistic.regionCount,
              totalCount: response.carsData.search.totalCount,
              offerCount: response.carsData.search.offerCount,
              filtersList: response.carsData.filters,
              priceFilter: priceRange,
              selectedPriceRange: priceRange,
              status: REQUEST_STATUS.SUCCESS,
            },
          });
        })
        .catch(() => {
          reject();
        });
    });
  });
};

export const MainPageSSRLoad = MainPage.fetchData;
export default MainPage;
