import React, {useContext} from "react";
import classNames from "classnames";

import { CompareContext } from "context/Compare";

import Tooltip from "components/ui/Tooltip";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

const AddToCompareButton = ({
  offerId,
}) => {
  const { carsIdList, functions } = useContext(CompareContext);
  const { addToCarsIdList, removeFromCarsIdList } = functions;

  const isInCompareList = carsIdList.some(id => id === offerId);

  return (
    <div className="add-to-compare-btn">
      <Tooltip
        content={
          isInCompareList
            ? getLocalText("Удалить из сравнения")
            : getLocalText("Добавить к сравнению")
        }
      >
        <div className="icon_container">
          <i
            className={classNames(
              "icon icon-compare",
              isInCompareList && "active",
            )}
            onClick={() => {
              if (!isInCompareList) {
                addToCarsIdList(offerId);
              } else {
                removeFromCarsIdList(offerId);
              }
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default AddToCompareButton;
