export const sendEquipmentRequestInput = `
  mutation Request($input: SendEquipmentRequestInput!) {
    sendEquipmentRequestType(input: $input) {
      clientMutationId
      request {
        id
      }
    }
  }
`;

export const bookingCarRequest = `
  mutation Request($id: ID!) {
    booking(input: {id: $id}) {
      action {
        ... on Redirect {
          url
        }
      }
    }
  }
`;

export const refreshAvailabilityRequest = `
  mutation Request($token: String!) {
    availability(input: {token: $token}) {
      time
      offers {
        availability
        offerCount
      }
    }
  }
`;

export const showPhoneNumberRequest = `
  mutation Request($dealer: ID!, $edition: ID!, $url: String, $ga: String) {
    showPhonenumber(input: {dealer: $dealer, edition: $edition, url: $url, ga: $ga}) {
      phonenumber {
        number
        extension
        expireTime
      }
    }
  }
`;
