import React, { useEffect, useRef } from "react";
import classNames from "classnames";

import { declOfNum, formatPhone } from "utils";
import {
  isWidgetMode,
  widgetConfig,
  isContactsModeEnable,
} from "utils/frameService";

import Dealer from "components/DealersPage/Dealer";

import "./styles.less";

const DealerPopover = ({
  dealer,
  equipmentId,
  pathname,
  onClosePopover,
  onSelectDealer,
  selectedDealersIdList,
  changeMapScroll,
  setPopupSettings,
}) => {
  let dealerPopoverRef = useRef();
  useEffect(() => {
    dealerPopoverRef.current.addEventListener("mouseenter", disableMapScroll);
    dealerPopoverRef.current.addEventListener("mouseleave", enableMapScroll);

    return () => {
      dealerPopoverRef.current.removeEventListener(
        "mouseenter",
        disableMapScroll,
      );
      dealerPopoverRef.current.removeEventListener(
        "mouseleave",
        enableMapScroll,
      );
      enableMapScroll();
    };
  }, []);

  const enableMapScroll = () => {
    changeMapScroll(true);
  };

  const disableMapScroll = () => {
    changeMapScroll(false);
  };

  const newContactBtnMode =
    isWidgetMode &&
    widgetConfig &&
    widgetConfig.contactBtn &&
    !isContactsModeEnable;

  return (
    <div
      className={classNames("s-marker", newContactBtnMode && "credit-mode")}
      onClick={e => e.stopPropagation()}
      ref={dealerPopoverRef}
    >
      <div className="close" onClick={onClosePopover}>
        <i className="icon icon-close" />
      </div>

      <Dealer
        key={dealer.node.id}
        dealer={dealer}
        equipmentId={equipmentId}
        pathname={pathname}
        selectedDealers={selectedDealersIdList}
        selectDealer={onSelectDealer}
        setPopupSettings={setPopupSettings}
      />
    </div>
  );
};

export default DealerPopover;
