import React from "react";
import classNames from "classnames";

import { declOfNum } from "utils";
import {
  getLocalText,
  getLocalTextWithNumbers,
  getText,
} from "app/utils/i18nService";

const Results = ({ offerCount, totalCount, dealerCount, inline = false }) => (
  <h2 className={classNames("c-results", { inline })}>
    <div>{getLocalText(getText("offersList.searchInformationPrefix"))}</div>
    <div>
      {getLocalTextWithNumbers("${totalCount} ${variantsText}", [
        {
          id: "totalCount",
          value: totalCount,
        },
        {
          id: "variantsText",
          value: declOfNum(totalCount, ["вариант", "варианта", "вариантов"]),
        },
      ])}
    </div>
    <div>
      {getLocalTextWithNumbers("${offerCount} ${autoText}", [
        {
          id: "offerCount",
          value: offerCount,
        },
        {
          id: "autoText",
          value: declOfNum(offerCount, [
            "автомобиль",
            "автомобиля",
            "автомобилей",
          ]),
        },
      ])}
    </div>
    <div>
      {getLocalTextWithNumbers("от ${dealerCount} ${variantsText}", [
        {
          id: "dealerCount",
          value: dealerCount,
        },
        {
          id: "variantsText",
          value: declOfNum(dealerCount, ["дилер", "дилеров", "дилеров"]),
        },
      ])}
    </div>
  </h2>
);

export default Results;
