import React from "react";

import { getText } from "../../utils/i18nService";

import Header from "components/Header";
import Footer from "components/Footer";
import Seo from "components/Seo";
import LinkButton from "components/ui/LinkButton";

import NotFoundSignImage from "errors/not-found.jpg";

import "./styles.less";

const TITLE = "404 — Страница не найдена";
const DESCRIPTION = "К сожалению, запрашиваемая Вами страница не найдена.";

const NotFoundPage = ({ staticContext }) => {
  if (staticContext) {
    staticContext.status = 404;
  }
  return (
    <div className="skeleton c-static-page">
      <Seo description={DESCRIPTION} title={TITLE} />
      <Header />
      <div id="main" className="not-found_page">
        <div className="not-found_container">
          <div>
            <img src={NotFoundSignImage} alt="" className="image" />
          </div>
          <div className="title">{getText("errors.notFound.title")}</div>
          <div className="text">{getText("errors.notFound.text")}</div>
          <div className="button_container">
            <LinkButton
              color="orange"
              text={getText("errors.notFound.button")}
              url={"/"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
