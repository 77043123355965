import React from "react";

import Link from "components/ui/Link";
import CarImage from "components/ui/CarImage";

import { getOfferPageUrl } from "utils";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

const TitlesList = ({ carsList, selectedRegionPath, removeCar, search }) => {
  return (
    <div className="titles-list_container">
      {carsList.map((car) => {
        const offerPageUrl = getOfferPageUrl(
          car.id,
          car.model.resourcePath,
          selectedRegionPath,
          search,
        );
        return (
          <div className="title_container" key={car.id}>
            <div className="close-button_container">
              <i
                className="icon icon-close-bold"
                onClick={() => {
                  removeCar(car.id);
                }}
              />
            </div>
            <div className="image_container">
              <Link className="name" to={offerPageUrl}>
                <CarImage className="image" src={car.photo} />
              </Link>
            </div>
            <div className="name_container">
              <Link
                className="name"
                to={offerPageUrl}
              >{`${car.brand.name} ${getLocalText(car.model.name)} ${getLocalText(car.versionName)}`}</Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TitlesList;
