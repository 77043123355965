import React from "react";

import { isWidgetMode, widgetConfig } from "app/utils/frameService";
import { expressionToFilter, filterToExpressionValue } from "utils";

export const FiltersContext = React.createContext();

const getValueFromDefault = (filter, defaultValue, resetFilters) => {
  let isNumberFilter = !!filter.max;
  let numberFilterSeparator = null;
  if (isNumberFilter && defaultValue.length > 0) {
    numberFilterSeparator = defaultValue[0].indexOf("..");
  }

  return {
    values: filter.values
      ? filter.values.map(value => {
          let checked = false;
          if (defaultValue.length > 0 && !isNumberFilter) {
            checked = defaultValue.some(item => item === value.id);
          }
          if (!checked && !resetFilters) {
            checked = value.checked;
          }
          return Object.assign({}, value, {
            checked: checked,
          });
        })
      : null,
    ...(isNumberFilter
      ? {
          from: numberFilterSeparator
            ? defaultValue.substring(0, numberFilterSeparator)
            : "",
          to: numberFilterSeparator
            ? defaultValue.substring(numberFilterSeparator + 2)
            : "",
        }
      : {}),
  };
};

class Filters extends React.Component {
  state = {
    offersList: this.props.offersList || {
      expressions: [],
      filterSet: "ALL",
      lastSelectedFilterId: 0,
      filters: [],
      expanded: {},
      previousFilters: [],
    },
    dealers: this.props.dealers || {
      expressions: [],
      filterSet: "ALL",
      lastSelectedFilterId: 0,
      filters: [],
      expanded: {},
      previousFilters: [],
    },
    isPopupShown: false,
  };

  changeState = (newFiltersState, filtersLocation, newState) => {
    let state = this.state;
    if (filtersLocation) {
      state[filtersLocation] = {
        ...state[filtersLocation],
        ...newFiltersState,
      };
    }
    if (newState) {
      state = {
        ...state,
        ...newState,
      };
    }
    this.setState({
      ...state,
    });
  };

  setFiltersData = (
    filters,
    expressions,
    filterSet,
    expanded,
    filtersLocation,
  ) => {
    const state = this.state[filtersLocation];
    const result = getFiltersData({
      filters,
      expressions,
      filterSet,
      expanded,
      lastSelectedFilterId: state.lastSelectedFilterId,
      expandedFromState: state.expanded,
      filterSetFromState: state.filterSet,
    });
    this.changeState(
      {
        ...result,
      },
      filtersLocation,
    );
  };

  selectFilter = (filterId, valueId, filtersLocation) => {
    const state = this.state[filtersLocation];
    const selectedFilterExpressionIndex = state.expressions.findIndex(
      expression => expression.indexOf(filterId) === 0,
    );

    const selectedFilterIndex = state.filters.findIndex(
      ({ id }) => id === filterId,
    );
    const selectedFilter = state.filters[selectedFilterIndex];
    let newFilter = {};

    switch (selectedFilter.__typename) {
      case "EnumFilter":
        const { multiple, values } = selectedFilter;

        const valueIndex = values.findIndex(({ id }) => id === valueId);
        const valueChecked = !values[valueIndex].checked;
        const currentValueIndex = values.findIndex(
          ({ checked }, index) => checked && index !== valueIndex,
        );

        let newValues = [
          ...values.slice(0, valueIndex),
          {
            ...values[valueIndex],
            checked: valueChecked,
          },
          ...values.slice(valueIndex + 1),
        ];

        if (valueChecked && !multiple && currentValueIndex >= 0) {
          newValues = newValues.map((value, index) =>
            value.checked && index !== valueIndex
              ? {
                  ...value,
                  checked: false,
                }
              : value,
          );
        }

        newFilter = {
          ...selectedFilter,
          values: newValues,
          selected: valueChecked || currentValueIndex >= 0,
        };
        break;

      case "NumberFilter":
        break;
    }

    const newFilterExpressionValue = `${
      selectedFilter.id
    }=${filterToExpressionValue(newFilter)}`;

    const newFilters = [
      ...state.filters.slice(0, selectedFilterIndex),
      newFilter,
      ...state.filters.slice(selectedFilterIndex + 1),
    ];
    this.changeState(
      {
        lastSelectedFilterId: selectedFilter.id,
        filters: newFilters,
        expressions:
          selectedFilterExpressionIndex >= 0
            ? [
                ...state.expressions.slice(0, selectedFilterExpressionIndex),
                newFilterExpressionValue,
                ...state.expressions.slice(selectedFilterExpressionIndex + 1),
              ]
            : filterId !== "1101" && filterId !== "1102"
            ? [...state.expressions, newFilterExpressionValue]
            : state.expressions,
      },
      filtersLocation,
    );
    return newFilters;
  };

  unSelectFilter = (filterId, filtersLocation) => {
    const state = this.state[filtersLocation];
    const filtersToUnselect = {};
    let parentIds = [filterId];

    do {
      parentIds.forEach(id => {
        filtersToUnselect[id] = true;
      });
      parentIds = state.filters
        .filter(filter =>
          parentIds.some(parentId => parentId === filter.depend),
        )
        .map(filter => filter.id);
    } while (parentIds.length > 0);

    const newFilters = state.filters.map(filter => {
      if (!filtersToUnselect[filter.id]) {
        return filter;
      }
      const values = getValueFromDefault(filter, filter.defaultValue, true);
      return Object.assign({}, filter, {
        expanded: false,
        selected: filter.defaultValue.length > 0,
        ...(values.from !== undefined
          ? {
              from: values.from,
              to: values.to,
            }
          : {
              values: values.values ? values.values : null,
            }),
      });
    });
    this.changeState(
      {
        filters: newFilters,
      },
      filtersLocation,
    );
    return newFilters;
  };

  selectRange = (filterId, from, to, filtersLocation) => {
    const state = this.state[filtersLocation];
    const selectedFilterIndex = state.filters.findIndex(
      ({ id }) => id === filterId,
    );
    const selectedFilter = state.filters[selectedFilterIndex];
    const { min, max } = selectedFilter;
    // If the range is equal [min, max]
    // this means that filter is unselect
    const isFromChanged = from && from !== min;
    const isToChanged = to && to !== max;
    const isChanged = isFromChanged || isToChanged;

    const newFilters = [
      ...state.filters.slice(0, selectedFilterIndex),
      {
        ...selectedFilter,
        selected: isChanged,
        from: isFromChanged ? from : "",
        to: isToChanged ? to : "",
      },
      ...state.filters.slice(selectedFilterIndex + 1),
    ];
    this.changeState(
      {
        filters: newFilters,
      },
      filtersLocation,
    );
    return newFilters;
  };

  dropFilters = filtersLocation => {
    const state = this.state[filtersLocation];
    const newFilters = state.filters.map(filter => {
      const values = getValueFromDefault(filter, filter.defaultValue, true);

      return Object.assign({}, filter, {
        expanded: false,
        selected: filter.defaultValue.length > 0,
        ...(values.from
          ? {
              from: values.from || null,
              to: values.to || null,
            }
          : {
              values: values.values ? values.values : null,
            }),
      });
    });
    this.changeState(
      {
        expanded: {},
        expressions: [],
        filters: newFilters,
      },
      filtersLocation,
    );
    return newFilters;
  };

  toggleFilter = (filterId, filtersLocation) => {
    const state = this.state[filtersLocation];
    const newState = {
      expanded: {
        ...state.expanded,
        [filterId]: !state.expanded[filterId],
      },
    };
    this.changeState(newState, filtersLocation);
  };

  render() {
    return (
      <FiltersContext.Provider
        value={{
          ...this.state,
          functions: {
            changeState: this.changeState,
            setFiltersData: this.setFiltersData,
            selectFilter: this.selectFilter,
            unSelectFilter: this.unSelectFilter,
            selectRange: this.selectRange,
            dropFilters: this.dropFilters,
            toggleFilter: this.toggleFilter,
          },
        }}
      >
        {this.props.children}
      </FiltersContext.Provider>
    );
  }
}

export const getFiltersData = ({
  filters,
  expressions,
  filterSet,
  expanded,
  lastSelectedFilterId = 0,
  expandedFromState = {},
  filterSetFromState = "ALL",
}) => {
  const selectedFiltersMap = expressions.reduce((res, expr) => {
    const { filterId, value } = expressionToFilter(expr);
    res[filterId] = { value };
    return res;
  }, {});

  const newFilters = filters.reduce((res, filter) => {
    let defaultValue = [];

    // Exclude dependent filters,
    // whose masters are not being selected
    if (filter.depend && !selectedFiltersMap[filter.depend]) {
      //Close depended filter if his master was reset
      expandedFromState[filter.id] = false;
      return res;
    }

    const selected =
      filter.__typename === "EnumFilter"
        ? filter.values.some(value => value.checked)
        : !!selectedFiltersMap[filter.id];

    if (selected && filter.__typename !== "NumberFilter") {
      selectedFiltersMap[filter.id] = true;
    }

    let isDefaultValue = false;
    let isVisibleSelectedValues = true;
    if (isWidgetMode && widgetConfig && widgetConfig.filtersList) {
      const widgetFilter = widgetConfig.filtersList.filtersList.find(
        widgetFilter => widgetFilter.filterId === filter.id,
      );

      if (widgetFilter) {
        defaultValue = !widgetFilter.isSoft ? widgetFilter.expressionsList : [];

        const values = getValueFromDefault(filter, defaultValue, false);
        filter.values = values.values ? values.values : null;
        filter.from = values.from ? values.from : null;
        filter.to = values.to ? values.to : null;

        isVisibleSelectedValues = false;
        if (values.values) {
          filter.values.forEach(value => {
            const isInValuesList = widgetFilter.selectedValuesIdList.some(
              selectedValueID => selectedValueID === value.id,
            );
            value.isInvisible = widgetFilter.isAllValuesEnable
              ? isInValuesList
              : !isInValuesList;
            if (!value.isInvisible && value.checked) {
              isVisibleSelectedValues = true;
            }
          });
        }

        isDefaultValue = defaultValue.length > 0 && isVisibleSelectedValues;
      }
    }

    res.push({
      ...filter,
      selected:
        (selected && filter.__typename === "NumberFilter") ||
        (selected && isVisibleSelectedValues) ||
        isDefaultValue,
      defaultValue,
      // Appending NumberFilter values got from expression,
      // due to they don't send by the server back
      ...(selected &&
      filter.__typename === "NumberFilter" &&
      !filter.from &&
      !filter.to
        ? selectedFiltersMap[filter.id].value
        : {}),
    });

    return res;
  }, []);

  //Expand depended filters
  const dependedFilters = newFilters.filter(
    filter => filter.depend === lastSelectedFilterId,
  );

  let newExpanded = expandedFromState;
  if (dependedFilters.length > 0) {
    newExpanded[lastSelectedFilterId] = false;
    dependedFilters.forEach(filter => {
      if (filter.values.length > 1 && filter.id !== "1009") {
        newExpanded[filter.id] = true;
      }
    });
    window.scroll({ top: 0, behavior: "smooth" });
  } else if (expanded) {
    newExpanded = expanded;
  }

  return {
    expressions: expressions,
    filters: newFilters,
    expanded: newExpanded,
    filterSet: filterSet || filterSetFromState,
    lastSelectedFilterId: 0,
  };
};

export const FiltersProvider = Filters;
