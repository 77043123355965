import React from "react";
import classNames from "classnames";

import { isWidgetMode, widgetConfig, getCreditData } from "utils/frameService";
import { formatNumber } from "utils";
import { getLocalText, getText } from "app/utils/i18nService";
import Price from "components/ui/Price";
import Tooltip from "components/ui/Tooltip";

import "./styles.less";

const PricesBlock = ({
  price,
  isPriceCard = false,
  marketingPrice,
  onlyCreditPrice = false,
  withoutCreditPrice = false,
}) => {
  let creditData =
    widgetConfig && widgetConfig.creditPrice && widgetConfig.creditPrice.prices
      ? getCreditData(price.low)
      : null;
  return price && price.low ? (
    <div className="prices-block">
      {!onlyCreditPrice && (
        <div
          className={classNames(
            "price_container",
            !(
              isWidgetMode &&
              widgetConfig.creditPrice &&
              widgetConfig.creditPrice.showCreditPrice &&
              !withoutCreditPrice
            ) &&
              price.low === price.high &&
              "solo-price",
          )}
        >
          <span className="label">
            {price.low !== price.high ? getLocalText("от") : ""}
            {!isWidgetMode && isPriceCard && (
              <Tooltip
                content={`• Текущая ситуация на рынке не позволяет, к сожалению, предоставить Вам прозрачную информацию по стоимости автомобилей. 
                    Мы по-прежнему показываем Вам цены, которые нам дали дилеры, но учитывайте, что в связи с дефицитом ходовых моделей 
                    реальная цена может быть больше за счет установленных "допов" и прочих подобных нюансов`}
                distance={10}
                touch={true}
                interactive={true}
              >
                <div className="tooltip">?</div>
              </Tooltip>
            )}
          </span>
          {isWidgetMode && widgetConfig.pricePrefix && (
            <span className="price-prefix">{widgetConfig.pricePrefix}</span>
          )}
          <span
            className={classNames(
              "price",
              price.low === price.high &&
                (!isWidgetMode ||
                  (isWidgetMode && !widgetConfig.pricePrefix)) &&
                "without-label",
            )}
          >
            <Price value={price.low} />
          </span>
          {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
            <span
              className={classNames(
                "currency",
                isWidgetMode &&
                  widgetConfig.creditPrice &&
                  widgetConfig.creditPrice.showCreditPrice &&
                  !withoutCreditPrice &&
                  "fixed-width",
              )}
            >
              {getLocalText("₽")}
            </span>
          )}
        </div>
      )}
      {!onlyCreditPrice &&
        marketingPrice &&
        (!isWidgetMode || (isWidgetMode && widgetConfig.showSalePrice)) &&
        marketingPrice.low + 1000 < price.low && (
          <div className="price_container">
            <div className="label marketing">
              {getLocalText("мин. от")}
              <Tooltip
                content={`• ${formatNumber(price.low)} – ${
                  !isWidgetMode
                    ? "ориентировочная цена"
                    : "цена по прайс-листу дилера"
                }
                    <br /> • мин. от ${formatNumber(marketingPrice.low)} -
                    минимально возможная цена с учетом скидок, предоставляемых
                    дилером. Условия получения скидок уточняйте у дилера.
                  `}
                interactive={true}
                distance={10}
                touch={true}
                appendTo={document.body}
              >
                <div className="tooltip">?</div>
              </Tooltip>
            </div>
            {isWidgetMode && widgetConfig.pricePrefix && (
              <span className="price-prefix">{widgetConfig.pricePrefix}</span>
            )}
            <span className="price">
              <Price value={marketingPrice.low} />
            </span>
            {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
              <span
                className={classNames(
                  "currency",
                  isWidgetMode &&
                    widgetConfig.creditPrice &&
                    widgetConfig.creditPrice.showCreditPrice &&
                    !withoutCreditPrice &&
                    "fixed-width",
                )}
              >
                {getLocalText("₽")}
              </span>
            )}
          </div>
        )}
      {isWidgetMode &&
        widgetConfig.creditPrice &&
        widgetConfig.creditPrice.showCreditPrice &&
        !withoutCreditPrice && (
          <div className="price_container">
            <div className="label">
              {getLocalText(getText("common.pricesBlock.creditPriceLabel"))}
              {creditData.label && (
                <Tooltip
                  content={creditData.label.content}
                  distance={10}
                  touch={true}
                  interactive={creditData.label.isInteractive}
                  appendTo={document.body}
                >
                  <div className="tooltip">?</div>
                </Tooltip>
              )}
            </div>
            {isWidgetMode && widgetConfig.pricePrefix && (
              <span className="price-prefix">{getLocalText("₽/мес")}</span>
            )}
            <span className="price">
              <Price value={creditData.price} />
            </span>
            {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
              <span className="currency fixed-width">
                {getLocalText("₽/мес")}
              </span>
            )}
          </div>
        )}
    </div>
  ) : null;
};

export default PricesBlock;
