import React from "react";

import { REQUEST_STATUS } from "app/constants";

import graphqlClient from "app/graphql/services/client";
import {
  getOfferDetail,
  getOfferDetailPrices,
  getOffersCount,
  getOffersList,
} from "app/graphql/queries";
import { receiveFilters } from "reducers/filters";

export const OffersListContext = React.createContext();

class OffersList extends React.Component {
  state = {
    endCursor: this.props.endCursor || "0",
    offers: this.props.offers || [],
    results: this.props.results || {
      totalCount: 0,
    },
    sorting: this.props.sorting || "PRICE_ASC",
    // appendResults: this.props.appendResults || true,
    status: this.props.status || REQUEST_STATUS.NONE,
  };

  resetStatus = () => {
    this.setState({
      status: REQUEST_STATUS.NONE,
    });
  };

  getOffersList = (vars, setFiltersData) => {
    this.setState({ status: REQUEST_STATUS.LOADING });
    loadOffersList(vars, this.state, setFiltersData).then(
      result => {
        this.setState(result);
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  getOffersCount = (vars, setFiltersData) => {
    loadOffersCount(vars, setFiltersData).then(
      result => {
        if (result) {
          this.setState({ results: result.results });
        }
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  setOffersResults = offersResults => {
    this.setState({ results: offersResults });
  };

  render() {
    return (
      <OffersListContext.Provider
        value={{
          ...this.state,
          functions: {
            getOffersList: this.getOffersList,
            getOffersCount: this.getOffersCount,
            setOffersResults: this.setOffersResults,
            resetStatus: this.resetStatus,
          },
        }}
      >
        {this.props.children}
      </OffersListContext.Provider>
    );
  }
}

const defaultVariables = {
  after: null, // String
  geo: null, // Any
  geoPath: null, // String
  resourcePath: null, // String
  expressions: [], // Array
  domain: {}, // Any
  sortBy: null, // String, values: 'NAME' | 'POWER' | 'PRICE'
  sortDir: null, // String, values: 'ASC' | 'DESC'
  filterSet: "ALL", // String, values: 'BASIC' | 'ALL'
};
export const loadOffersList = (vars, state, setFiltersData) => {
  const variables = { ...defaultVariables, ...vars };

  return new Promise((resolve, reject) => {
    graphqlClient
      .fetch(getOffersList, variables)
      .then(
        response => {
          if (setFiltersData) {
            setFiltersData(
              response.context.filters,
              variables.expressions || [],
              variables.filterSet,
              null,
              "offersList",
            );
          }
          const result = {
            // appendResults: variables.after != null,
            endCursor: response.context.search.pageInfo
              ? response.context.search.pageInfo.endCursor
              : null,
            offers:
              variables.after && state
                ? [...state.offers, ...response.context.search.edges]
                : response.context.search.edges,
            results: {
              dealerCount: response.context.search.dealerCount,
              offerCount: response.context.search.offerCount,
              totalCount: response.context.search.totalCount,
            },
            sorting: `${variables.sortBy}_${variables.sortDir}`,
            status: REQUEST_STATUS.SUCCESS,
            filters: {
              filters: response.context.filters,
              expressions: variables.expressions || [],
              filtersSet: variables.filterSet,
              expanded: {},
            },
          };
          resolve(result);
        },
        () => {
          reject();
        },
      )
      .catch(() => reject());
  });
};

let previousRequestController = null;
export const loadOffersCount = (vars, setFiltersData) => {
  const variables = { ...defaultVariables, ...vars };

  return new Promise((resolve, reject) => {
    if (previousRequestController) {
      previousRequestController.cancel();
    }
    previousRequestController = {};

    graphqlClient
      .fetch(getOffersCount, variables, previousRequestController)
      .then(
        response => {
          previousRequestController = null;

          setFiltersData(
            response.context.filters,
            variables.expressions || [],
            variables.filterSet,
            null,
            "offersList",
          );

          const result = {
            results: {
              dealerCount: response.context.search.dealerCount,
              offerCount: response.context.search.offerCount,
              totalCount: response.context.search.totalCount,
            },
          };
          resolve(result);
        },
        error => {
          if (error !== "canceled") {
            previousRequestController = null;
            reject();
          }
          resolve(null);
        },
      );
  });
};

export const OffersListProvider = OffersList;
