import React from "react";
import { withRouter } from "react-router";

export const RouterHistoryListContext = React.createContext();

class RouterHistoryList extends React.Component {
  prevHistoryLength = 0;
  state = {
    routerHistoryList: [this.props.location],
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const { routerHistoryList } = this.state;

      if (
        action === "PUSH" &&
        this.prevHistoryLength !== this.props.history.length
      ) {
        routerHistoryList.push(location);
        this.prevHistoryLength = this.props.history.length;
        this.setState({ routerHistoryList: routerHistoryList });
      }
      if (action === "REPLACE") {
        let lastRouter = routerHistoryList[routerHistoryList.length - 1];
        lastRouter.pathname = location.pathname;
        lastRouter.search = location.search;
        this.setState({ routerHistoryList: routerHistoryList });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <RouterHistoryListContext.Provider value={this.state.routerHistoryList}>
        {this.props.children}
      </RouterHistoryListContext.Provider>
    );
  }
}
export const RouterHistoryListProvider = withRouter(RouterHistoryList);
