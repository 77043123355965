import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import moment from "moment";

import { declOfNum, formatPhone, getCarsList } from "utils";
import {
  isWidgetMode,
  widgetConfig,
  isContactsModeEnable,
} from "utils/frameService";
import { useWindowSize } from "utils/windowSizeHook";

import CheckBox from "components/ui/CheckBox";
import PricesBlock from "components/shared/PricesBlock";
import Car from "components/DealersPage/Car";

import "./styles.less";
import TextButton from "components/ui/TextButton";
import graphqlClient from "app/graphql/services/client";
import { showPhoneNumberRequest } from "app/graphql/mutations";
import { parentUrl } from "app/utils/frameService";
import { LOCAL_STORAGE_PHONENUMBER_KEY } from "app/constants";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const Dealer = props => {
  const {
    selectDealer,
    selectedDealers,
    dealer,
    openMobileDealerCarsList,
    setPopupSettings,
    equipmentId,
    pathname,
  } = props;
  const { isTabletWidth } = useWindowSize();
  let phoneExpireInterval = null;
  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isCallRunning, setIsCallRunning] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({
    show: false,
    number: formatPhone(dealer.node.tollFreePhoneNumber.number),
    extension: isWidgetMode ? dealer.node.tollFreePhoneNumber.extension : null,
    isFree: true,
  });
  const widgetMode =
    isWidgetMode &&
    widgetConfig &&
    widgetConfig.contactBtn &&
    !isContactsModeEnable;

  const didMountRef = useRef(false);
  useEffect(() => {
    if (!didMountRef.current) {
      setDidMount(true);

      if (!isWidgetMode) {
        let phoneNumbersList =
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_PHONENUMBER_KEY)) || [];
        const currentPhoneNumber = phoneNumbersList.find(
          item =>
            item.dealerId === dealer.node.id &&
            item.equipmentId === equipmentId,
        );
        if (
          currentPhoneNumber &&
          !moment().isAfter(moment(currentPhoneNumber.expireTime))
        ) {
          setPhoneNumber({
            show: true,
            number: currentPhoneNumber.number,
            extension: currentPhoneNumber.extension,
            isFree: currentPhoneNumber.isFree,
          });
          createPhoneExpireTimeout(moment(currentPhoneNumber.expireTime));
        }
      }
    }

    return () => {
      if (phoneExpireInterval) {
        clearInterval(phoneExpireInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (isCallRunning) {
      window.location = createCallLink(phoneNumber.number);
      setIsCallRunning(false);
    }
  }, [phoneNumber]);

  const onDealerSelect = () => {
    if (widgetMode) {
      return;
    }
    selectDealer(dealer.node.id);
  };

  const onShowPhoneClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let clientId = "";
    try {
      if (ga) {
        clientId = ga.getAll()[0].get("clientId");
      }
    } catch {}
    graphqlClient
      .fetch(showPhoneNumberRequest, {
        dealer: dealer.node.id,
        edition: equipmentId,
        url: `${
          isWidgetMode
            ? parentUrl
            : `${window.location.protocol}//${window.location.host}`
        }${pathname}`,
        ga: clientId,
      })
      .then(result => {
        const data = result.showPhonenumber.phonenumber;
        const phoneNumber =
          data.number.indexOf("+") !== -1
            ? `+${formatPhone(data.number.replace("+", ""))}`
            : formatPhone(data.number);
        const isFree = phoneNumber.indexOf("+7") === -1;
        setPhoneNumber({
          show: true,
          number: phoneNumber,
          extension: data.extension,
          isFree,
        });

        if (data.expireTime) {
          const phoneNumbersList =
            JSON.parse(localStorage.getItem(LOCAL_STORAGE_PHONENUMBER_KEY)) ||
            [];
          const currentPhoneNumber = phoneNumbersList.find(
            item =>
              item.dealerId === dealer.node.id &&
              item.equipmentId === equipmentId,
          );
          if (currentPhoneNumber) {
            currentPhoneNumber.number = phoneNumber;
            currentPhoneNumber.extension = data.extension;
            currentPhoneNumber.isFree = isFree;
            currentPhoneNumber.expireTime = data.expireTime;
          } else {
            phoneNumbersList.push({
              number: phoneNumber,
              extension: data.extension,
              isFree,
              expireTime: data.expireTime,
              dealerId: dealer.node.id,
              equipmentId: equipmentId,
            });
          }
          localStorage.setItem(
            LOCAL_STORAGE_PHONENUMBER_KEY,
            JSON.stringify(phoneNumbersList),
          );
          createPhoneExpireTimeout(moment(data.expireTime));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRunCallClick = () => {
    if (isWidgetMode) {
      window.location = createCallLink(commonPhoneNumber);
    } else {
      if (!phoneNumber.show) {
        onShowPhoneClick();
        setIsCallRunning(true);
      } else {
        window.location = createCallLink(commonPhoneNumber);
      }
    }
  };

  const createCallLink = phone => {
    return phone[0] === "+"
      ? `tel:${phone.replace(/[ ]/g, "-")}${
          phoneNumber.extension ? `,${phoneNumber.extension}` : ""
        }`
      : `tel:${phone.replace("8", "+7").replace(/[ ]/g, "-")}${
          phoneNumber.extension ? `,${phoneNumber.extension}` : ""
        }`;
  };

  const createPhoneExpireTimeout = expireTime => {
    if (phoneExpireInterval) {
      clearInterval(phoneExpireInterval);
    }
    phoneExpireInterval = setInterval(() => {
      if (moment().isAfter(expireTime)) {
        setPhoneNumber({
          ...phoneNumber,
          show: false,
        });
        clearInterval(phoneExpireInterval);
      }
    }, 3000);
  };

  const isSelected = selectedDealers.some(
    selectedDealerId => selectedDealerId === dealer.node.id,
  );

  let commonPhoneNumber = "";
  if (isWidgetMode && widgetConfig) {
    if (!!widgetConfig.showDealerPhone && dealer.node.phoneNumber) {
      commonPhoneNumber = `${getLocalText("+7")} ${dealer.node.phoneNumber}`;
    } else {
      commonPhoneNumber =
        widgetConfig.phoneNumber[0] === "7"
          ? `+${formatPhone(widgetConfig.phoneNumber)}`
          : formatPhone(widgetConfig.phoneNumber);
    }
  } else {
    commonPhoneNumber = phoneNumber.number;
  }

  const carsList = getCarsList(dealer, showAll);
  const showMoreOffers = dealer.variants.length > 1;
  let inStockCount = 0;
  let outStockCount = 0;
  if (isWidgetMode && !widgetConfig.showDealersFilters) {
    dealer.variants.forEach(variant => {
      variant.offers.forEach(offer => {
        if (offer.availability === "IN_STOCK") {
          inStockCount += offer.offerCount;
        } else {
          outStockCount += offer.offerCount;
        }
      });
    });
  }

  return (
    <div
      className={classNames(
        "dealer",
        showMoreOffers &&
          (!isWidgetMode || widgetConfig.showDealersFilters) &&
          "with-more-offers",
      )}
    >
      {!widgetMode && (
        <div className="checkbox_container">
          <CheckBox checked={isSelected} onClick={onDealerSelect} />
        </div>
      )}
      <div
        className={classNames(
          "content_container",
          widgetMode && "without_checkbox",
          isWidgetMode && !widgetConfig.showDealersFilters && "hide-cars-list",
        )}
      >
        <div className="dealer-info_container">
          <div className="title_container">
            <div
              className={classNames("title", !widgetMode && "active")}
              onClick={onDealerSelect}
            >
              {dealer.node.name}
            </div>
            <div className="address">
              {`${dealer.node.region.name}. ${dealer.node.address.formatted}`}
            </div>
          </div>
          {isWidgetMode && !widgetConfig.showDealersFilters && (
            <div className="prices_container">
              <PricesBlock
                price={dealer.price}
                marketingPrice={dealer.marketingPrice}
                withoutCreditPrice={true}
              />
              <div className="car-availability">
                {inStockCount !== 0
                  ? outStockCount !== 0
                    ? `${inStockCount +
                        outStockCount} авто (${inStockCount} в наличии)`
                    : `${inStockCount} авто в наличии`
                  : `${outStockCount} авто в пути`}
              </div>
            </div>
          )}
          <div className="phone_container">
            {!phoneNumber.show && !isWidgetMode ? (
              <TextButton
                text={getLocalText("Показать телефон")}
                className="show-phone_button"
                color="gray"
                loading={loading}
                onClick={onShowPhoneClick}
              />
            ) : (
              <>
                <span className="phone-number">{commonPhoneNumber}</span>
                {!(
                  isWidgetMode &&
                  widgetConfig &&
                  widgetConfig.showDealerPhone
                ) &&
                  phoneNumber.extension && (
                    <span className="extra-number">
                      {`, доб. ${phoneNumber.extension}`}
                    </span>
                  )}
              </>
            )}
          </div>
          <div className="phone_container_mobile">
            {loading && (
              <div className="loading_container">
                <img src={require("loader.gif").default} />
              </div>
            )}
            <div className="phone_button_container">
              <a
                className="link show-phone_button-mobile"
                target="_parent"
                onClick={onRunCallClick}
              >
                <i className="icon icon-call-phone" />
              </a>
              {!(
                isWidgetMode &&
                widgetConfig &&
                widgetConfig.showDealerPhone
              ) && (
                <div className="phone-code">
                  {phoneNumber.extension ? `#${phoneNumber.extension}` : ""}
                </div>
              )}
            </div>
          </div>
        </div>
        {(!isWidgetMode || widgetConfig.showDealersFilters) && (
          <div className="cars-list_container">
            <div className="cars-list">
              {carsList.map(car => {
                return (
                  <Car
                    car={car}
                    key={`${car.picture}_${car.year}_${car.price.low}`}
                    setPopupSettings={setPopupSettings}
                  />
                );
              })}
            </div>
            {!isTabletWidth && showMoreOffers && (
              <div
                className="more-offers"
                onClick={() => {
                  setShowAll(!showAll);
                }}
              >
                {showAll
                  ? getLocalText("Свернуть")
                  : getLocalTextWithNumbers("Ещё ${number} ${changeableText}", [
                      {
                        id: "number",
                        value: dealer.variants.length - 1,
                      },
                      {
                        id: "changeableText",
                        value: declOfNum(dealer.variants.length - 1, [
                          "предложение",
                          "предложения",
                          "предложений",
                        ]),
                      },
                    ])}
              </div>
            )}
          </div>
        )}
      </div>
      {(isTabletWidth || !didMount) &&
        showMoreOffers &&
        (!isWidgetMode || widgetConfig.showDealersFilters) && (
          <div
            className="more-offers-mobile"
            onClick={() => {
              openMobileDealerCarsList(dealer.node.id);
            }}
          >
            {getLocalTextWithNumbers("Ещё ${number} ${changeableText}", [
              {
                id: "number",
                value: dealer.variants.length - 1,
              },
              {
                id: "changeableText",
                value: declOfNum(dealer.variants.length - 1, [
                  "предложение",
                  "предложения",
                  "предложений",
                ]),
              },
            ])}
            <i className="icon icon-next" />
          </div>
        )}
    </div>
  );
};

export default Dealer;
