import React, { useState, useEffect, useContext, useRef } from "react";

import { REQUEST_STATUS, MOBILE_WIDTH_MAX } from "app/constants";

import { RegionsContext, getPopularAndSelectedRegions } from "context/Regions";
import { CompareContext, loadCars, loadSpecification } from "context/Compare";
import { RouterHistoryListContext } from "context/RouterHistoryList";

import {
  getUpdatedUrlSearch,
  generateUrlWithRegionAndRadius,
  parseUrlToFilter,
  removeURLParameter,
} from "utils";
import {
  getDefaultDomainFilters,
  getDefaultExpressionsAndRegions,
} from "app/utils/filtersService";
import { isWidgetMode, onScrollTo } from "app/utils/frameService";

import CompareHeader from "components/ComparePage/CompareHeader";
import SelectedCarsList from "components/ComparePage/SelectedCarsList";
import CompareList from "components/ComparePage/CompareList";
import Header from "components/Header";
import Footer from "components/Footer";
import Loading from "components/Loading";
import Seo from "components/Seo";

import "./styles.less";
import { useWindowSize } from "app/utils/windowSizeHook";
import { getLocalText } from "app/utils/i18nService";
import { useUpdateEffect } from "app/utils/useUpdateEffect";

const getSpecificationsList = (carsList, specificationsList) => {
  //Detect similar cars
  let allCarsIsSimilar = true;
  for (let i = 0; i < carsList.length; i++) {
    for (let j = i + 1; j < carsList.length; j++) {
      if (
        carsList[i].brand.name !== carsList[j].brand.name ||
        carsList[i].model.name !== carsList[j].model.name
      ) {
        allCarsIsSimilar = false;
        break;
      }
    }
    if (!allCarsIsSimilar) {
      break;
    }
  }
  //Get parametersList
  let parametersList = JSON.parse(JSON.stringify(specificationsList));
  parametersList = parametersList.map((specification, specIndex) => {
    specification.features = specification.features.map(specFeature => {
      specFeature.values = carsList.map((car, index) => {
        let filter = car.filters.find(filter => filter.i === specFeature.i);
        let value = null;
        let descriptionValue = null;
        if (filter) {
          value = filter.value !== null ? filter.value : getLocalText("есть");
          descriptionValue =
            filter.ins && filter.ins.length > 0
              ? filter.ins.map(item => item.description)
              : null;
        }

        return {
          key: car.id + index,
          value: value,
          description: descriptionValue,
        };
      });
      return specFeature;
    });

    const additionalOptions = [];
    const hasAdditionalOptions = carsList.some(car =>
      car.filters.some(filter => String(filter.i) === `${specIndex + 1}999`),
    );
    if (hasAdditionalOptions) {
      let listOfDescriptionsWithValue = [];
      const listOfOptionsFilters = [];
      carsList.forEach((car, index) => {
        const additionalOptionsFilter = car.filters.find(
          filter => String(filter.i) === `${specIndex + 1}999`,
        );
        if (additionalOptionsFilter) {
          listOfOptionsFilters.push({
            id: car.id,
            filter: additionalOptionsFilter,
          });
          additionalOptionsFilter.ins.forEach(item => {
            const descriptionWithValue = listOfDescriptionsWithValue.find(
              desc => desc.value === item.description,
            );
            if (descriptionWithValue) {
              descriptionWithValue.count = descriptionWithValue.count + 1;
            } else {
              listOfDescriptionsWithValue.push({
                value: item.description,
                count: 1,
              });
            }
          });
        }
      });
      listOfDescriptionsWithValue = listOfDescriptionsWithValue.sort((a, b) =>
        b.count - a.count === 0
          ? a.value < b.value
            ? -1
            : a.value > b.value
            ? 1
            : 0
          : b.count - a.count,
      );

      //Show additionalOptions only if models are same
      const firstCarModel = carsList[0].filters.find(
        filter => filter.i === 1102,
      );
      const firstCarYear = carsList[0].filters.find(
        filter => filter.i === 1106,
      );
      let addAdditionalOptions = carsList.every(car => {
        const model = car.filters.find(filter => filter.i === 1102);
        const year = car.filters.find(filter => filter.i === 1106);

        return (
          firstCarModel.value === model.value &&
          firstCarYear.value === year.value
        );
      });

      if (addAdditionalOptions) {
        carsList.forEach((car, index) => {
          const additionalOptionsFilter = listOfOptionsFilters.find(
            item => item.id === car.id,
          );
          additionalOptions.push({
            key: car.id + index,
            value: additionalOptionsFilter ? getLocalText("есть") : null,
            description: additionalOptionsFilter
              ? listOfDescriptionsWithValue
                  .map(item =>
                    additionalOptionsFilter.filter.ins.some(
                      item2 => item2.description === item.value,
                    )
                      ? item.value
                      : null,
                  )
                  .filter(item => !!item)
              : null,
          });
        });
        specification.features.push({
          name: "Прочее описание",
          values: additionalOptions,
        });
      }
    }

    return specification;
  });
  //Get differentParametersList
  let differentParametersList = [];
  if (carsList.length > 1) {
    parametersList.forEach(specification => {
      let hasSpecificationDifference = false;
      //Create new object (with new Ref)
      let newSpecification = Object.assign({}, specification);
      newSpecification.features = [];
      specification.features.forEach(specFeature => {
        let hasSpecFeatureDifference = false;
        //Compare all values with all values: optimized algorithm
        let similarDescriptionValues = [];
        for (let i = 0; i < specFeature.values.length - 1; i++) {
          for (let j = i + 1; j < specFeature.values.length; j++) {
            //Compare values
            if (allCarsIsSimilar) {
              let isSimilarDescription = true;
              let desc = specFeature.values[i].description;
              let desc2 = specFeature.values[j].description;
              if (desc && desc2) {
                if (desc.length !== desc2.length) {
                  similarDescriptionValues = desc.filter(item =>
                    desc2.some(desc2 => item === desc2),
                  );
                  isSimilarDescription = false;
                } else {
                  for (let iDesc = 0; iDesc < desc.length; iDesc++) {
                    if (!desc2.some(desc2 => desc[iDesc] === desc2)) {
                      similarDescriptionValues = desc.filter(item =>
                        desc2.some(desc2 => item === desc2),
                      );
                      isSimilarDescription = false;
                      break;
                    }
                  }
                }
              }
              if (
                specFeature.values[i].value !== specFeature.values[j].value ||
                !isSimilarDescription
              ) {
                hasSpecFeatureDifference = true;
                break;
              }
            } else {
              if (specFeature.values[i].value !== specFeature.values[j].value) {
                hasSpecFeatureDifference = true;
                break;
              }
            }
          }
          if (hasSpecFeatureDifference) {
            break;
          }
        }
        if (hasSpecFeatureDifference) {
          hasSpecificationDifference = true;
          if (similarDescriptionValues.length > 0) {
            let newSpecFeature = { name: specFeature.name, values: [] };
            specFeature.values.forEach(value => {
              const description = value.description
                ? value.description.filter(
                    item =>
                      !similarDescriptionValues.some(item2 => item2 === item),
                  )
                : [];
              newSpecFeature.values.push({
                key: value.key,
                value:
                  value.description && value.description.length > 0
                    ? value.value
                    : getLocalText("нет"),
                description: description.length > 0 ? description : null,
              });
            });
            newSpecification.features.push(newSpecFeature);
          } else {
            newSpecification.features.push(specFeature);
          }
        }
      });
      if (hasSpecificationDifference) {
        differentParametersList.push(newSpecification);
      }
    });
  }

  return {
    specificationsList: parametersList,
    differentSpecificationsList: differentParametersList,
  };
};

//Always return desktop version from SSR but rerender to mobile on client if that need
const ComparePage = props => {
  const { regions, radius, selectedRegions } = useContext(RegionsContext);
  const {
    carsList,
    carsIdList,
    status,
    specificationsList: specificationsListFromContext,
    functions,
  } = useContext(CompareContext);
  const routerHistoryList = useContext(RouterHistoryListContext);
  const [state, setState] = useState({
    specificationsList: [],
    differentSpecificationsList: [],
    differentSpecificationsListMobile: [],
    isAllParameters: false,
    prevIsAllParametersState: null,
    showCarsList: false,
    mobileSelectedCarsId:
      carsList && carsList.length !== 0
        ? carsList.length > 1
          ? [carsList[0].id, carsList[1].id]
          : [carsList[0].id]
        : [],
  });
  const { isMobileWidth } = useWindowSize();
  const [widthState, _setWidthState] = useState({
    width: 0,
  });
  const widthStateRef = React.useRef(widthState);
  const setWidthState = newState => {
    widthStateRef.current = newState;
    _setWidthState(newState);
  };

  useEffect(() => {
    const { location, history } = props;

    window.addEventListener("resize", updateWindowWidth);
    updateWindowWidth();

    if (status !== REQUEST_STATUS.SUCCESS) {
      loadData();

      const newUrl = generateUrlWithRegionAndRadius(
        location,
        regions,
        selectedRegions,
        radius,
      );
      const params = getUpdatedUrlSearch(
        "id",
        [carsIdList.map(id => id.replace(/==$/g, ""))],
        newUrl.slice(newUrl.indexOf("?")),
      );
      history.replace(
        (newUrl.indexOf("?") !== -1 ? newUrl.split("?")[0] : newUrl) + params,
      );
    }

    //componentWillUnmount
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
      functions.resetStatus();
    };
  }, []);

  useEffect(() => {
    let search = getUpdatedUrlSearch("id", [
      carsIdList.map(id => id.replace(/==$/g, "")),
    ]);
    props.history.replace(search);

    let newSpecificationsLists = {};
    let filteredCarsList = isMobileWidth
      ? [carsList[0], carsList[1]]
      : carsList;
    filteredCarsList = filteredCarsList.filter(car => {
      return !!car;
    });

    const specifications = getSpecificationsList(
      filteredCarsList,
      specificationsListFromContext,
    );

    // let differentSpecificationsListMobile = specifications.differentSpecificationsList;

    setState({
      ...state,
      specificationsList: specifications.specificationsList,
      differentSpecificationsList: specifications.differentSpecificationsList,
      mobileSelectedCarsId: filteredCarsList.map(car => car.id),
      // differentSpecificationsListMobile: differentSpecificationsListMobile,
      isAllParameters:
        !isAllParameters && carsIdList.length < 2 ? true : isAllParameters,
    });
  }, [carsList, isMobileWidth]);

  useUpdateEffect(() => {
    loadData();
  }, [selectedRegions, radius]);

  const ssrFunction = () => {
    let filteredCarsList = isMobileWidth
      ? [carsList[0], carsList[1]]
      : carsList;
    filteredCarsList = filteredCarsList.filter(car => {
      return !!car;
    });

    const specifications = getSpecificationsList(
      filteredCarsList,
      specificationsListFromContext,
    );

    //hack for correct mobile SSR loading
    let hackCarsList = [carsList[0], carsList[1]];
    hackCarsList = hackCarsList.filter(car => {
      return !!car;
    });
    const hackSpecifications = getSpecificationsList(
      hackCarsList,
      specificationsListFromContext,
    );
    let differentSpecificationsListMobile =
      hackSpecifications.differentSpecificationsList;

    setState({
      ...state,
      specificationsList: specifications.specificationsList,
      differentSpecificationsList: specifications.differentSpecificationsList,
      mobileSelectedCarsId: filteredCarsList.map(car => car.id),
      differentSpecificationsListMobile: differentSpecificationsListMobile,
      isAllParameters:
        !isAllParameters && carsIdList.length < 2 ? true : isAllParameters,
    });
  };

  if (
    !process.browser &&
    carsList.length > 0 &&
    state.specificationsList.length === 0 &&
    specificationsListFromContext.length > 0
  ) {
    ssrFunction();
  }

  const loadData = () => {
    const vars = {
      limit: 20,
      after: "null",
      expressions: [],
      equipmentIds: carsIdList,
      geo: Object.assign(
        { ids: selectedRegions },
        radius ? { radius: radius } : {},
      ),
    };
    const defaultExpressions = getDefaultExpressionsAndRegions(
      vars.expressions,
    );
    vars.expressions = defaultExpressions.expressions;
    vars.domain = getDefaultDomainFilters();
    functions.initCompareData(vars);
  };

  const updateWindowWidth = () => {
    setWidthState({
      width: document.documentElement.clientWidth,
    });
  };

  const changeCompareMode = isAllParameters => {
    if (state.isAllParameters !== isAllParameters) {
      setState({ ...state, isAllParameters: isAllParameters });
    }
  };

  const changeSelectedCar = (sliderIndex, selectedCarId) => {
    let {
      mobileSelectedCarsId,
      isAllParameters,
      prevIsAllParametersState,
    } = state;
    mobileSelectedCarsId[sliderIndex] = selectedCarId;

    const filteredCarsList = mobileSelectedCarsId.map(id => {
      return carsList.find(car => car.id === id);
    });

    const specifications = getSpecificationsList(
      filteredCarsList,
      specificationsListFromContext,
    );

    let parametersStateObj = {};
    if (
      mobileSelectedCarsId.length >= 2 &&
      mobileSelectedCarsId[0] === mobileSelectedCarsId[1]
    ) {
      parametersStateObj = {
        prevIsAllParametersState: isAllParameters,
        isAllParameters: true,
      };
    } else if (prevIsAllParametersState !== null) {
      parametersStateObj = {
        prevIsAllParametersState: null,
        isAllParameters: prevIsAllParametersState,
      };
    }

    setState({
      ...state,
      mobileSelectedCarsId: mobileSelectedCarsId,
      specificationsList: specifications.specificationsList,
      differentSpecificationsList: specifications.differentSpecificationsList,
      ...parametersStateObj,
    });
  };

  const changeCarsListState = newState => {
    if (isWidgetMode) {
      onScrollTo(0);
    }
    setState({ ...state, showCarsList: newState });
  };

  const {
    specificationsList,
    differentSpecificationsList,
    isAllParameters,
    showCarsList,
    mobileSelectedCarsId,
    differentSpecificationsListMobile,
  } = state;
  const { width } = widthState;
  const { history, location } = props;

  const selectedRegionsArray = [];
  regions.forEach(region => {
    if (selectedRegions.some(item => item === region.id)) {
      selectedRegionsArray.push(region.resourcePath);
    }
  });

  const currentRoutingPathnameList = routerHistoryList[
    routerHistoryList.length - 1
  ].pathname.split("/");
  const showBackButton =
    isMobileWidth &&
    ((routerHistoryList.length > 1 &&
      currentRoutingPathnameList[currentRoutingPathnameList.length - 1] ===
        "compare") ||
      showCarsList);

  return (
    <div className="skeleton compare_page">
      <Seo description="" title="Сравнение автомобилей" />
      <Header
        showBackButton={showBackButton}
        showCompareBtn={!showCarsList}
        showRegionBtn={!showCarsList}
        onBackButtonClick={() => {
          if (showCarsList) {
            changeCarsListState(false);
          } else {
            history.goBack();
          }
        }}
      />
      <div id="main" className="s-content clearfix">
        {status !== REQUEST_STATUS.SUCCESS ? (
          <Loading />
        ) : isMobileWidth && showCarsList ? (
          <SelectedCarsList
            removeCar={functions.removeFromCarsIdList}
            removeAll={functions.removeAllFromCarsIdList}
            carsList={carsList}
          />
        ) : carsList.length > 0 ? (
          <>
            <CompareHeader
              isMobile={isMobileWidth}
              carsCount={carsList.length}
              removeAll={functions.removeAllFromCarsIdList}
              changeCarsListState={changeCarsListState}
              isAllParameters={isAllParameters}
              changeMode={changeCompareMode}
              mobileSelectedCarsId={mobileSelectedCarsId}
            />
            <CompareList
              isMobile={isMobileWidth}
              windowWidth={width}
              carsList={carsList}
              specificationsList={
                isAllParameters
                  ? specificationsList
                  : differentSpecificationsList
              }
              selectedRegionPath={
                selectedRegionsArray.length === 1
                  ? selectedRegionsArray[0]
                  : null
              }
              removeCar={functions.removeFromCarsIdList}
              selectedCarsId={mobileSelectedCarsId}
              changeSelectedCar={changeSelectedCar}
              search={removeURLParameter(location.search, "id")}
              differentSpecificationsListMobile={
                differentSpecificationsListMobile
              }
            />
          </>
        ) : (
          <div className="empty-list_label">
            {getLocalText("В сравнении нет автомобилей")}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

ComparePage.fetchData = (dataContext, match, request) => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;
  const params = parseUrlToFilter(
    request.originalUrl.split("?")[0],
    request.originalUrl.split("?")[1],
  );
  params.region = params.region !== "compare" ? params.region : null;

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(
      params.region,
      params.radius,
      selectedRegions,
    ).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };
      const vars = {
        expressions: [],
        equipmentIds: request.query.id ? request.query.id.split(",") : [],
        geo: Object.assign(
          { ids: regions.selectedRegions },
          regions.radius ? { radius: regions.radius } : {},
        ),
        domain: {},
      };

      Promise.all([loadCars(vars), loadSpecification()])
        .then(responsesList => {
          resolve({
            isDataContext: true,
            regions,
            compare: {
              carsIdList: vars.equipmentIds,
              carsList: responsesList[0],
              specificationsList: responsesList[1].specification,
              status: REQUEST_STATUS.SUCCESS,
            },
          });
        })
        .catch(() => {
          reject();
        });
    });
  });
};

export const CompareSSRLoad = ComparePage.fetchData;
export default ComparePage;
