export const REQUEST_STATUS = {
  NONE: "NONE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

export const MOBILE_WIDTH_MAX = 767;
export const TABLET_WIDTH_MAX = 1099;

export const FILTER_LABEL_LIFETIME_IN_MS = 3000;
export const DEFAULT_REGION_RADIUS = 200;

export const DATE_FILTERS_ID = ["1106", "1107", "1108"];
export const DEALER_FILTER_ID = "1002";

export const DEALERS_PER_PAGE_LIMIT = 400;

export const DEFAULT_SORTING_OPTIONS = [
  { value: "PRICE_ASC", label: "По возрастанию цены" },
  { value: "PRICE_DESC", label: "По убыванию цены" },
  { value: "POWER_ASC", label: "По возрастанию мощности" },
  { value: "POWER_DESC", label: "По убыванию мощности" },
  { value: "NAME_ASC", label: "По названию AZ" },
  { value: "NAME_DESC", label: "По названию ZA" },
];

export const LOCAL_STORAGE_PHONENUMBER_KEY = "ilsa_phoneNumbers";
export const LOCAL_STORAGE_INFO_NOTIFICATION_POPUP_KEY =
  "isInfoNotificationPopupShowed";
