import React from "react";
import uniqBy from "lodash/uniqBy";
import Color from "components/ui/Color";

const Colors = ({ colors, label }) => (
  <div className="c-colors">
    {label}

    {uniqBy(colors, color => color.hex).map((color, index) => (
      <Color key={index} hex={color.hex} />
    ))}
  </div>
);

export default Colors;
