import React from "react";

import "./styles.less";

const NoCarImage = () => {
  return (
    <div className="no-car-image_container">
      <div className="image_container">
        <img className="image" src={require("no-car-image.svg").default} />
      </div>
      <div className="label_container">изображение отсутствует</div>
    </div>
  );
};

export default NoCarImage;
