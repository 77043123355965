import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import { RegionsContext } from "app/context/Regions";

/**
 * Get keywords for the page
 * @param region Region's name
 */
const getKeywords = region =>
  `автомобиль, авто, машина, транспортное средство, легковой, коммерческий, малотоннажный, купить, покупка, продажа, обмен, трейд-ин, выбор, подбор, поиск, цена, стоимость, условия, кредит, лизинг, рассрочка, страховка, официальный дилер, автосалон, автоцентр, наличие, склад, ПТС, скидки, акции, база объявлений, каталог моделей и комплектаций, сравнение по параметрам, Россия${
    region ? `/${region}` : ""
  }`;

const Seo = ({ description, title, isNoIndex = false, canonical }) => {
  const { regions, selectedRegions } = useContext(RegionsContext);
  const selectedRegion =
    selectedRegions.length === 1
      ? regions.find(region => selectedRegions[0] === region.id)
      : null;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={getKeywords(selectedRegion ? selectedRegion.name : "")}
      />
      {isNoIndex && <meta name="robots" content="noindex, nofollow" />}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default Seo;
