import { useEffect, useRef } from "react";

export const useUpdateEffect = (effect, deps) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
  }, deps);
};
