import ComparePage, { CompareSSRLoad } from "pages/compare";
import ContactsPage from "pages/contacts";
import DealersPage, { DealersSSRLoad } from "pages/dealers";
import ForDealersPage, {ForDealersPageSSRLoad} from "pages/for-dealers";
import LegalPage from "pages/legal";
import MainPage, { MainPageSSRLoad } from "pages/main";
import MapDetailPage from "pages/map-detail";
import OfferDetailPage, { OfferDetailSSRLoad } from "pages/offer-detail";
import OffersListPage, { OffersListSSRLoad } from "pages/offers-list";
import PrivacyPage from "pages/privacy";
import NotFoundPage from "pages/not-found";

import { isWidgetMode, widgetConfig } from "utils/frameService";

// !!! The order of routes is important
// Be carefull while editing routes
const routes = [
  {
    component: ContactsPage,
    path: "/page/contacts",
  },
  {
    component: ForDealersPage,
    path: "/page/for_dealers",
    componentSSRLoad: ForDealersPageSSRLoad,
  },
  {
    component: LegalPage,
    path: "/page/legal",
  },
  {
    component: PrivacyPage,
    path: "/page/privacy_policy",
  },
  // Dealers page
  {
    path: "/:regionPath?/cars/new/:brand/:model/:offerId/:mode",
    component: DealersPage,
    componentSSRLoad: DealersSSRLoad,
  },
  // Offer page
  {
    path: "/:regionPath?/cars/new/:brand/:model/:offerId",
    component: OfferDetailPage,
    componentSSRLoad: OfferDetailSSRLoad,
  },
  {
    path: "/:regionPath?/compare",
    component: ComparePage,
    componentSSRLoad: CompareSSRLoad,
  },
  /**
   * /moskva/cars/new/
   * /moskva/cars/new/audi/
   * /moskva/cars/new/audi/a3-sedan/
   * /moskva/cars/new/audi/a3-sedan/RXF1aXBtZW50OjEzNDk0OQ/
   * /moskva/cars/new/audi/a3-sedan/RXF1aXBtZW50OjEzNDk0OQ/offers/
   * /moskva/cars/new/audi/a3-sedan/RXF1aXBtZW50OjEzNDk0OQ/offers/?map=1
   * /cars/new/
   * /cars/new/audi/
   * /cars/new/audi/a3-sedan/
   * /cars/new/audi/a3-sedan/RXF1aXBtZW50OjEzNDk0OQ/
   * /cars/new/audi/a3-sedan/RXF1aXBtZW50OjEzNDk0OQ/offers/
   * /cars/new/audi/a3-sedan/RXF1aXBtZW50OjEzNDk0OQ/offers/?map=1
   */
  // Search results (offers-list)
  {
    key: "OffersListPage",
    path: "/:regionPath?/cars/new/:brand/:model?",
    component: OffersListPage,
    componentSSRLoad: OffersListSSRLoad,
  },
  {
    key: "OffersListPage",
    path: "/:regionPath?/cars/new",
    component: OffersListPage,
    componentSSRLoad: OffersListSSRLoad,
  },
  {
    key: !isWidgetMode ? "MainPage" : "OffersListPage",
    path: "/",
    component: !isWidgetMode ? MainPage : OffersListPage,
    componentSSRLoad: !isWidgetMode ? MainPageSSRLoad : OffersListSSRLoad,
    exact: true,
  },
  {
    key: "NotFoundPage",
    component: NotFoundPage,
    componentSSRLoad: NotFoundPage,
  },
];

export default routes;
