import React from "react";
import classNames from "classnames";

import IconButton from "components/ui/IconButton";
import { isWidgetMode, onScrollTo } from "utils/frameService";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

import "./styles.less";
import { declOfNum } from "utils";

class CompareHeader extends React.Component {
  isSSRRender = true;

  componentDidMount() {
    this.isSSRRender = false;
  }

  render() {
    const {
      isMobile,
      carsCount,
      removeAll,
      changeCarsListState,
      isAllParameters,
      changeMode,
      mobileSelectedCarsId,
    } = this.props;
    const disableDifferentParameters =
      carsCount < 2 ||
      (isMobile && mobileSelectedCarsId[0] === mobileSelectedCarsId[1]);
    return (
      <div className="compare-header">
        <div className="data_container">
          {!isMobile && (
            <span className="cars-count">
              {getLocalTextWithNumbers(
                "Сравнение ${number} ${changeableText}",
                [
                  {
                    id: "number",
                    value: carsCount,
                  },
                  {
                    id: "changeableText",
                    value: declOfNum(carsCount, [
                      "модели",
                      "моделей",
                      "моделей",
                    ]),
                  },
                ],
              )}
            </span>
          )}
          {!isMobile && (
            <span
              className="delete-list"
              onClick={() => {
                if (isWidgetMode) {
                  onScrollTo(0);
                }
                removeAll();
              }}
            >
              <IconButton icon="icon-trash-can" />
              {getLocalText("Удалить список")}
            </span>
          )}
          <div className="compare-mode">
            <span
              className={classNames(
                "all-parameters",
                !isAllParameters && "not-selected",
              )}
              onClick={() => {
                changeMode(true);
              }}
            >
              {getLocalText("Все параметры")}
            </span>
            <span
              className={classNames(
                "different-parameters",
                isAllParameters && "not-selected",
                disableDifferentParameters && "disabled",
              )}
              onClick={() => {
                if (!disableDifferentParameters) {
                  changeMode(false);
                }
              }}
            >
              {getLocalText("Различающиеся")}
            </span>
          </div>
          {(isMobile || this.isSSRRender) && (
            <IconButton icon="icon-pencil-edit" onClick={changeCarsListState} />
          )}
        </div>
      </div>
    );
  }
}

export default CompareHeader;
