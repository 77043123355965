import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';

import App from 'app';
import { getPopularAndSelectedRegions } from 'context/Regions';
import smoothscroll from 'smoothscroll-polyfill';
import { getContextFromCookie } from 'utils/cookieService';
import {
  createFrameHandshake,
  isWidgetMode,
  parentCookies,
  createResizeListener,
  widgetConfigPromise,
  widgetConfig,
  parentUrl
} from 'utils/frameService';
import { getFilterRequestParamsFromLocation } from 'utils';
import {onHeightChange} from "app/utils/frameService";

// kick off the polyfill!
smoothscroll.polyfill();

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: isWidgetMode ? 'GTM-NBK8285' : 'GTM-N56TDZ3'
  };
  if (isWidgetMode) {
    tagManagerArgs.dataLayerName = 'ilsaLayer';
  }
  TagManager.initialize(tagManagerArgs);
}

const renderPage = () => {
  const hasError = window['__PRELOADED_STATE__']
    ? window['__PRELOADED_STATE__'].hasErrors
    : false;
  const errorText = window['__PRELOADED_STATE__']
    ? window['__PRELOADED_STATE__'].errorText
    : null;
  const context = getContextFromCookie(window['__PRELOADED_STATE__'] ? window['__PRELOADED_STATE__'].ilsa_data || window['__PRELOADED_STATE__'].context : {});
  delete window['__PRELOADED_STATE__'];

  ReactDOM.hydrate(
      <BrowserRouter>
        <App hasError={hasError} errorText={errorText} context={context} />
      </BrowserRouter>
    document.getElementById('app')
  );
};

const renderPageWithoutSSR = async () => {
  const context = getContextFromCookie(
    JSON.parse((parentCookies['ilsa_data'] || parentCookies['context']) ? parentCookies['ilsa_data'] || parentCookies['context'] : '{}')
  );

  const regionsFromCookies = context.regions || [];
  if (
    regionsFromCookies &&
    regionsFromCookies.selectedRegions &&
    !Array.isArray(regionsFromCookies.selectedRegions)
  ) {
    regionsFromCookies.selectedRegions = [];
  }
  const { selectedRegions = [], radius = null } = regionsFromCookies;
  const location = {
    pathname: window.location.pathname,
    search: window.location.search
  };
  const offersParams = getFilterRequestParamsFromLocation(
    location,
    [],
    selectedRegions,
    radius
  );

  if (widgetConfigPromise) {
    try {
      await widgetConfigPromise;
    } catch (e) {
      Sentry.captureException(e);
      ReactDOM.render(
        <div style={{ textAlign: 'center', fontSize: '25px' }}>{e}</div>,
        document.getElementById('app')
      );
      onHeightChange(100);
      return;
    }
  }

  if (process.env.NODE_ENV === 'production') {
    if (widgetConfig && widgetConfig.gtm) {
      const clientTagManager = {
        gtmId: widgetConfig.gtm.id
      };

      TagManager.initialize(clientTagManager);
    }
  }

  getPopularAndSelectedRegions(
    offersParams.geoPath,
    offersParams.geo ? offersParams.geo.radius : null,
    selectedRegions
  ).then((regions) => {
    regions.selectedRegions = regions.selectedRegions || selectedRegions;
    regions.radius = regions.radius || radius;
    regions = {
      ...regionsFromCookies,
      ...regions,
    };
    ReactDOM.render(
        <BrowserRouter>
          <App hasError={false} context={{
            ...context,
            regions
          }} />
        </BrowserRouter>
      document.getElementById('app')
    );
    createResizeListener();
  });
};

if (isWidgetMode) {
  createFrameHandshake().then(() => {
    Sentry.init({
      dsn:
        process.env.NODE_ENV === 'production'
          ? 'https://c9a614dc448a4834af98cefd01fd6030@sentry.io/1864995'
          : '',
      environment: parentUrl
        .replace(/(^\w+:|^)\/\//g, '')
        .replace(/\/#(?!.*\/#)/g, '')
        .replace(/[/]/g, '\\')
    });
    renderPageWithoutSSR();
  });
} else {
  Sentry.init({
    dsn:
      process.env.NODE_ENV === 'production'
        ? 'https://c9a614dc448a4834af98cefd01fd6030@sentry.io/1864995'
        : '',
    environment: window.location.host
  });
  renderPage();
}
