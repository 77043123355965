import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { formatNumber, reformatNumber } from "utils";

const FilterRange = ({
  from = "",
  to = "",
  filterId,
  format = false,
  onSetNumbersEdge,
  min,
  max,
  precision,
}) => {
  const [fromState, setFromState] = useState(from);
  const [toState, setToState] = useState(to);

  useEffect(() => {
    if (from !== fromState) {
      setFromState(from);
    }
    if (to !== toState) {
      setToState(to);
    }
  }, [from, to]);

  const updateData = useCallback(
    debounce((event, fromValue, toValue) => {
      onSetNumbersEdge(
        filterId,
        format ? reformatNumber(fromValue) : fromValue,
        format ? reformatNumber(toValue) : toValue,
        event.target,
      );
    }, 1200),
    [onSetNumbersEdge],
  );

  const onInput = (event, isFrom) => {
    updateData.cancel();
    event.persist();
    let { value } = event.currentTarget;
    const regexp = precision > 0 ? /^[\d., ]+/g : /^[\d ]+/g;
    const match = value.match(regexp);
    if (match && match.length > 0) {
      value = match[0];
      value = value.replace(/(^[^.,]*[.,])|[.,]+/g, "$1");
    }
    if (isFrom) {
      setFromState(value);
      updateData(event, value, toState);
    } else {
      setToState(value);
      updateData(event, fromState, value);
    }
  };

  return (
    <div className="c-filter-numbers-edge clearfix">
      <input
        className="s-from"
        type="text"
        onChange={event => onInput(event, true)}
        placeholder={format ? formatNumber(min) : min.toString()}
        value={format ? formatNumber(fromState) : fromState}
        min={min}
        max={max}
      />

      <input
        className="s-to"
        type="text"
        onChange={event => onInput(event, false)}
        placeholder={format ? formatNumber(max) : max.toString()}
        value={format ? formatNumber(toState) : toState}
        min={min}
        max={max}
      />
    </div>
  );
};

export default FilterRange;
