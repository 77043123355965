import React from "react";

import { getPopularAndSelectedRegions } from "app/context/Regions";

import Header from "components/Header";
import Footer from "components/Footer";
import Seo from "components/Seo";

const TITLE = "Политика конфиденциальности";
const DESCRIPTION = "";

const PrivacyPage = () => (
  <div className="skeleton c-static-page">
    <Seo description={DESCRIPTION} title={TITLE} />
    <Header />
    <div id="main" className="content">
      <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>

      <p className="content--row">г. Москва 23 апреля 2017&nbsp;г.</p>

      <p className="content--row">
        Настоящая Политика конфиденциальности данных (далее &ndash; Политика
        конфиденциальности)
      </p>

      <p className="content--row">
        1) действует в отношении всей информации, которую сайт&nbsp;ilsa.ru,
        расположенный на доменном имени&nbsp;ilsa.ru&nbsp;и его поддоменах
        (далее &ndash; &laquo;Сайт&raquo;), может получить о Пользователе во
        время использования сайта, программ и продуктов сайта;
      </p>

      <p className="content--row">
        2) направлена на защиту персональных данных, передаваемых Пользователем
        в форме заявки дилеру на обратный звонок.
      </p>

      <h2>ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h2>

      <p className="content--row">
        1.1 В настоящей Политике конфиденциальности используются следующие
        термины:
      </p>

      <p className="content--row">
        1.1.1. &laquo;<strong>Администрация сайта ilsa.ru</strong>&raquo; (далее
        &ndash; &laquo;Администрация Сайта&raquo;) &ndash; уполномоченные
        сотрудники на управления сайтом, действующие от имени Общества с
        ограниченной ответственностью &laquo;Илса&raquo; (ОГРН 1127746565638,
        ИНН 7715929153, адрес 129085, г. Москва, ул. Годовикова, д.9, стр.12),
        которые организуют и (или) осуществляет обработку данных, а также
        определяет цели обработки данных, состав данных, подлежащих обработке,
        действия (операции), совершаемые с данными.
      </p>

      <p className="content--row">
        1.1.2. &laquo;<strong>Обработка данных</strong>&raquo; - любое действие
        (операция) или совокупность действий (операций), совершаемых с
        использованием средств автоматизации или без использования таких средств
        с данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение данных.
      </p>

      <p className="content--row">
        1.1.3. &laquo;<strong>Пользователь сайта ilsa.ru</strong>&raquo; (далее
        &ndash; &laquo;Пользователь&raquo;) &ndash; лицо, имеющее доступ к
        Сайту, посредством сети Интернет и использующее Сайт.
      </p>

      <p className="content--row">
        1.1.4. &laquo;<strong>Рекламодатель</strong>&raquo; - лицо, разместившее
        рекламу на Сайте.
      </p>

      <p className="content--row">
        1.1.5. &laquo;<strong>Cookies</strong>&raquo; &mdash; небольшой фрагмент
        данных, отправленный веб-сервером и хранимый на компьютере пользователя,
        который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
        HTTP-запросе при попытке открыть страницу соответствующего сайта.
      </p>

      <p className="content--row">
        1.1.6. &laquo;<strong>IP-адрес</strong>&raquo; &mdash; уникальный
        сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
      </p>

      <p className="content--row">
        1.1.7. &laquo;<strong>Персональные данные</strong>&raquo; - любая
        информация, относящаяся к прямо определенному или косвенно определяемому
        конкретному физическому лицу (субъекту персональных данных).
      </p>

      <p className="content--row">
        <strong>Примеры персональных данных</strong> : фамилия, имя, отчество,
        адрес места жительства или регистрации субъекта персональных данных,
        номер основного документа, удостоверяющего его личность (паспорт,
        свидетельство о рождении), сведения о дате выдачи указанного документа и
        выдавшем его органе.
      </p>

      <p className="content--row">
        1.1.8. &laquo;<strong>Обезличивание персональных данных</strong>&raquo;
        - действия, в результате которых становится невозможным без
        использования дополнительной информации определить принадлежность
        персональных данных конкретному субъекту персональных данных.
      </p>

      <p className="content--row">
        <strong>Примеры обезличенных персональных данных</strong> , по которым
        ни прямо, ни косвенно нельзя однозначно определить конкретного субъекта
        персональных данных: имя, номер телефона, адрес электронной почты.
      </p>

      <p className="content--row">
        1.1.9. &laquo;<strong>Конфиденциальность данных</strong>&raquo; -
        обязательное для соблюдения Администрацией Сайта или иным получившим
        доступ к персональным данным лицом требование не допускать их
        распространения без согласия субъекта персональных данных или наличия
        иного законного основания.
      </p>

      <p className="content--row">
        1.1.10. &laquo;<strong>Заявка дилеру на обратный звонок</strong>&raquo;
        (далее &ndash; &laquo;Заявка&raquo;) - заявка установленной на Сайте
        формы, появляющаяся при нажатии кнопок &laquo;Хочу!&raquo;, &laquo;Хочу
        дешевле!&raquo; посредством которой Пользователь или его представитель
        просит дилера связаться с лицом, указанным в Заявке, для получения
        консультации.
      </p>

      <h2>ОБЩИЕ ПОЛОЖЕНИЯ</h2>

      <p className="content--row">
        2.1. Использование Сайта Пользователем означает согласие с настоящей
        Политикой конфиденциальности и условиями обработки персональных данных
        Пользователя.
      </p>

      <p className="content--row">
        2.2. В случае несогласия с условиями Политики конфиденциальности
        Пользователь должен прекратить использование Сайта.
      </p>

      <p className="content--row">
        2.3.Настоящая Политика конфиденциальности применяется только к Сайту.
        Администрация Сайта не контролирует и не несет ответственность за сайты
        третьих лиц, на которые Пользователь может перейти по ссылкам, доступным
        на Сайте.
      </p>

      <p className="content--row">
        2.4. Администрация Сайта не проверяет достоверность персональных данных,
        предоставляемых Пользователем Сайта или его представителями.
      </p>

      <p className="content--row">
        2.5. Администрация Сайта принимает обезличенные персональные данные
        только в форме Заявки.
      </p>

      <h2>ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h2>

      <p className="content--row">
        3.1. Настоящая Политика конфиденциальности устанавливает обязательства
        Администрации Сайта по неразглашению и обеспечению режима защиты
        конфиденциальности обезличенных персональных данных, которые
        Пользователь предоставляет по запросу Администрации сайта при оформлении
        заявки дилеру на обратный звонок.
      </p>

      <p className="content--row">
        3.2. Персональные данные, разрешённые к обработке в рамках настоящей
        Политики конфиденциальности, предоставляются Пользователем или его
        представителем путём заполнения Заявки (заявки дилеру на обратный
        звонок&nbsp;при нажатии кнопок &laquo;Хочу!&raquo;, &laquo;Хочу
        дешевле!&raquo;) и включают в себя следующую информацию:
      </p>

      <p className="content--row">
        3.2.1. имя, ник, псевдоним (как дилер может обратиться к лицу, кто
        заказал обратный звонок);
      </p>

      <p className="content--row">
        3.2.2. контактный городской, мобильный или&nbsp;IP-телефон (по которому
        дилер может позвонить и спросить лицо, имя которого указано в Заявке);
      </p>

      <p className="content--row">
        3.2.3. адрес электронной почты (e-mail) Пользователя или его
        представителя;
      </p>

      <p className="content--row">
        В рамках настоящей Политики конфиденциальности следует понимать, что в
        силу отсутствия проверки достоверности персональных данных, имя,
        контактный телефон и адрес электронной почты могут принадлежать разным
        лицам, что лишний раз подчеркивает обезличенность персональных данных,
        передаваемых в Заявке.
      </p>

      <p className="content--row">
        3.3. Сайт использует&nbsp;SSL-протокол шифрования данных и защищает
        данные, которые автоматически передаются в процессе использования сайта
        и не являются персональными данными:
      </p>

      <div className="content--row">
        <ul>
          <li>IP адрес;</li>
          <li>информация из cookies;</li>
          <li>
            информация о браузерах (или иной программах, которые осуществляют
            доступ к Сайту);
          </li>
          <li>время доступа;</li>
          <li>адреса страниц, которые посещал пользователь;</li>
          <li>
            реферер (адрес предыдущей страницы, с которой осуществлен переход на
            сайт);
          </li>
          <li>
            другая информация, автоматически собираемая сервером сайта
            (используемые операционные системы, запросы, статусы ответов,
            размеры передаваемых данных, юзер-агенты, протоколы и т.д.).
          </li>
        </ul>
      </div>

      <p className="content--row">
        Эти данным полностью или частично могут быть получены при посещении
        сайта не только Пользователями (лицами), но и при посещении Сайта
        различными роботами с целью индексирования или извлечения данных, а
        также другими серверами и компьютерами, посылающими
        Сайту&nbsp;http,&nbsp;SQL&nbsp;и другие типы запросов в автоматическом
        или ручном режиме.
      </p>

      <p className="content--row">
        3.3.1. Отключение cookies может повлечь невозможность доступа к частям
        Сайта.
      </p>

      <p className="content--row">
        3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих
        Посетителей. Данная информация используется с целью выявления и решения
        технических проблем, для контроля законности проводимых операций и
        действий на сайте.
      </p>

      <p className="content--row">
        3.4. Любая информация из пп. 3.2. и 3.3. подлежит временному надежному
        хранению и нераспространению, за исключением случаев, предусмотренных в
        п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
      </p>

      <p className="content--row">
        3.5. Информация из пп. 3.2. и 3.3. может быть уничтожена Администрацией
        Сайта в любой момент.
      </p>

      <h2>ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h2>

      <p className="content--row">
        4.1. Персональные данные Пользователя Администрация Сайта может
        использовать в целях:
      </p>

      <p className="content--row">
        4.1.1. предоставления Пользователю эффективной клиентской и технической
        поддержки при возникновении проблем связанных с использованием Сайта.
      </p>

      <p className="content--row">
        4.1.2. установления с Пользователем или его представителем обратной
        связи со стороны Администрации сайта или Рекламодателя, включая
        направление уведомлений, запросов, касающихся использования Сайта,
        оказания услуг, обработки запросов и заявок от Пользователя.
      </p>

      <p className="content--row">
        4.1.3. обеспечения безопасности, предотвращения мошенничества.
      </p>

      <h2>СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h2>

      <p className="content--row">
        5.1. Обработка персональных данных Пользователя осуществляется без
        ограничения срока, любым законным способом, в том числе в информационных
        системах персональных данных с использованием средств автоматизации или
        без использования таких средств.
      </p>

      <p className="content--row">
        5.2. Пользователь соглашается с тем, что Администрация сайта вправе
        передавать персональные данные третьим лицам (в частности,
        Рекламодателям), исключительно в целях выполнения Заявки, оформленной на
        Сайте.
      </p>

      <p className="content--row">
        5.3. Персональные данные Пользователя могут быть переданы уполномоченным
        органам государственной власти Российской Федерации только по основаниям
        и в порядке, установленным законодательством Российской Федерации.
      </p>

      <p className="content--row">
        5.4. Субъект персональных данных имеет право на получение информации,
        касающейся Обработки его персональных данных.
      </p>

      <p className="content--row">
        5.5. Администрация сайта принимает необходимые организационные и
        технические меры для защиты персональных данных Пользователя от
        неправомерного или случайного доступа, уничтожения, изменения,
        блокирования, копирования, распространения, а также от иных
        неправомерных действий третьих лиц.
      </p>

      <p className="content--row">
        5.6. Администрация сайта совместно с Пользователем принимает все
        необходимые меры по предотвращению убытков или иных отрицательных
        последствий, вызванных утратой или разглашением персональных данных
        Пользователя.
      </p>

      <h2>ОБЯЗАТЕЛЬСТВА СТОРОН</h2>

      <p className="content--row">
        <strong>6.1. Пользователь обязан:</strong>
      </p>

      <p className="content--row">
        6.1.1. Предоставить информацию об обезличенных персональных данных,
        необходимую для пользования Сайтом и отправки Заявки.
      </p>

      <p className="content--row">
        <strong>6.2. Администрация сайта обязана:</strong>
      </p>

      <p className="content--row">
        6.2.1. Использовать полученную информацию исключительно для целей,
        указанных в п. 4 настоящей Политики конфиденциальности.
      </p>

      <p className="content--row">
        6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
        разглашать без предварительного разрешения Пользователя, а также не
        осуществлять продажу, обмен, опубликование, либо разглашение иными
        возможными способами переданных персональных данных Пользователя, за
        исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
      </p>

      <p className="content--row">
        6.2.3. Принимать меры предосторожности для защиты конфиденциальности
        персональных данных Пользователя согласно порядку, обычно используемого
        для защиты такого рода информации в существующем деловом обороте.
      </p>

      <p className="content--row">
        6.2.4. Осуществить блокирование персональных данных, относящихся к
        соответствующему Пользователю, с момента обращения или запроса
        Пользователя или его законного представителя либо уполномоченного органа
        по защите прав субъектов персональных данных на период проверки, в
        случае выявления недостоверных персональных данных или неправомерных
        действий.
      </p>

      <h2>ОТВЕТСТВЕННОСТЬ СТОРОН</h2>

      <p className="content--row">
        7.1. Администрация Сайта, не исполнившая свои обязательства, несёт
        ответственность в связи с неправомерным использованием персональных
        данных, в соответствии с законодательством Российской Федерации, за
        исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей
        Политики Конфиденциальности.
      </p>

      <p className="content--row">
        7.2. В случае разглашения Конфиденциальной информации Администрация
        сайта не несёт ответственность, если данная конфиденциальная информация:
      </p>

      <p className="content--row">
        7.2.1. Стала публичным достоянием до её утраты или разглашения.
      </p>

      <p className="content--row">
        7.2.2. Была получена от третьей стороны до момента её получения
        Администрацией сайта.
      </p>

      <p className="content--row">
        7.2.3. Была разглашена с согласия Пользователя.
      </p>

      <h2>РАЗРЕШЕНИЕ СПОРОВ</h2>

      <p className="content--row">
        8.1. До обращения в суд с иском по спорам, возникающим из отношений
        между Пользователем Сайта и Администрацией Сайта, обязательным является
        предъявление претензии (письменного предложения о добровольном
        урегулировании спора).
      </p>

      <p className="content--row">
        8.2 .Получатель претензии в течение 30 календарных дней со дня получения
        претензии, письменно уведомляет заявителя претензии о результатах
        рассмотрения претензии.
      </p>

      <p className="content--row">
        8.3. При не достижении соглашения спор будет передан на рассмотрение в
        судебный орган г.Москвы в соответствии с действующим законодательством
        Российской Федерации.
      </p>

      <p className="content--row">
        8.4. К настоящей Политике конфиденциальности и отношениям между
        Пользователем и Администрацией сайта применяется действующее
        законодательство Российской Федерации.
      </p>

      <h2>ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>

      <p className="content--row">
        9.1. Администрация сайта вправе вносить изменения в настоящую Политику
        конфиденциальности без согласия Пользователя.
      </p>

      <p className="content--row">
        9.2. Новая Политика конфиденциальности вступает в силу с момента ее
        размещения на Сайте, если иное не предусмотрено новой редакцией Политики
        конфиденциальности.
      </p>

      <p className="content--row">
        9.3. Все предложения или вопросы по&nbsp;настоящей Политике
        конфиденциальности следует сообщать на&nbsp;адрес info@ilsa.ru.
      </p>

      <p className="content--row">
        9.4. Действующая Политика конфиденциальности размещена на странице по
        адресу{" "}
        <a href="https://www.ilsa.ru/page/privacy_policy">
          {" "}
          {"https://www.ilsa.ru/page/privacy_policy"}{" "}
        </a>
      </p>

      <p className="content--row">Обновлено&nbsp;23&nbsp;апреля 2017 г.</p>
    </div>
    <Footer />
  </div>
);

PrivacyPage.fetchData = dataContext => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(null, null, selectedRegions).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };

      resolve({
        isDataContext: true,
        regions,
      });
    });
  });
};

export default PrivacyPage;
