import classNames from "classnames";
import omit from "lodash/omit";
import React, { useContext, useState, useRef, useEffect } from "react";

import { DEFAULT_REGION_RADIUS, REQUEST_STATUS } from "app/constants";

import { RegionsContext } from "app/context/Regions";

import Pill from "components/Header/Region/Pill";
import Radius from "components/Header/Region/Radius";
import TextButton from "components/ui/TextButton";

import { generateUrlWithRegionAndRadius } from "utils";
import { getText } from "utils/i18nService";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";
import { useOutsideClick } from "app/utils/outsideClickHook";

import "./styles.less";
import { getRegionFromLatLng } from "app/graphql/services/client";
import { getLocalText } from "app/utils/i18nService";

const notAllRegionsEnabled =
  widgetConfig &&
  widgetConfig.regionFilter &&
  !widgetConfig.regionFilter.isAllEnable;
const Region = props => {
  const ref = useRef();
  const {
    regions = [],
    radius: radiusFromContext = DEFAULT_REGION_RADIUS,
    selectedRegions: selectedRegionsFromContext = [],
    detectRegion,
    searchResults = [],
    status,
    functions: regionsContextFunctions,
  } = useContext(RegionsContext) || {};

  const [state, setState] =
    useState({
      query: "",
      showRegionsList: false,
      showPopup: false,
      showConfirmPopup: false,
      selectedRegions: selectedRegionsFromContext.map(item => item),
      radius: radiusFromContext,
    }) || {};

  useEffect(() => {
    if (
      detectRegion &&
      selectedRegions.length === 0 &&
      (!isWidgetMode ||
        (isWidgetMode && widgetConfig && widgetConfig.detectRegion))
    ) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          result => {
            getRegionFromLatLng(result.coords.latitude, result.coords.longitude)
              .then(({ city, region }) => {
                regionsContextFunctions
                  .getRegionByName(city, region)
                  .then(result => {
                    if (result) {
                      onRegionSelect(result.node.id);
                      regionsContextFunctions.changeState({
                        regions: [result.node],
                        radius: 200,
                        selectedRegions: [result.node.id],
                      });
                      setState({ ...state, showConfirmPopup: true });
                    }
                  });
              })
              .catch(() => {});
          },
          () => {},
        );
      }
    }
  }, []);

  useOutsideClick(ref, () => {
    if (state.showPopup) {
      setState({
        ...state,
        showPopup: false,
        query: "",
        showRegionsList: false,
        selectedRegions: selectedRegionsFromContext.map(item => item),
      });
    }
  });

  const onQueryInputChange = event => {
    setState({
      ...state,
      query: event.target.value,
    });
    regionsContextFunctions.searchRegions(event.target.value);
  };

  const onCleanQueryButtonClick = event => {
    setState({
      ...state,
      query: "",
    });
  };

  const onSearchResultItemClick = event => {
    const { selectedRegions } = state;
    const { regionId } = event.currentTarget.dataset;

    if (selectedRegions.some(item => item === regionId)) {
      return;
    }

    regionsContextFunctions.changeState({
      regions: [
        ...regions,
        ...searchResults.filter(
          region =>
            region.id === regionId && !regions.find(r => r.id === regionId),
        ),
      ],
    });

    selectedRegions.push(regionId);
    setState({
      ...state,
      query: "",
      selectedRegions: selectedRegions,
    });
  };

  const onRegionSelect = regionId => {
    const { selectedRegions } = state;

    selectedRegions.push(regionId);
    setState({
      ...state,
      selectedRegions: selectedRegions,
    });
  };

  const onRegionUnselect = regionId => {
    let { selectedRegions } = state;

    selectedRegions = selectedRegions.filter(item => item !== regionId);
    setState({
      ...state,
      selectedRegions: selectedRegions,
    });
  };

  const onSaveButtonClick = () => {
    const { selectedRegions, radius } = state;
    const { history, location } = props;
    setState({
      ...state,
      showPopup: false,
    });
    regionsContextFunctions.changeState({
      radius:
        selectedRegions.length === 1 &&
        regions.find(
          region => selectedRegions[0] === region.id && region.cutoff,
        )
          ? radius
          : undefined,
      selectedRegions,
    });

    history.replace(
      generateUrlWithRegionAndRadius(
        location,
        regions,
        selectedRegions,
        radius,
        true,
      ),
    );
  };

  const onShowButtonClick = () => {
    setState({
      ...state,
      showPopup: !state.showPopup,
      showConfirmPopup: false,
    });
  };

  const {
    showPopup,
    showConfirmPopup,
    query,
    showRegionsList,
    selectedRegions,
    radius,
  } = state;

  let isCutoffRegionSelected = false;
  const selectedRegionsNames = [];
  const savedSelectedRegionsNames = [];

  regions.forEach(region => {
    if (selectedRegions.some(item => item === region.id)) {
      selectedRegionsNames.push(region.name);

      if (!isCutoffRegionSelected && region.cutoff) {
        isCutoffRegionSelected = true;
      }
    }

    if (selectedRegionsFromContext.some(item => item === region.id)) {
      savedSelectedRegionsNames.push(region.name);
    }
  });

  const isRadiusSelectionAvailable =
    isCutoffRegionSelected && selectedRegionsNames.length === 1;

  return (
    <div className="region_container">
      <div ref={ref}>
        <div
          className="region_container--btn"
          onClick={onShowButtonClick}
          title="Выбрать регион"
        >
          <i className="icon icon-navigation-arrow" />

          <span
            className="region_container--text"
            title={savedSelectedRegionsNames.join(", ")}
          >
            {savedSelectedRegionsNames.length > 0
              ? savedSelectedRegionsNames.slice(0, 2).join(", ") +
                (savedSelectedRegionsNames.length > 2 ? ", ..." : "")
              : getLocalText(
                  getLocalText(getText("common.header.emptyRegion")),
                )}
          </span>
        </div>
        {showPopup && [
          <div className="region_container--arrow" />,
          <div className="region_container--popup">
            <div className="region_container--list">
              <div className="region">
                <div className="region__header">
                  <div className="region__search">
                    {notAllRegionsEnabled ? (
                      <>
                        {!showRegionsList && (
                          <span
                            className="label"
                            onClick={() => {
                              setState({
                                ...state,
                                showRegionsList: true,
                              });
                            }}
                          >
                            Выбрать регион
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="region__search-icon">
                          <i className="icon icon-search" />
                        </div>

                        <input
                          type="text"
                          name="query"
                          onChange={onQueryInputChange}
                          placeholder={getLocalText("Поиск региона")}
                          value={query}
                          autoComplete="off"
                        />

                        {query.length > 0 && (
                          <i
                            className="icon icon-clear"
                            onClick={onCleanQueryButtonClick}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="region__container">
                  {selectedRegionsNames.length === 0 && (
                    <Pill
                      key={getLocalText(
                        getLocalText(getText("common.header.emptyRegion")),
                      )}
                      active={true}
                      hideCloseBtn={true}
                    >
                      {getLocalText(
                        getLocalText(getText("common.header.emptyRegion")),
                      )}
                    </Pill>
                  )}
                  <div
                    className={classNames(
                      "selected-regions",
                      selectedRegionsNames.length === 0 && "popular_container",
                    )}
                  >
                    {selectedRegionsNames.length === 0 && (
                      <div className="label">{getLocalText("Популярные")}</div>
                    )}
                    <div className="regions">
                      {regions
                        .filter(region => {
                          return (
                            (selectedRegionsNames.length > 0 &&
                              selectedRegions.some(
                                item => item === region.id,
                              )) ||
                            (selectedRegionsNames.length === 0 &&
                              region.isFavourite)
                          );
                        })
                        .map(region => (
                          <Pill
                            key={region.id}
                            active={selectedRegions.some(
                              item => item === region.id,
                            )}
                            onActive={() => onRegionSelect(region.id)}
                            onDelete={() => onRegionUnselect(region.id)}
                          >
                            {region.name}
                          </Pill>
                        ))}
                    </div>
                    {isRadiusSelectionAvailable && (
                      <Radius
                        value={radius}
                        onChange={radius => {
                          setState({
                            ...state,
                            radius,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="region__footer">
                  <div className="region__controls">
                    <TextButton
                      text={getLocalText("Сохранить")}
                      color="default"
                      onClick={() => onSaveButtonClick()}
                    />
                  </div>
                </div>

                {(showRegionsList || query.length >= 2) &&
                  (status === REQUEST_STATUS.SUCCESS ||
                    (status === "LOADING" && searchResults.length > 0)) && (
                    <div
                      className={classNames(
                        "region__search-results",
                        showRegionsList && "region-filter",
                      )}
                    >
                      {searchResults.length > 0 ? (
                        <ul className="region__list">
                          {searchResults.map(region => (
                            <li
                              className={classNames("region__list-item", {
                                "region__list-item--selected": selectedRegions.some(
                                  item => item === region.id,
                                ),
                              })}
                              key={region.id}
                              data-region-id={region.id}
                              onClick={onSearchResultItemClick}
                            >
                              {region.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="region__zero-results">
                          По вашему запросу ничего не найдено :(
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>,
        ]}
        {showConfirmPopup && [
          <div className="region_container--arrow" />,
          <div className="region_container--popup">
            <div className="popup-content_container">
              <div className="label">Ваш регион определен верно?</div>
              <div className="buttons_container">
                <div
                  className="no label-btn"
                  onClick={() => {
                    onRegionUnselect(selectedRegions[0]);
                    regionsContextFunctions.changeState({
                      radius: null,
                      selectedRegions: [],
                    });
                    setState({ ...state, showConfirmPopup: false });
                  }}
                >
                  {getLocalText("Нет")}
                </div>
                <div
                  className="yes label-btn"
                  onClick={() => {
                    setState({ ...state, showConfirmPopup: false });
                  }}
                >
                  {getLocalText("Да")}
                </div>
              </div>
            </div>
          </div>,
        ]}
      </div>
    </div>
  );
};

export default Region;
