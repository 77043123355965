import React, { useState } from "react";
import Select from "react-select";

import { isWidgetMode, widgetConfig } from "app/utils/frameService";

import "./styles.less";

const PhoneLabel = ({ text, image }) => (
  <span className="s-phone-label">
    <span className="s-phone-label--text">{text}</span>
    <img src={image} />
  </span>
);

const PHONE_CODE_OPTIONS = [
  {
    value: "ru",
    labelText: "Россия (+7)",
  },
  {
    value: "by",
    labelText: "Беларусь (+375)",
  },
  {
    value: "kz",
    labelText: "Казахстан (+77)",
  },
  {
    value: "md",
    labelText: "Молдова (+373)",
  },
  {
    value: "uz",
    labelText: "Узбекистан (+998)",
  },
  {
    value: "ua",
    labelText: "Украина (+380)",
  },
  {
    value: "ot",
    labelText: "Прочие",
  },
];

const PhonePopup = ({ setPhonePattern }) => {
  let phoneCodesList =
    isWidgetMode && widgetConfig.phoneCodeOptions
      ? widgetConfig.phoneCodeOptions
      : PHONE_CODE_OPTIONS;
  phoneCodesList = phoneCodesList.map(item => ({
    ...item,
    label: (
      <PhoneLabel
        key={item.value}
        text={item.labelText}
        image={require(`flags/${item.value}.png`).default}
      />
    ),
  }));
  const [selectedItem, setSelectedItem] = useState(phoneCodesList[0]);

  const onChange = selectedItem => {
    setSelectedItem(selectedItem);
    setPhonePattern(selectedItem.value);
  };

  return (
    <div className="phone-popup">
      <Select
        classNamePrefix={"select"}
        value={selectedItem}
        options={phoneCodesList}
        onChange={onChange}
        isSearchable={false}
        isClearable={false}
        tabIndex={-1}
        // menuIsOpen={true}
      />
    </div>
  );
};

export default PhonePopup;
