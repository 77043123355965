import React from "react";

import ParametersCategory from "components/ComparePage/CompareList/ParametersCategoryList/ParametersCategory";
import IconButton from "components/ui/IconButton";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

class ParametersCategoryList extends React.Component {
  state = {
    openCategories: true,
  };

  changeCategoriesState = () => {
    this.setState({ openCategories: !this.state.openCategories });
  };

  render() {
    const { openCategories } = this.state;
    const { title, categories, pageOffset } = this.props;
    return (
      <div className="parameters-category-list_container">
        <div className="title_container" style={{ left: pageOffset }}>
          <div className="title" onClick={this.changeCategoriesState}>
            {getLocalText(title)}
          </div>
          <span className="expand-btn_container">
            <IconButton
              icon={openCategories ? "icon-down" : "icon-next"}
              onClick={this.changeCategoriesState}
            />
          </span>
        </div>
        {openCategories &&
          categories.map(category => {
            return (
              <ParametersCategory
                category={category}
                key={category.name}
                pageOffset={pageOffset}
              />
            );
          })}
      </div>
    );
  }
}

export default ParametersCategoryList;
