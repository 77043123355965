import React from "react";
import classNames from "classnames";

import { isWidgetMode, onScrollTo } from "utils/frameService";

import TitlesList from "components/ComparePage/CompareList/TitlesList";
import MobileTitlesList from "components/ComparePage/CompareList/MobileTitlesList";
import PricesList from "components/ComparePage/CompareList/PricesList";
import ParametersCategoryList from "components/ComparePage/CompareList/ParametersCategoryList";
import IconButton from "components/ui/IconButton";

import "./styles.less";
import {scrollWindow} from "app/utils/frameService";
import {getLocalText} from "app/utils/i18nService";

const CARD_WIDTH = 220;

class CompareList extends React.Component {
  state = {
    page: 0,
    maxPage: 0,
    showScrollToTopButton: false,
    isSSRRender: true,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.updateScroll);
    this.setState({ isSSRRender: false });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateScroll);
  }

  static getDerivedStateFromProps(props, state) {
    const maxPage =
      props.windowWidth !== 0
        ? props.windowWidth / 220 - props.carsList.length - 1
        : 0;
    return {
      maxPage: maxPage >= 0 ? 0 : maxPage * -1,
    };
  }

  lastScrollPosition = 0;
  updateScroll = () => {
    let st = window.pageYOffset;
    if (this.props.isMobile) {
      if (st < this.lastScrollPosition) {
        if (!this.state.showScrollToTopButton && st > 200) {
          this.setState({ showScrollToTopButton: true });
        } else if (this.state.showScrollToTopButton && st < 200) {
          this.setState({ showScrollToTopButton: false });
        }
      } else {
        if (this.state.showScrollToTopButton) {
          this.setState({ showScrollToTopButton: false });
        }
      }
    }
    this.lastScrollPosition = st;
  };

  onArrowBtnClick = increment => {
    const { page } = this.state;
    const newPage = page + increment;
    this.setState({ page: newPage < 0 ? 0 : newPage });
  };

  scrollToTop = () => {
    scrollWindow(0);
    if (isWidgetMode) {
      onScrollTo(0);
    }
  };

  render() {
    const { page, maxPage, showScrollToTopButton, isSSRRender } = this.state;
    const {
      isMobile,
      carsList,
      specificationsList,
      selectedRegionPath,
      removeCar,
      changeSelectedCar,
      selectedCarsId,
      search,
      differentSpecificationsListMobile,
    } = this.props;

    const pageOffset = !isMobile ? page * CARD_WIDTH - CARD_WIDTH : 0;

    return (
      <div className="compare-list_container">
        {!isMobile && (
          <div className="title-row_background_container">
            {page > 0 && (
              <div className="arrow-btn_container">
                <IconButton
                  className="arrow-btn left"
                  icon="icon-next"
                  onClick={() => {
                    this.onArrowBtnClick(-1);
                  }}
                />
              </div>
            )}
            <div className="title-row_background" />
            {page < maxPage && (
              <div className="arrow-btn_container">
                <IconButton
                  className="arrow-btn right"
                  icon="icon-next"
                  onClick={() => {
                    this.onArrowBtnClick(1);
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="compare-list" style={{ right: pageOffset }}>
          <div className="title-row">
            {!isMobile && (
              <div
                className={classNames(
                  "gradient",
                  "left",
                  isWidgetMode && "widget",
                )}
              />
            )}
            {!isMobile && (
              <TitlesList
                carsList={carsList}
                selectedRegionPath={selectedRegionPath}
                removeCar={removeCar}
                search={search}
              />
            )}
            {(isMobile || isSSRRender) && (
              <MobileTitlesList
                carsList={carsList}
                selectedRegionPath={selectedRegionPath}
                removeCar={removeCar}
                changeSelectedCar={changeSelectedCar}
                search={search}
              />
            )}
            {!isMobile && (
              <div
                className={classNames(
                  "gradient",
                  "right",
                  isWidgetMode && "widget",
                )}
              />
            )}
          </div>
          {showScrollToTopButton && (
            <span className="to-top-btn" onClick={this.scrollToTop}>
              {getLocalText("Вернуться наверх")}
            </span>
          )}
          <div className="data_container">
            <div className="price-row">
              <PricesList
                carsList={
                  isMobile
                    ? selectedCarsId.map(id =>
                        carsList.find(car => car.id === id),
                      )
                    : carsList
                }
                selectedRegionPath={selectedRegionPath}
                search={search}
              />
            </div>
            {specificationsList.map(specification => {
              return (
                <div
                  className={isSSRRender ? "row-from-ssr" : "row"}
                  key={specification.name}
                >
                  <ParametersCategoryList
                    title={specification.name}
                    categories={specification.features}
                    pageOffset={isMobile ? null : pageOffset}
                    isMobile={isMobile}
                  />
                </div>
              );
            })}
            {isSSRRender ? ( //hack for correct mobile SSR loading
              <div className="mobile-hack_container">
                {differentSpecificationsListMobile.map(specification => {
                  return (
                    <div className="row" key={specification.name}>
                      <ParametersCategoryList
                        title={specification.name}
                        categories={specification.features}
                        pageOffset={isMobile ? null : pageOffset}
                        isMobile={isMobile}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default CompareList;
