import React from "react";
import Slider from "react-slick";
import classNames from "classnames";

import CarImage from "components/ui/CarImage";
import Link from "components/ui/Link";

import { getOfferPageUrl } from "utils";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

class MobileTitlesList extends React.Component {
  state = {
    selectedCarsIndexes: this.props.carsList.length > 1 ? [0, 1] : [0],
  };

  render() {
    const { selectedCarsIndexes } = this.state;
    const {
      carsList,
      selectedRegionPath,
      changeSelectedCar,
      search,
    } = this.props;

    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const slidersList = carsList.length === 1 ? [0] : [0, 1];

    return (
      <div className="mobile_titles-list_container">
        {slidersList.map(sliderIndex => {
          return (
            <div
              className={classNames(
                "slider_container",
                slidersList.length === 1 && "single",
              )}
              key={sliderIndex}
            >
              <Slider
                {...settings}
                onSwipe={direction => {
                  let newIndex =
                    direction === "right"
                      ? selectedCarsIndexes[sliderIndex] - 1
                      : selectedCarsIndexes[sliderIndex] + 1;
                  if (newIndex === carsList.length) {
                    newIndex = 0;
                  }
                  if (newIndex < 0) {
                    newIndex = carsList.length - 1;
                  }
                  changeSelectedCar(sliderIndex, carsList[newIndex].id);
                  selectedCarsIndexes[sliderIndex] = newIndex;
                  this.setState({
                    selectedCarsIndexes: selectedCarsIndexes,
                  });
                }}
                initialSlide={sliderIndex}
              >
                {carsList.map((car) => {
                  return (
                    <div key={car.id}>
                      <div className="title_container" key={car.id}>
                        <Link
                          to={getOfferPageUrl(
                            car.id,
                            car.model.resourcePath,
                            selectedRegionPath,
                            search,
                          )}
                        >
                          <div className="image_container">
                            <CarImage className="image" src={car.photo} />
                          </div>
                          <div className="name_container">
                            <div className="name">{`${car.brand.name} ${getLocalText(car.model.name)} ${getLocalText(car.versionName)}`}</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <div className="slider-counter">
                {selectedCarsIndexes[sliderIndex] + 1} / {carsList.length}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default MobileTitlesList;
