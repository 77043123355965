import React from "react";

import { declOfNum } from "utils";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const NumberOfRemain = ({ selectedDealers }) => {
  let numberRemain;
  let numberOfRemain;
  if (selectedDealers.length > 2) {
    numberRemain = selectedDealers.length - 2;
    numberOfRemain = (
      <span>
        {getLocalTextWithNumbers("и еще ${numberRemain} ${changeableText}", [
          {
            id: "numberRemain",
            value: numberRemain,
          },
          {
            id: "changeableText",
            value: declOfNum(numberRemain, ["дилер", "дилера", "дилеров"]),
          },
        ])}
      </span>
    );
  }
  let dealersNames = selectedDealers
    .slice(0, 2)
    .map(dealer => {
      return dealer.name;
    })
    .join(", ");

  if (selectedDealers.length === 0) {
    return null;
  }

  return (
    <div className="с-number-of-remain">
      {getLocalText("Выбраны дилеры")}: {dealersNames} {numberOfRemain}
    </div>
  );
};

export default NumberOfRemain;
