import React, { useState } from "react";
import classNames from "classnames";

import TabContent from "components/OfferDetailPage/TabContent";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

const tabs = [
  {
    id: "short",
    label: "Краткий список",
  },
  {
    id: "full",
    label: "Полная расшифровка",
  },
];

const MobileTabsContent = props => {
  const { activeTab, changeTab, description, specifications, title } = props;

  return (
    <div className="mobile-tabs">
      {activeTab !== "technical" && (
        <div className="tabs-nav">
          {tabs.map(tab => {
            return (
              <div
                onClick={() => {
                  changeTab(tab.id);
                }}
                data-key={tab.id}
                className={classNames("tab", activeTab === tab.id && "active")}
              >
                <span>{getLocalText(tab.label)}</span>
              </div>
            );
          })}
        </div>
      )}
      <div className="tabs-data">
        <TabContent
          tab={activeTab}
          description={description}
          specifications={specifications}
          offerTitle={title}
        />
      </div>
    </div>
  );
};

export default MobileTabsContent;
