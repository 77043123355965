import React, { useRef, useState, useEffect, useContext } from "react";
import classNames from "classnames";
import moment from "moment";
import random from "lodash/random";

import { formatNumber } from "utils";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";
import graphqlClient from "app/graphql/services/client";
import {
  bookingCarRequest,
  refreshAvailabilityRequest,
} from "app/graphql/mutations";

import Tooltip from "components/ui/Tooltip";
import Price from "components/ui/Price";
import TextButton from "components/ui/TextButton";
import Color from "components/ui/Color";
import CarImage from "components/ui/CarImage";

import { DealersContext } from "app/context/Dealers";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const Car = ({ car, setPopupSettings }) => {
  const colorTextRef = useRef(null);
  const [carAvailability, setCarAvailability] = useState({
    time:
      isWidgetMode && widgetConfig.fakeCarRefresh
        ? moment().subtract(random(12, 24), "hours")
        : moment(car.checkTime),
    offers: car.offers,
    available: true,
  });
  const [showColorNameTooltip, setShowColorNameTooltip] = useState(false);
  const [bookingIsDisabled, setBookingIsDisabled] = useState(false);
  const [isRefreshingStock, setIsRefreshingStock] = useState(false);
  const { sameColorsList } = useContext(DealersContext);

  useEffect(() => {
    if (colorTextRef.current.scrollWidth > colorTextRef.current.offsetWidth) {
      setShowColorNameTooltip(true);
    }
  }, []);

  const refreshStock = token => {
    setIsRefreshingStock(true);
    if (isWidgetMode && widgetConfig.fakeCarRefresh) {
      setTimeout(() => {
        const isAvailable = random(0, 1) === 1;
        setCarAvailability({
          time: moment(),
          offers: isAvailable ? carAvailability.offers : [],
          available: isAvailable,
        });
        setIsRefreshingStock(false);
      }, 1000);
    } else {
      graphqlClient
        .fetch(refreshAvailabilityRequest, { token: token })
        .then(result => {
          const data = result.availability;
          setCarAvailability({
            time: moment(data.time ? data.time : carAvailability.time),
            offers: data.offers,
            available: data.offers.some(offer => offer.offerCount > 0),
          });
          setIsRefreshingStock(false);
        });
    }
  };

  const bookCar = () => {
    graphqlClient.fetch(bookingCarRequest, { id: car.id }).then(result => {
      if (
        result.booking &&
        result.booking.action &&
        result.booking.action.url
      ) {
        //Hack for iphones
        setTimeout(() => {
          window.open(result.booking.action.url);
        });
      } else if (!result.booking) {
        const isOnceCar = stockCount + inStockCount === 1;
        setPopupSettings({
          show: true,
          title: getLocalText(
            `При бронировании дилер сообщил, что именно ${
              isOnceCar ? "этот автомобиль снят" : "эти автомобили сняты"
            } с продажи`,
          ),
        });
        setBookingIsDisabled(true);
      }
    });
  };

  let stockCount = 0;
  let inStockCount = 0;
  carAvailability.offers.forEach(offer => {
    if (offer.availability === "IN_STOCK") {
      inStockCount = offer.offerCount;
    }
    stockCount += offer.offerCount;
  });
  const stockText =
    inStockCount !== 0
      ? stockCount !== inStockCount
        ? `${stockCount} ${getLocalText(
            "авто",
          )} (${inStockCount} ${getLocalText("в наличии")}*)`
        : `${inStockCount} ${getLocalText("авто")} ${getLocalText(
            "в наличии",
          )}*`
      : `${stockCount} ${getLocalText("авто")} ${getLocalText("в пути")}*`;
  const hasSameColor = sameColorsList.some(
    color => color.name === car.color.name,
  );
  return (
    <div className="car_container">
      {!carAvailability.available && (
        <div className="overlay">
          {getLocalText("Автомобили сняты с продажи")}
        </div>
      )}
      <div className="image_container">
        <CarImage className="image" src={car.picture} />
      </div>
      <div className="car-info_container">
        <div className="container car-info_block">
          <div className="color_container">
            <Color hex={car.color.hex} />
            <div
              className={classNames(
                "name",
                showColorNameTooltip && "with-tooltip",
              )}
              ref={colorTextRef}
            >
              {`${getLocalText(car.color.name)}${
                hasSameColor ? ` (${car.color.code})` : ""
              }`}
            </div>
            {showColorNameTooltip && (
              <>
                <Tooltip
                  content={`${getLocalText(car.color.name)}${
                    hasSameColor ? ` (${car.color.code})` : ""
                  }`}
                  distance={10}
                  touch={true}
                  interactive={true}
                >
                  <div className="tooltip">?</div>
                </Tooltip>
              </>
            )}
          </div>
          <div className="car-info">
            <span className="stock">{stockText}</span>
            <span className="separator">|</span>
            <span className="year">{`${car.year} ${getLocalText("год")}`}</span>
          </div>
          <div className="stock-update-date">
            <span className="text">{`*${getLocalText("Актуально на")}: ${
              carAvailability.time
                ? getLocalTextWithNumbers(
                    "${dayText}.${monthText} ${hoursText}:${minutesText}",
                    [
                      {
                        id: "dayText",
                        value: carAvailability.time.format("DD"),
                      },
                      {
                        id: "monthText",
                        value: carAvailability.time.format("MM"),
                      },
                      {
                        id: "yearText",
                        value: carAvailability.time.format("YY"),
                      },
                      {
                        id: "hoursText",
                        value: carAvailability.time.format("HH"),
                      },
                      {
                        id: "minutesText",
                        value: carAvailability.time.format("mm"),
                      },
                      {
                        id: "hoursUSAText",
                        value: carAvailability.time.format("hh"),
                      },
                      {
                        id: "amPmText",
                        value: carAvailability.time.format("a"),
                      },
                    ],
                  )
                : ""
            }`}</span>
            {(car.token || (isWidgetMode && widgetConfig.fakeCarRefresh)) && (
              <span
                className="icon_container update-availability_button"
                onClick={() => refreshStock(car.token)}
              >
                <i
                  className={classNames(
                    "icon icon-refresh",
                    isRefreshingStock && "loading",
                  )}
                />
              </span>
            )}
          </div>
        </div>
        <div className="container car-prices_block">
          <div
            className={classNames(
              "price",
              !car.marketingPrice.low && "without-discount",
            )}
          >
            {!isWidgetMode
              ? `${getLocalText("Ориентировочная цена")}:`
              : `${getLocalText("Цена")}:`}
            {(!isWidgetMode || !!car.extraEquipment) && (
              <Tooltip
                content={
                  !isWidgetMode
                    ? `• Текущая ситуация на рынке не позволяет, к сожалению, предоставить Вам прозрачную информацию по стоимости автомобилей. 
                    Мы по-прежнему показываем Вам цены, которые нам дали дилеры, но учитывайте, что в связи с дефицитом ходовых моделей 
                    реальная цена может быть больше за счет установленных "допов" и прочих подобных нюансов
                    ${
                      !!car.extraEquipment
                        ? `<br />• Установлено дополнительное оборудование дилера, подробную информацию и стоимость уточняйте в отделе продаж`
                        : ""
                    }`
                    : `Установлено дополнительное оборудование дилера, подробную информацию и стоимость уточняйте в отделе продаж`
                }
                distance={10}
                touch={true}
                interactive={true}
              >
                <div className="tooltip">?</div>
              </Tooltip>
            )}
            {isWidgetMode && widgetConfig.pricePrefix && (
              <span className="price-prefix">{widgetConfig.pricePrefix}</span>
            )}
            <Price value={car.price.low} />
            {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
              <>{` ${getLocalText("₽")}`}</>
            )}
          </div>
          <div
            className={classNames(
              "discount-price",
              (!isWidgetMode ||
                (widgetConfig && widgetConfig.enableBookingBtn)) &&
                car.booking &&
                "with-booking",
            )}
          >
            {car.marketingPrice.low &&
              car.marketingPrice.low + 1000 < car.price.low &&
              (!isWidgetMode ||
                (isWidgetMode && widgetConfig.showSalePrice)) && (
                <>
                  <span className="text">Мин. цена со скидками от:</span>
                  <span className="text mobile">
                    {!isWidgetMode ? "Минимальная цена от:" : "Мин. цена от:"}
                  </span>
                  <Tooltip
                    content={`• ${formatNumber(car.price.low)} – ${
                      !isWidgetMode
                        ? "ориентировочная цена"
                        : "цена по прайс-листу дилера"
                    }
                    <br /> • мин. от ${formatNumber(car.marketingPrice.low)} -
                    минимально возможная цена с учетом скидок, предоставляемых
                    дилером. Условия получения скидок уточняйте у дилера.
                  `}
                    distance={10}
                    touch={true}
                    interactive={true}
                  >
                    <div className="tooltip">?</div>
                  </Tooltip>
                  {isWidgetMode && widgetConfig.pricePrefix && (
                    <span className="price-prefix">
                      {widgetConfig.pricePrefix}
                    </span>
                  )}
                  <Price value={car.marketingPrice.low} />
                  {(!isWidgetMode ||
                    (isWidgetMode && !widgetConfig.pricePrefix)) && (
                    <>{` ${getLocalText("₽")}`}</>
                  )}
                </>
              )}
          </div>
          {!!car.extraEquipment && (
            <div className="extra-equipment">
              <span className="text">Установлено доп. оборудование</span>
            </div>
          )}
        </div>
      </div>
      {(!isWidgetMode || (widgetConfig && widgetConfig.enableBookingBtn)) &&
        car.booking && (
          <div className="container btn_container">
            <TextButton
              text={getLocalText("Забронировать")}
              className="booking-car_button"
              color="orange"
              disabled={bookingIsDisabled}
              onClick={bookCar}
            />
          </div>
        )}
    </div>
  );
};

export default Car;
