import React from "react";

import CleanAllButton from "components/shared/CleanAllButton";
import IconButton from "components/ui/IconButton";
import CarImage from "components/ui/CarImage";

import { scrollWindow } from "app/utils/frameService";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

class SelectedCarsList extends React.Component {
  state = {
    starterCarsList: this.props.carsList,
  };

  componentDidMount() {
    scrollWindow(0);
  }

  render() {
    const { starterCarsList } = this.state;
    const { carsList, removeCar, removeAll } = this.props;
    return (
      <div className="selected-cars-list">
        <CleanAllButton
          text={getLocalText("Удалить список")}
          itemsCount={carsList.length}
          onClick={() => {
            removeAll();
          }}
        />
        <div className="cars-list">
          {carsList.map(car => (
            <div className="car-card" key={car.id}>
              <IconButton
                icon="icon-close-bold"
                onClick={() => removeCar(car.id)}
              />
              <div className="counter">
                <span className="number-first">
                  {starterCarsList.findIndex(
                    starterCar => starterCar.id === car.id,
                  ) + 1}
                </span>
                /<span className="number-second">{starterCarsList.length}</span>
              </div>
              <div className="car-data">
                <div className="car-photo_container">
                  <CarImage className="car-photo" src={car.photo} />
                </div>
                <span className="car-name">{`${car.brand.name} ${getLocalText(
                  car.model.name,
                )} ${getLocalText(car.versionName)}`}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SelectedCarsList;
