import React from "react";
import Link from "components/ui/Link";
import Slider from "react-slick";

import { declOfNum, getRegionUrl, createPathBasedOnExpressions } from "utils";

import CarouselArrow from "components/shared/CarouselArrow";
import Price from "components/ui/Price";
import CarImage from "components/ui/CarImage";

import { MOBILE_WIDTH_MAX } from "app/constants";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

class CompetitorsCarousel extends React.Component {
  state = {
    dots: false,
    infinite: true,
    arrows: true,
    variableWidth: false,
    speed: 500,
    prevArrow: <CarouselArrow direction="left" />,
    nextArrow: <CarouselArrow direction="right" />,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  updateSliderWidth = () => {
    const { competitors } = this.props;
    const windowWidth = document.documentElement.clientWidth;
    const slider = document.querySelector(".competitors-slider");
    const sliderWidth = slider ? slider.clientWidth : null;
    const itemWidth = 160;

    let stateChanges = {
      sliderWidth: sliderWidth,
      slidesToShow: Math.min(
        competitors.edges.length,
        Math.floor(sliderWidth / itemWidth),
      ),
      arrows: windowWidth > MOBILE_WIDTH_MAX,
    };

    this.setState({ ...stateChanges });
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateSliderWidth);
    this.updateSliderWidth();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateSliderWidth);
  };

  render() {
    const { sliderWidth } = this.state;
    const { competitors, selectedRegion } = this.props;

    return (
      <div className="competitors">
        <h3 className="gray-title">{getLocalText("Ближайшие конкуренты")}</h3>

        <div
          className="competitors-slider"
          style={{ maxWidth: sliderWidth ? sliderWidth + "px" : "unset" }}
        >
          <Slider {...this.state}>
            {competitors.edges.map((competitor, index) => (
              <Link
                to={`${getRegionUrl(selectedRegion)}${
                  competitor.node.resourcePath
                }?${createPathBasedOnExpressions(competitor.expressions)}`}
                className="slick-slide_container"
                key={index}
              >
                <div className="slick-slide-wrapper_container">
                  <div className="slick-slide-wrapper">
                    <div className="slide-image_container">
                      <div className="slide-image">
                        <CarImage src={competitor.node.picture} />
                      </div>
                    </div>

                    <div className="text_container">
                      <div className="slider-name">
                        {competitor.node.brand.name} {competitor.node.name}
                      </div>

                      <div className="slide-offers-count">
                        {getLocalTextWithNumbers(
                          "${offerCount} ${changeableText}",
                          [
                            {
                              id: "offerCount",
                              value: competitor.offerCount,
                            },
                            {
                              id: "changeableText",
                              value: declOfNum(competitor.offerCount, [
                                "автомобиль",
                                "автомобиля",
                                "автомобилей",
                              ]),
                            },
                          ],
                        )}
                      </div>

                      <div className="slide-price">
                        {getLocalText("от ")}
                        <Price value={competitor.price.low} />
                        {getLocalText(" ₽")} <br />
                        {getLocalText("до ")}
                        <Price value={competitor.price.high} />
                        {getLocalText(" ₽")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default CompetitorsCarousel;
