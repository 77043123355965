import React from "react";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

const Specification = ({ data }) => {
  return (
    <div className="specification">
      {data.map(item => {
        return (
          <div className="line" key={item.name}>
            <div className="specification-name">{getLocalText(item.name)}:</div>
            {item.has === null ? (
              <div className="specification-value-empty">
                {getLocalText("нет данных")}
              </div>
            ) : (
              <div className="specification-value">{`${getLocalText(
                item.ins && item.ins[0]
                  ? item.ins[0].description
                  : item.value
                  ? item.value
                  : item.has
                  ? getLocalText("есть")
                  : getLocalText("нет"),
              )} ${getLocalText(item.unit || "")}${getLocalText(
                (item.name === "Мощность, л.с." ||
                  item.name === "Максимальный крутящий момент, Н·м") &&
                  item.ins &&
                  item.ins[0] &&
                  item.ins[0].description.indexOf("(") !== -1
                  ? getLocalText(" (об/мин)")
                  : "",
              )}`}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Specification;
