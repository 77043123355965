import React from "react";
import Link from "components/ui/Link";
import classNames from "classnames";

import PriceRange from "components/shared/PriceRange";

import { getDealersPageUrl, declOfNum } from "utils";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const PricesList = ({ carsList, selectedRegionPath, search }) => {
  return (
    <div className="prices-list_container">
      {carsList.map((car, index) => {
        return (
          car && (
            <div
              className={classNames(
                "price_container",
                carsList.length === 1 && "single",
              )}
              key={car.id + index}
            >
              {car.offers.price.low ? (
                <div>
                  <PriceRange
                    price={{
                      low: car.offers.price.low,
                      high:
                        car.offers.price.high === car.offers.price.low
                          ? car.offers.price.high
                          : null,
                    }}
                  />
                  <div className="cars-count">
                    <Link
                      className="cars-count"
                      to={getDealersPageUrl(
                        car.id,
                        car.model.resourcePath,
                        selectedRegionPath,
                        search,
                      )}
                    >
                      {getLocalTextWithNumbers(
                        "${totalCount} ${changeableText}",
                        [
                          {
                            id: "totalCount",
                            value: car.offers.totalCount,
                          },
                          {
                            id: "changeableText",
                            value: declOfNum(car.offers.totalCount, [
                              "автомобиль",
                              "автомобиля",
                              "автомобилей",
                            ]),
                          },
                        ],
                      )}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="no-price-label">
                  {getLocalText("Нет в продаже")}
                </div>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default PricesList;
