import React, { Fragment } from "react";
import * as Sentry from "@sentry/browser";

import { getText } from "utils/i18nService";
import { isWidgetMode, onHeightChange } from "app/utils/frameService";

import Header from "components/Header";
import Footer from "components/Footer";
import LinkButton from "components/ui/LinkButton";

import NotFoundSignImage from "errors/internal-server.jpg";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

class ErrorBoundary extends React.Component {
  state = {
    hasError: this.props.hasError ? this.props.hasError : false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    const { errorText } = this.props;
    if (errorText && errorText !== "undefined") {
      console.log("SSR error: ", errorText);
    }
    window.addEventListener("catchErrorFromApi", this.catchErrorFromApi);
    window.addEventListener("popstate", e => {
      const { hasError } = this.state;
      if (hasError) {
        this.setState({ hasError: false });
      }
    });
    window.addEventListener("unhandledrejection", event => {
      if (event.reason !== "500 - внутренняя ошибка сервера") {
        console.log(event.reason);
        Sentry.captureException("Error after try to render bad api response");
        if (isWidgetMode) {
          onHeightChange(window.innerHeight);
        }
        this.setState({ hasError: true });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("catchErrorFromApi", this.catchErrorFromApi);
  }

  componentDidCatch(error, info) {
    const { staticContext } = this.props;
    if (staticContext) {
      staticContext.status = 500;
      staticContext.info = 500;
    }
    if (isWidgetMode) {
      onHeightChange(window.innerHeight);
    }

    Sentry.captureException(error);

    console.log(error);
    console.log(info);
  }

  catchErrorFromApi = () => {
    this.setState({ hasError: true });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (!hasError) {
      // Вы можете отрисовать любой резервный UI
      return children;
    }
    return (
      <div className="skeleton error-boundary">
        <Header
          onFilterHistoryClick={() => {
            this.setState({ hasError: false });
          }}
          onLogoClick={() => {
            this.setState({ hasError: false });
          }}
          isError={true}
          showBackButton={false}
          showCompareBtn={false}
        />
        <div id="main" className="error-boundary_page">
          <div className="error-boundary_container">
            <div>
              <img src={NotFoundSignImage} alt="" className="image" />
            </div>
            <div className="title">
              {getLocalText(getText("errors.internalServerError.title"))}
            </div>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: getLocalText(getText("errors.internalServerError.text")),
              }}
            />
            <div className="button_container">
              <LinkButton
                color="orange"
                text={getLocalText(getText("errors.internalServerError.button"))}
                url={"/"}
                onClick={() => {
                  this.setState({ hasError: false });
                }}
              />
            </div>
          </div>
        </div>
        <Footer
          onClick={() => {
            this.setState({ hasError: false });
          }}
        />
      </div>
    );
  }
}

export default ErrorBoundary;
