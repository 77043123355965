import React from "react";

import graphqlClient from "app/graphql/services/client";
import {
  getPopularAndSelectedRegionsQuery,
  getRegions,
} from "app/graphql/queries";
import { REQUEST_STATUS } from "app/constants";
import unionBy from "lodash/unionBy";
import debounce from "lodash/debounce";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";
import { setContextCookie } from "app/utils/cookieService";

export const RegionsContext = React.createContext();

class Regions extends React.Component {
  state = {
    radius: this.props.radius || 0,
    regions: this.props.regions || [],
    searchResults: this.props.searchResults || [],
    selectedRegions: this.props.selectedRegions || [],
    detectRegion: this.props.detectRegion === undefined,
    showPopup: this.props.showPopup || false,
    status: REQUEST_STATUS.NONE,
  };

  changeState = newState => {
    if (newState.radius || newState.selectedRegions) {
      setContextCookie({
        regions: {
          radius: newState.radius || this.state.radius,
          selectedRegions:
            newState.selectedRegions || this.state.selectedRegions,
          detectRegion: false,
        },
      });
    }

    this.setState({
      ...this.state,
      detectRegion: false,
      ...newState,
    });
  };

  searchRegions = query => {
    this.setState({ status: REQUEST_STATUS.LOADING });
    debounce(query => {
      if (query.length >= 2) {
        graphqlClient.fetch(getRegions, { query }).then(res => {
          let searchResults = res.regions.edges.map(({ node }) => node);
          if (
            widgetConfig &&
            widgetConfig.regionFilter &&
            widgetConfig.regionFilter.isAllEnable
          ) {
            searchResults = searchResults.filter(
              region =>
                !widgetConfig.regionFilter.selectedFiltersIdList.some(
                  regionId => regionId === region.id,
                ),
            );
          }
          this.setState({
            searchResults: searchResults,
            status: REQUEST_STATUS.SUCCESS,
          });
        });
      } else {
        this.setState({
          searchResults: [],
          status: REQUEST_STATUS.FAIL,
        });
      }
    }, 500)(query);
  };

  getRegionByName = (cityName, regionName) => {
    return new Promise(resolve => {
      if (cityName || regionName) {
        graphqlClient
          .fetch(getRegions, { query: cityName || regionName })
          .then(res => {
            if (res.regions.edges.length > 0) {
              resolve(res.regions.edges[0]);
            } else if (cityName) {
              //if cityName is not in DB, check regionName
              graphqlClient
                .fetch(getRegions, { query: regionName })
                .then(res => {
                  resolve(res.regions.edges[0]);
                })
                .catch(() => {});
            } else {
              resolve();
            }
          })
          .catch(() => {});
      } else {
        resolve();
      }
    });
  };

  render() {
    return (
      <RegionsContext.Provider
        value={{
          ...this.state,
          functions: {
            changeState: this.changeState,
            searchRegions: this.searchRegions,
            getRegionByName: this.getRegionByName,
          },
        }}
      >
        {this.props.children}
      </RegionsContext.Provider>
    );
  }
}

export const getPopularAndSelectedRegions = (
  geoPath,
  radius,
  selectedRegions = [],
  regionFilterIdsList,
) => {
  const vars = {
    corpus: "PREFERRED",
    ids: selectedRegions,
    geo: {
      ids: [],
    },
    filtersIds: regionFilterIdsList ? regionFilterIdsList : [],
    ...(geoPath ? { geoPath: geoPath } : {}),
  };

  return new Promise((resolve, reject) => {
    graphqlClient.fetch(getPopularAndSelectedRegionsQuery, vars).then(
      response => {
        let { favorite } = response;
        const { fromCookie, fromUrl, fromFilters } = response;
        if (isWidgetMode && widgetConfig.popularRegions) {
          favorite = {
            edges: widgetConfig.popularRegions.map(item => ({ node: item })),
          };
        }
        const isGetRegionFromUrl =
          fromUrl.region &&
          (fromCookie.length !== 1 || fromCookie[0].id !== fromUrl.region.id);
        const result = {
          regions: unionBy(
            favorite.edges.map(({ node }) => ({
              ...node,
              isFavourite: true,
            })),
            isGetRegionFromUrl ? [fromUrl.region] : fromCookie,
            "id",
          ),
          selectedRegions: isGetRegionFromUrl ? [fromUrl.region.id] : null,
          radius:
            radius !== null && radius !== undefined ? Number(radius) : 200,
          searchResults: fromFilters,
        };
        resolve(result);
      },
      () => {
        reject();
      },
    );
  });
};

export const RegionsProvider = Regions;
