import React, { useEffect, useState } from "react";

import NoCarImage from "components/shared/NoCarImage";

import "./styles.less";

const CarImage = ({ className, src }) => {
  const [isErrorState, setIsErrorState] = useState(false);

  useEffect(() => {
    let img = document.createElement("img");
    img.onerror = () => {
      setIsErrorState(true);
    };
    img.src = src;
  }, []);

  return !isErrorState && src ? (
    <img className={className} src={src} />
  ) : (
    <NoCarImage />
  );
};

export default CarImage;
