import React from "react";
import classNames from "classnames";

import { declOfNum, getOfferPageUrl, getDealersPageUrl } from "utils";

import Colors from "components/OffersListPage/Offer/Colors";
import PricesBlock from "components/shared/PricesBlock";
import Results from "components/OffersListPage/Offer/Results";
import Tooltip from "components/ui/Tooltip";
import Link from "components/ui/Link";
import CarImage from "components/ui/CarImage";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const Offer = ({
  search,
  offer,
  region,
  carsIdList,
  addToCarsIdList,
  removeFromCarsIdList,
}) => {
  const description = offer.headline
    ? offer.headline.map(item => getLocalText(item)).join(", ")
    : "";
  const title = `${offer.brand.name} ${getLocalText(offer.model.name)} ${getLocalText(offer.versionName)}`;
  const modification = offer.modificationName
    ? getLocalText(offer.modificationName.replace("0.0", ""))
    : "";
  const interior = getLocalText(offer.interior);
  const offerUrl = getOfferPageUrl(
    offer.id,
    offer.model.resourcePath.replace(/^\//, ""),
    region,
    search,
  );

  const dealersUrl = getDealersPageUrl(
    offer.id,
    offer.model.resourcePath.replace(/^\//, ""),
    region,
    search,
  );

  const isInCompareList = carsIdList.some(id => id === offer.id);

  const offerCountText = getLocalTextWithNumbers(
    "${totalCount} ${changeableText} от ${dealerCount} ${changeableText2}",
    [
      {
        id: "totalCount",
        value: offer.offers.totalCount,
      },
      {
        id: "changeableText",
        value: declOfNum(offer.offers.totalCount, [
          "автомобиль",
          "автомобиля",
          "автомобилей",
        ]),
      },
      {
        id: "dealerCount",
        value: offer.offers.dealerCount,
      },
      {
        id: "changeableText2",
        value: declOfNum(offer.offers.dealerCount, [
          "дилера",
          "дилеров",
          "дилеров",
        ]),
      },
    ],
  );

  return (
    <div className="c-offer clearfix">
      <div className="s-mobile-name">
        <Link className="s-title" to={offerUrl}>
          {title}
        </Link>
        <div className="s-short">{modification}</div>
      </div>

      <div className="s-photo">
        <Link to={offerUrl}>
          <div className="s-image">
            <CarImage src={`${offer.photo}`} />
          </div>
        </Link>
      </div>

      <div className="s-content">
        <Link className="s-title" to={offerUrl}>
          {title}
        </Link>

        <div className="s-short">{modification}</div>
      </div>

      <div className="right_container">
        <PricesBlock
          price={offer.offers.price}
          marketingPrice={offer.offers.marketingPrice}
        />

        <Results
          offer={offer}
          dealersPageUrl={dealersUrl}
          offerCountText={offerCountText}
        />
      </div>

      <div className="s-full-content">
        {offer.offers && offer.offers.colors && offer.offers.colors.length > 0 && (
          <Colors
            label={`${getLocalText("Цвет кузова")}:`}
            colors={offer.offers.colors}
          />
        )}

        <div className="s-description">
          <span>{getLocalText("Интерьер")}:</span>&nbsp;
          {interior}
        </div>

        <div className="s-description">
          <span>{getLocalText("Описание")}:</span>
          {description}
        </div>

        <Results
          offer={offer}
          dealersPageUrl={dealersUrl}
          offerCountText={offerCountText}
        />
      </div>

      <Tooltip
        content={
          isInCompareList
            ? getLocalText("Удалить из сравнения")
            : getLocalText("Добавить к сравнению")
        }
      >
        <div className="icons_container">
          <i
            className={classNames(
              "icon icon-compare",
              isInCompareList && "active",
            )}
            onClick={() => {
              if (!isInCompareList) {
                addToCarsIdList(offer.id);
              } else {
                removeFromCarsIdList(offer.id);
              }
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default Offer;
