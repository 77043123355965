import React from "react";
import Tippy from "@tippyjs/react";

import "./styles.less";

const Tooltip = ({
  children,
  content = "",
  distance = 7,
  touch = false,
  interactive = false,
  appendTo = "parent",
  ...props
}) => {
  return (
    <Tippy
      content={
        <div
          className={"tooltip-content_container"}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      }
      offset={[0, distance]}
      appendTo={appendTo}
      touch={touch}
      interactive={interactive}
      {...props}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
