import React, { useContext } from "react";
import { matchPath, withRouter } from "react-router";
import Link from "components/ui/Link";
import classNames from "classnames";

import { CompareContext } from "context/Compare";
import { RouterHistoryListContext } from "context/RouterHistoryList";
import {
  getRegionUrl,
  getFilterRequestParamsFromLocation,
  getModelUrl,
  getOfferPageUrl,
  getCompareUrl,
  parseUrlToFilter,
} from "app/utils";
import { isWidgetMode } from "utils/frameService";

import Region from "components/Header/Region";
import IconButton from "components/ui/IconButton";

import "./styles.less";
import {
  getDefaultDomainFilters,
  getDefaultExpressionsAndRegions,
} from "app/utils/filtersService";
import { OffersListContext } from "app/context/OffersList";
import { FiltersContext } from "app/context/Filters";
import { SavedFiltersContext } from "app/context/SavedFilters";
import { RegionsContext } from "app/context/Regions";
import { getLocalText } from "app/utils/i18nService";
import { widgetConfig } from "app/utils/frameService";

const Header = props => {
  const { regions = [], radius, selectedRegions = [] } =
    useContext(RegionsContext) || {};
  const routerHistoryList = useContext(RouterHistoryListContext);
  const compare = useContext(CompareContext);
  const { isPopupShown } = useContext(FiltersContext) || {};
  const { savedFilters = [], functions: savedFiltersContextFunctions } =
    useContext(SavedFiltersContext) || {};
  const { functions: filtersContextFunctions } =
    useContext(FiltersContext) || {};
  const { functions: offersListContextFunctions } =
    useContext(OffersListContext) || {};

  const getComponentClassAndOfferId = () => {
    const { location } = props;
    const { pathname } = location;
    let offerId;
    let componentClass = "c-header clearfix ";

    if (pathname) {
      const splittedPathname = pathname.split("/");

      if (splittedPathname.length > 1) {
        offerId = splittedPathname[splittedPathname.length - 1];

        if (splittedPathname[1] === "map") {
          componentClass += "map";
        } else if (splittedPathname[1] === "dealers") {
          componentClass += "dealers";
        } else if (splittedPathname[1] === "offer") {
          componentClass += "offer";
        } else {
          componentClass += "home";
        }
      }
      return { componentClass, offerId };
    }
  };

  const onFilterHistoryClick = (selectedRegionResourcePath, event) => {
    const { history, isOffersList } = props;
    if (event) {
      event.preventDefault();
    }
    let path = savedFilters[savedFilters.length - 1];
    history.push(
      savedFilters.length === 0
        ? getRegionUrl(selectedRegionResourcePath)
        : path,
    );
    if (isOffersList) {
      const offersParams = getFilterRequestParamsFromLocation(
        savedFilters.length === 0
          ? {}
          : {
              pathname: path.replace(/[?][\s\S]+/g, ""),
              search: path.replace(/(.*?)[?]/g, "?"),
            },
        regions,
        selectedRegions,
        radius,
      );
      if (isWidgetMode) {
        const defaultExpressions = getDefaultExpressionsAndRegions(
          offersParams.expressions,
        );
        offersParams.expressions = defaultExpressions.expressions;
        offersParams.domain = getDefaultDomainFilters();
      }

      offersListContextFunctions.getOffersList(
        offersParams,
        filtersContextFunctions.setFiltersData,
      );
    }
    if (savedFilters.length !== 0) {
      savedFiltersContextFunctions.removeLastFilter();
    }
  };

  const onLeftArrowBtnClick = () => {
    const { history, location, onBackButtonClick } = props;
    const filters = parseUrlToFilter(location.pathname, location.search);

    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      const matchOfferDetail = matchPath(
        location.pathname,
        "/:regionPath?/cars/new/:brand/:model/:offerId",
      );
      const isOfferDetailPage = matchOfferDetail && matchOfferDetail.isExact;
      let matchPrevPageDealers = null;
      if (routerHistoryList.length > 1) {
        matchPrevPageDealers = matchPath(
          routerHistoryList[routerHistoryList.length - 2].pathname,
          "/:regionPath?/cars/new/:brand/:model/:offerId/:mode",
        );
      }

      if (
        routerHistoryList.length > 1 &&
        (!isOfferDetailPage ||
          (isOfferDetailPage &&
            !(matchPrevPageDealers && matchPrevPageDealers.isExact)))
      ) {
        history.goBack();
      } else {
        let search = location.search.replace(/^[?]/g, "");
        let prevPageUrl = isOfferDetailPage
          ? getModelUrl(filters.model, filters.region, filters.brand, search)
          : getOfferPageUrl(
              filters.version,
              `${filters.brand}/${filters.model}`,
              filters.region,
              search,
            );
        history.push(`${prevPageUrl}`);
      }
    }
  };

  const {
    onLogoClick,
    onFilterHistoryClick: onFilterHistoryClickFromProps,
    showBackButton = false,
    history,
    location,
    showCompareBtn = true,
    showRegionBtn = true,
    isError = false,
  } = props;

  const { componentClass } = getComponentClassAndOfferId();

  let selectedRegionResourcePath = "";
  if (regions.length > 0 && selectedRegions.length === 1) {
    selectedRegionResourcePath =
      regions.find(region => selectedRegions[0] === region.id).resourcePath ||
      "";
  }

  if (isError && isPopupShown) {
    filtersContextFunctions.changeState(null, null, {
      isPopupShown: false,
    });
  }

  return (
    <header className={componentClass}>
      {(!isWidgetMode || (isWidgetMode && widgetConfig.showLogo)) && (
        <div
          className={classNames(
            "s-logo",
            (showBackButton || isPopupShown) && !isError && "mobile-hide",
          )}
        >
          <Link
            to=""
            onClick={() => {
              if (onLogoClick) {
                onLogoClick();
              }
            }}
          >
            <span className="auto">{getLocalText("Auto")}</span>
            <span className="ilsa">{getLocalText("ILSA")}</span>
          </Link>
        </div>
      )}

      <div
        className={classNames(
          "s-mobile-back",
          (showBackButton || isPopupShown) && !isError && "mobile-show",
        )}
      >
        <IconButton icon="icon-left-arrow" onClick={onLeftArrowBtnClick} />
      </div>

      <Link
        className={classNames(
          "filter-history_container",
          (showBackButton || isPopupShown) && !isError && "mobile-hide",
          isWidgetMode && "widget",
        )}
        to={
          savedFilters[savedFilters.length - 1]
            ? savedFilters[savedFilters.length - 1]
            : getRegionUrl(selectedRegionResourcePath)
        }
        onClick={event => {
          if (onFilterHistoryClickFromProps) {
            let mode = onFilterHistoryClickFromProps(event);
            if (mode === "stopExecution") {
              return;
            }
          }
          onFilterHistoryClick(selectedRegionResourcePath, event);
        }}
      >
        <IconButton icon="icon-filter-history" />
        <span className="filter-history_label">{getLocalText("Подбор")}</span>
      </Link>

      <div className="compare_container">
        {compare && showCompareBtn && (
          <Link
            className="link"
            to={getCompareUrl(
              selectedRegionResourcePath || null,
              compare.carsIdList,
              location.search,
            )}
            onClick={e => {
              const pathList = props.location.pathname.split("/");
              if (pathList[pathList.length - 1] === "compare") {
                e.preventDefault();
              }
            }}
          >
            <i className="icon icon-compare" />
            {compare.carsIdList.length > 0 && (
              <span className="compare-cars-count">
                {compare.carsIdList.length}
              </span>
            )}
            <span className="label">{getLocalText("Сравнение")}</span>
          </Link>
        )}
      </div>

      {showRegionBtn && <Region history={history} location={location} />}
    </header>
  );
};

export default withRouter(Header);
