import React, { PureComponent } from "react";
import classNames from "classnames";
import Link from "components/ui/Link";

import { getBrandUrl, getModelUrl } from "utils";
import FilterValue from "./filter-value";
import {getLocalText} from "app/utils/i18nService";

export default class FilterValues extends PureComponent {
  handleValueClick = (event, value) => {
    event.preventDefault();
    if (!value.count && !value.checked) {
      return;
    }

    const {
      filter, // Filter's data
      onValueClick,
      onUnselectFilter,
    } = this.props;

    if (value.checked && !filter.multiple) {
      onUnselectFilter(filter.id, event.target);
    } else {
      onValueClick(filter.id, value.id, event.target);
    }
  };

  render() {
    const { filter, selectedRegionPath, selectedBrandPath } = this.props;

    if (!filter.values || filter.values.length === 0) {
      return null;
    }

    const isDecreaseFontSize = filter.values.some(
      value => value.name.length > 86,
    );

    return (
      <ul className="c-filter-values">
        {filter.values.map(value => {
          return !value.isInvisible ? (
            <li className="s-value" key={`value-${value.id}`}>
              <FilterValue
                disabled={!value.count}
                multiple={filter.multiple}
                selected={value.checked}
                onClick={event => this.handleValueClick(event, value)}
              />

              {filter.id === "1101" || filter.id === "1102" ? (
                <Link
                  to={
                    filter.id === "1101"
                      ? getBrandUrl(selectedRegionPath, value.slug)
                      : getModelUrl(
                          value.slug,
                          selectedRegionPath,
                          selectedBrandPath,
                        )
                  }
                  className={classNames(
                    "s-title",
                    {
                      "no-results": !value.count,
                    },
                    isDecreaseFontSize && "decrease-font-size",
                  )}
                  onClick={event => this.handleValueClick(event, value)}
                >
                  {getLocalText(value.name)}
                </Link>
              ) : (
                <div
                  className={classNames(
                    "s-title",
                    {
                      "no-results": !value.count,
                    },
                    isDecreaseFontSize && "decrease-font-size",
                  )}
                  onClick={event => this.handleValueClick(event, value)}
                >
                  {getLocalText(value.name)}
                </div>
              )}
            </li>
          ) : null;
        })}
      </ul>
    );
  }
}
