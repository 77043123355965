export default new function() {
  let base = this;

  base.init = function() {
    // String functions
    String.prototype.trim = function() {
      return this.replace(/^\s+|\s+$/g, "");
    };

    String.prototype.repeat = function(n) {
      return new Array(1 + n).join(this);
    };
  };
  base.init();

  let isEmpty = function(x) {
    return typeof x == "undefined" || x.length == 0 || x == null;
  };

  let isCssJson = function(node) {
    return !isEmpty(node) ? node.attributes && node.children : false;
  };

  /**
   * @param node
   *            A JSON node.
   * @param depth
   *            The depth of the current node; used for indentation and
   *            optional.
   * @param breaks
   *            Whether to add line breaks in the output.
   */
  base.toCSS = function(node, depth, breaks) {
    let cssString = "";
    if (typeof depth == "undefined") {
      depth = 0;
    }
    if (typeof breaks == "undefined") {
      breaks = false;
    }
    if (node.attributes) {
      for (let i in node.attributes) {
        let att = node.attributes[i];
        if (att instanceof Array) {
          for (let j = 0; j < att.length; j++) {
            cssString += strAttr(i, att[j], depth);
          }
        } else {
          cssString += strAttr(i, att, depth);
        }
      }
    }
    if (node.children) {
      let first = true;
      for (let i in node.children) {
        if (breaks && !first) {
          cssString += "\n";
        } else {
          first = false;
        }
        cssString += strNode(i, node.children[i], depth);
      }
    }
    return cssString;
  };

  /**
   * @param data
   *            You can pass css string or the CSSJS.toJSON return value.
   * @param id (Optional)
   *            To identify and easy removable of the style element
   * @param replace (Optional. defaults to TRUE)
   *            Whether to remove or simply do nothing
   * @return HTMLLinkElement
   */
  base.toHEAD = function(data, id, replace) {
    let head = document.getElementsByTagName("head")[0];
    let xnode = document.getElementById(id);
    let _xnodeTest = xnode !== null && xnode instanceof HTMLStyleElement;

    if (isEmpty(data) || !(head instanceof HTMLHeadElement)) return;
    if (_xnodeTest) {
      if (replace === true || isEmpty(replace)) {
        xnode.removeAttribute("id");
      } else return;
    }
    if (isCssJson(data)) {
      data = base.toCSS(data);
    }

    let node = document.createElement("style");
    node.type = "text/css";

    if (!isEmpty(id)) {
      node.id = id;
    } else {
      node.id = "cssjson_" + timestamp();
    }
    if (node.styleSheet) {
      node.styleSheet.cssText = data;
    } else {
      node.appendChild(document.createTextNode(data));
    }

    head.appendChild(node);

    if (isValidStyleNode(node)) {
      if (_xnodeTest) {
        xnode.parentNode.removeChild(xnode);
      }
    } else {
      node.parentNode.removeChild(node);
      if (_xnodeTest) {
        xnode.setAttribute("id", id);
        node = xnode;
      } else return;
    }

    return node;
  };

  // Alias

  if (typeof window != "undefined") {
    window.createCSS = base.toHEAD;
  }

  // Helpers

  let isValidStyleNode = function(node) {
    return node instanceof HTMLStyleElement && node.sheet.cssRules.length > 0;
  };

  let timestamp = function() {
    return Date.now() || +new Date();
  };

  let strAttr = function(name, value, depth) {
    return "\t".repeat(depth) + name + ": " + value + ";\n";
  };

  let strNode = function(name, value, depth) {
    let cssString = "\t".repeat(depth) + name + " {\n";
    cssString += base.toCSS(value, depth + 1);
    cssString += "\t".repeat(depth) + "}\n";
    return cssString;
  };
}();