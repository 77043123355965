import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import Dealer from "components/DealersPage/Dealer";
import GoogleMap from "components/DealersPage/Map/GoogleMap";

import {
  isWidgetMode,
  widgetConfig,
  onHeightChange,
  isContactsModeEnable,
} from "app/utils/frameService";
import { useWindowSize } from "utils/windowSizeHook";

import "./styles.less";
import { getCarsList } from "utils";
import { useUpdateEffect } from "app/utils/useUpdateEffect";

let minHeightIE = 0;
const Map = props => {
  const { isMobileWidth, isTabletWidth } = useWindowSize();
  const [state, setState] = useState({
    center: {
      lat: 55,
      lng: 38,
    },
    activeDealer: null,
  });
  const [windowSizeState, _setWindowSizeState] = useState({
    height: 0,
  });
  const windowSizeStateRef = React.useRef(windowSizeState);
  const setWindowSizeState = newState => {
    windowSizeStateRef.current = newState;
    _setWindowSizeState(newState);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useUpdateEffect(() => {
    setState({ ...state, activeDealer: null, center: null });
  }, [props.dealers]);

  const resizeHandler = () => {
    setWindowSizeState({
      height: window.innerHeight,
    });

    if (isWidgetMode) {
      const userAgent = window.navigator.userAgent;
      const isIE =
        userAgent.indexOf("MSIE ") > 0 || userAgent.indexOf("Trident/") > 0;
      if (isIE) {
        if (minHeightIE === 0) {
          minHeightIE = window.innerHeight;
        }
        if (window.innerHeight < minHeightIE) {
          minHeightIE = window.innerHeight;
        }
        onHeightChange(minHeightIE);
      } else {
        onHeightChange(window.innerHeight);
      }
    }
  };

  const handleMarkerClick = dealer => {
    let center = {
      center: {
        lat: dealer.map.position.lat,
        lng: dealer.map.position.lng,
      },
    };

    setState({ ...state, ...center, activeDealer: dealer });
  };

  const {
    onSelectDealer,
    dealers,
    selectedDealersIdList,
    openMobileDealerCarsList,
    setPopupSettings,
  } = props;
  const { center, activeDealer } = state;
  const { height } = windowSizeState;

  let mapCenter = undefined;
  if (
    dealers &&
    dealers.length &&
    typeof google !== "undefined" &&
    dealers[0].map.position.lat
  ) {
    mapCenter = {
      lat: dealers[0].map.position.lat,
      lng: dealers[0].map.position.lng,
    };
  }

  const withoutShowMore = activeDealer && activeDealer.variants.length === 1;
  const withoutCarsInfo =
    isTabletWidth &&
    activeDealer &&
    isWidgetMode &&
    !widgetConfig.showDealersFilters;
  const withBookingBtn =
    isTabletWidth &&
    activeDealer &&
    (!isWidgetMode || widgetConfig.showDealersFilters) &&
    getCarsList(activeDealer, false)[0].booking;
  const contactBtnVisible =
    isTabletWidth && (!isWidgetMode || isContactsModeEnable);

  let mapHeight =
    height -
    (isTabletWidth && !isMobileWidth ? 15 : 0) -
    (isTabletWidth ? 148 : 128) -
    (contactBtnVisible ? 58 : 0) -
    (withoutCarsInfo ? 110 : 0) -
    (withBookingBtn ? 40 : 0) -
    (isTabletWidth &&
    activeDealer &&
    (!isWidgetMode || widgetConfig.showDealersFilters)
      ? withoutShowMore
        ? 180
        : 220
      : 0) -
    (isTabletWidth ? 0 : 56);
  if (!isTabletWidth && mapHeight < 420) {
    mapHeight = 420;
  }

  return (
    <div className="dealers-map">
      {mapCenter && (
        <GoogleMap
          height={mapHeight}
          center={center}
          dealers={dealers}
          equipmentId={props.equipmentId}
          pathname={props.pathname}
          selectedDealersIdList={selectedDealersIdList}
          activeDealer={activeDealer}
          onMarkerClick={handleMarkerClick}
          onMapClick={() => {
            if (isTabletWidth) {
              setState({ ...state, center: null, activeDealer: null });
            }
          }}
          onSelectDealer={onSelectDealer}
          onClosePopover={() => {
            setState({ ...state, center: null, activeDealer: null });
          }}
          setPopupSettings={setPopupSettings}
        />
      )}

      {activeDealer && isTabletWidth && (
        <div
          className={classNames(
            "selected-dealer_container",
            withoutShowMore && "without-show-more",
          )}
        >
          <Dealer
            key={activeDealer.node.id}
            dealer={activeDealer}
            equipmentId={props.equipmentId}
            pathname={props.pathname}
            selectedDealers={selectedDealersIdList}
            selectDealer={onSelectDealer}
            openMobileDealerCarsList={openMobileDealerCarsList}
            setPopupSettings={setPopupSettings}
          />
        </div>
      )}
    </div>
  );
};

export default Map;
