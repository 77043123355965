import React from "react";
import CheckBox from "components/ui/CheckBox";
import RadioButton from "components/ui/RadioButton";

const FilterValue = ({ multiple, selected, onClick, disabled }) => {
  return multiple ? (
    <CheckBox checked={selected} onClick={onClick} disabled={disabled} />
  ) : (
    <RadioButton checked={selected} onClick={onClick} disabled={disabled} />
  );
};

export default FilterValue;
