import React from "react";
import classNames from "classnames";

import { useWindowSize } from "app/utils/windowSizeHook";

import "./styles.less";

const Marker = ({ isSelected, isActive, onClick, content }) => {
  const { isTabletWidth } = useWindowSize();
  return (
    <div className="marker" onClick={onClick}>
      <div className="marker-icon">
        {isSelected ? (
          <i className="icon icon-marker" />
        ) : (
          <i
            className={classNames(
              "icon icon-empty-marker",
              isActive && "active",
            )}
          />
        )}
      </div>
      {isActive && !isTabletWidth && content}
    </div>
  );
};

export default Marker;
