import React from "react";

import { getPopularAndSelectedRegions } from "app/context/Regions";

import Header from "components/Header";
import Footer from "components/Footer";
import Seo from "components/Seo";

const TITLE = "Правовые аспекты";
const DESCRIPTION = "";

const LegalPage = () => (
  <div className="skeleton c-static-page">
    <Seo description={DESCRIPTION} title={TITLE} />
    <Header />
    <div id="main" className="content">
      <h1>Правовые аспекты</h1>

      <h2>1. Продукция и цены</h2>

      <p className="content--row">
        <strong>
          Изображения, цвета, тексты, цены, спецификации, внешний вид,
          технические и&nbsp; эксплуатационные характеристики новых автомобилей
        </strong>{" "}
        мы рекомендуем рассматривать как&nbsp; приблизительные. Производители
        оставляют за&nbsp;собой право без предварительного уведомления&nbsp;
        осуществлять конструктивные изменения автомобилей, их комплектаций,
        формальные изменения информации об&nbsp; автомобилях. Поэтому материалы,
        размещаемые на сайте, могут изменяться и корректироваться по&nbsp; мере
        поступления к&nbsp;нам информации о таких изменениях, но в&nbsp;рамках
        наших технических&nbsp; возможностей мы не&nbsp; можем гарантировать
        отсутствие ошибок и точное соответствие серийным моделям.
      </p>

      <p className="content--row">
        Поскольку мы не размещаем условия продажи автомобилей со стороны
        официальных дилеров, то&nbsp; дилеры по своему усмотрению могут изменять
        цены в&nbsp;зависимости от&nbsp;этих условий без&nbsp; предварительного
        уведомления. Поэтому указанные цены следует рассматривать как
        рекомендованные для&nbsp; переговоров с официальными дилерами.{" "}
        <strong>
          Информацию об актуальных ценах мы рекомендуем узнавать у&nbsp;
          официальных дилеров-продавцов автомобилей.
        </strong>{" "}
        <strong>
          Мы стремимся размещать информацию только о&nbsp; новых автомобилях, по
          которым со&nbsp;стороны дилера получено подтверждение
          о&nbsp;возможности продажи
        </strong>
        .
      </p>

      <p className="content--row">
        В силу различных обстоятельств мы не несем обязательств
        за&nbsp;достоверность предоставленной&nbsp; дилерами информации
        и&nbsp;рассматриваем ее как предварительную для оценки возможности
        покупки&nbsp; автомобиля, более того, ни&nbsp;при каких условиях такая
        информация не&nbsp;является публичной офертой,&nbsp; определяемой
        положениями Статьи 437 Гражданского кодекса Российской Федерации.
      </p>

      <h2>2. Гарантии и ответственность</h2>

      <p className="content--row">
        Информация представлена на&nbsp;сайте "как она есть" без&nbsp;гарантий
        любого рода,&nbsp; фактических или подразумеваемых, в&nbsp;том числе без
        гарантий отсутствия нарушений прав третьих лиц.
      </p>

      <p className="content--row">
        На&nbsp;наших интернет-страницах Вы также найдёте ссылки на другие
        интернет-ресурсы (внешние сайты), к&nbsp; содержанию которых мы не имеем
        никакого отношения.
      </p>

      <h2>3. Авторские права и товарные знаки</h2>

      <p className="content--row">
        Авторские права на&nbsp;некоторые материалы, размещенные на сайте
        (текст, обозначения, изображения и&nbsp; т.п.), могут принадлежать
        третьим лицам. Если мы случайно нарушили Ваши авторские права, то&nbsp;
        Вы можете сообщить нам об&nbsp;этом, и&nbsp;мы предпримем все меры
        к&nbsp;урегулированию такого нарушения.
      </p>

      <p className="content--row">
        Если не указано иное, все товарные знаки на&nbsp;интернет-сайте, включая
        марки, наименования моделей,&nbsp; их&nbsp;логотипы и&nbsp;эмблемы,
        принадлежат их&nbsp;правообладателям.
      </p>

      <h2>4. Информация</h2>
      <p className="content--row">
        По вопросам размещения информации о&nbsp;новых автомобилях в наличии на
        складах в&nbsp; Москве обращайтесь{" "}
        <a href="mailto:info@ilsa.ru" target="_blank">
          info@ilsa.ru
        </a>
        .
      </p>

      <p className="content--row">
        Авторские права на&nbsp;дизайн, расположение материалов, всю информацию
        веб-сайта принадлежат ООО "Илса".
      </p>

      <p className="content--row">
        Использование отдельных материалов сайта в&nbsp;коммерческих и
        некоммерческих целях возможно&nbsp; только с&nbsp;письменного разрешения
        ООО "Илса". Вы можете отправить свою заявку по&nbsp; адресу&nbsp;
        <a href="mailto:info@ilsa.ru" target="_blank">
          info@ilsa.ru
        </a>
        .
      </p>

      <p className="content--row">
        Запрещается автоматизированное извлечение и&nbsp;копирование информации
        и любого другого контента&nbsp; сайта любыми сервисами и&nbsp;способами
        без&nbsp;официального разрешения ООО "Илса".
      </p>
    </div>
    <Footer />
  </div>
);

LegalPage.fetchData = dataContext => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(null, null, selectedRegions).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius || radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };

      resolve({
        isDataContext: true,
        regions,
      });
    });
  });
};

export default LegalPage;
