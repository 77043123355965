import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import noUiSlider from "nouislider";
import wNumb from "wnumb";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";

const PriceSlider = ({ min, max, priceRange, onChange }, ref) => {
  const sliderRef = useRef();
  useImperativeHandle(ref, () => ({
    reset: () => {
      sliderRef.current.noUiSlider.reset();
    },
  }));

  useEffect(() => {
    let range =
      isWidgetMode && widgetConfig.priceSliderRanges
        ? widgetConfig.priceSliderRanges.others
        : {
            "20%": [700000, 20000],
            "40%": [1000000, 50000],
            "60%": [1500000, 100000],
            "80%": [3000000, 1000000],
          };
    noUiSlider.create(sliderRef.current, {
      start: priceRange,
      connect: true,
      range: {
        min: [
          min,
          isWidgetMode && widgetConfig.priceSliderRanges
            ? widgetConfig.priceSliderRanges.min
            : 10000,
        ],
        ...range,
        max: [max],
      },
      tooltips: [
        wNumb({
          prefix: getLocalText("от "),
          suffix: getLocalText(" ₽"),
          thousand: " ",
          decimals: 0,
        }),
        wNumb({
          prefix: getLocalText("до "),
          suffix: getLocalText(" ₽"),
          thousand: " ",
          decimals: 0,
        }),
      ],
      format: wNumb({
        decimals: 0,
      }),
    });

    sliderRef.current.noUiSlider.on("change", value => {
      onChange(value);
    });
  }, []);

  useEffect(() => {
    let range =
      isWidgetMode && widgetConfig.priceSliderRanges
        ? widgetConfig.priceSliderRanges.others
        : {
            "20%": [700000, 20000],
            "40%": [1000000, 50000],
            "60%": [1500000, 100000],
            "80%": [3000000, 1000000],
          };

    sliderRef.current.noUiSlider.updateOptions({
      range: {
        min: [
          min,
          isWidgetMode && widgetConfig.priceSliderRanges
            ? widgetConfig.priceSliderRanges.min
            : 10000,
        ],
        ...range,
        max: [max],
      },
      start: priceRange,
    });
    sliderRef.current.noUiSlider.reset();
  }, [min, max]);

  return <div className="price-slider" ref={sliderRef} />;
};

export default forwardRef(PriceSlider);
