import React from "react";
import ReactDOM from "react-dom";
import { Link as RouterLink } from "react-router-dom";

import { parentUrl, isWidgetMode } from "utils/frameService";

class Link extends React.Component {
  handleInteract = () => {
    const { to } = this.props;
    if (!isWidgetMode || this.inited) {
      return;
    }

    const el = ReactDOM.findDOMNode(this);
    el.href = parentUrl + to;
    this.inited = true;
  };

  render() {
    const { to, ...props } = this.props;
    return (
      <RouterLink
        to={to}
        {...props}
        onMouseOver={this.handleInteract}
        onTouchStart={this.handleInteract}
      />
    );
  }
}

export default Link;
