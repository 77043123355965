import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import tippy from "tippy.js";

import Filter from "components/filters/filter";
import CleanAllButton from "components/shared/CleanAllButton";

import {
  getSelectedFilterParams,
  getContextRequestParamsFromFilters,
  declOfNum,
} from "utils";
import {
  isWidgetMode,
  widgetConfig,
  onScrollTo,
  scrollWindow,
  onHeightChange,
} from "utils/frameService";

import {
  DEFAULT_REGION_RADIUS,
  FILTER_LABEL_LIFETIME_IN_MS,
  MOBILE_WIDTH_MAX,
} from "app/constants";
import { useWindowSize } from "app/utils/windowSizeHook";

import TextButton from "components/ui/TextButton";

import "./styles.less";
import { OffersListContext } from "app/context/OffersList";
import { FiltersContext } from "app/context/Filters";
import { RegionsContext } from "app/context/Regions";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

let labelTimeOut = null;
let labelTargetElement = null;
let searchResultLabelInstance = null;
const SELECTORS_FOR_GET_PARENT_ELEMENT =
  ".s-value, .s-line, .c-filter-numbers-edge, .c-filter";

const Filters = props => {
  const {
    radius,
    selectedRegions,
    functions: regionsContextFunctions,
  } = useContext(RegionsContext);
  const {
    offersList,
    dealers,
    isPopupShown,
    functions: filtersContextFunctions,
  } = useContext(FiltersContext);
  const filtersList = {
    offersList,
    dealers,
  };
  const { sorting } = useContext(OffersListContext);
  const { isMobileWidth, isTabletWidth } = useWindowSize();

  useEffect(() => {
    return () => {
      removeSearchResultLabel();
      if (labelTimeOut) {
        clearTimeout(labelTimeOut);
      }
      labelTargetElement = null;
    };
  }, []);

  useEffect(() => {
    if (!isMobileWidth) {
      addSearchResultLabel();
      if (labelTimeOut) {
        clearTimeout(labelTimeOut);
      }
      labelTimeOut = setTimeout(() => {
        removeSearchResultLabel();
      }, FILTER_LABEL_LIFETIME_IN_MS);
    }
    /*
      props.results must be object because if totalCount not change we cant show label
      prevResults={prevTotalCount = 10}; results={totalCount = 10}; prevResults === results - false
    */
  }, [props.results]);

  useEffect(() => {
    if (isWidgetMode && isPopupShown) {
      let element = document.getElementsByClassName("filters-list")[0];
      if (element) {
        const newHeight = element.scrollHeight + 248;
        onHeightChange(
          newHeight < window.innerHeight ? window.innerHeight : newHeight,
        );
      }
    }
  }, [
    filtersList[props.filtersLocation].expanded,
    filtersList[props.filtersLocation].filterSet,
    isPopupShown,
  ]);

  //Search result label methods
  const addSearchResultLabel = () => {
    if (labelTargetElement) {
      let targetParentElement = getClosestElement(
        labelTargetElement,
        SELECTORS_FOR_GET_PARENT_ELEMENT,
      );

      searchResultLabelInstance = tippy(targetParentElement, {
        content: getLocalTextWithNumbers(
          "Найдено ${number} ${changeableText}",
          [
            {
              id: "number",
              value: props.results.totalCount,
            },
            {
              id: "changeableText",
              value: declOfNum(
                props.results.totalCount,
                props.isCarInsteadVariants
                  ? ["автомобиль", "автомобиля", "автомобилей"]
                  : ["вариант", "варианта", "вариантов"],
              ),
            },
          ],
        ),
        trigger: "manual",
        showOnCreate: true,
        placement: "left",
        theme: "search-result-label",
        zIndex: 1000,
      });
    }
  };

  const removeSearchResultLabel = () => {
    if (searchResultLabelInstance) {
      searchResultLabelInstance.unmount();
      searchResultLabelInstance = null;
    }
  };

  const getClosestElement = (htmlElement, selectors) => {
    let node = htmlElement.parentElement;
    while (node) {
      if (
        node.matches
          ? node.matches(selectors)
          : node.msMatchesSelector
          ? node.msMatchesSelector(selectors)
          : false
      ) {
        return node;
      } else node = node.parentElement;
    }
    return null;
  };

  //Filters methods
  const handleToggleFilter = filterId => {
    const { filtersLocation } = props;
    filtersContextFunctions.toggleFilter(filterId, filtersLocation);

    removeSearchResultLabel();
    if (labelTimeOut) {
      clearTimeout(labelTimeOut);
    }
  };

  const onValueClick = (filterId, valueId, eventTargetElement) => {
    const { filtersLocation } = props;

    removeSearchResultLabel();
    if (labelTimeOut) {
      clearTimeout(labelTimeOut);
    }

    const filters = filtersContextFunctions.selectFilter(
      filterId,
      valueId,
      filtersLocation,
    );
    const requestParams = getContextRequestParamsFromFilters({
      filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: filtersList[filtersLocation].filterSet,
    });

    labelTargetElement =
      filterId === "1101" || filterId === "1102"
        ? getClosestElement(
            eventTargetElement,
            SELECTORS_FOR_GET_PARENT_ELEMENT,
          )
        : eventTargetElement;
    props.onChange(requestParams);
  };

  const onUnSelectFilter = (filterId, eventTargetElement) => {
    const { filtersLocation } = props;

    removeSearchResultLabel();
    if (labelTimeOut) {
      clearTimeout(labelTimeOut);
    }

    const filters = filtersContextFunctions.unSelectFilter(
      filterId,
      filtersLocation,
    );

    const requestParams = getContextRequestParamsFromFilters({
      filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: filtersList[filtersLocation].filterSet,
    });

    labelTargetElement = eventTargetElement;
    props.onChange(requestParams);
  };

  const onSetNumbersEdge = (filterId, from, to, eventTargetElement) => {
    const { filtersLocation } = props;

    removeSearchResultLabel();
    if (labelTimeOut) {
      clearTimeout(labelTimeOut);
    }

    const filters = filtersContextFunctions.selectRange(
      filterId,
      from,
      to,
      filtersLocation,
    );

    const requestParams = getContextRequestParamsFromFilters({
      filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: filtersList[filtersLocation].filterSet,
    });

    labelTargetElement = eventTargetElement;
    props.onChange(requestParams);
  };

  const onDropFilters = () => {
    const { filtersLocation } = props;

    const filters = filtersContextFunctions.dropFilters(filtersLocation);

    const requestParams = getContextRequestParamsFromFilters({
      filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: filtersList[filtersLocation].filterSet,
    });

    props.onChange(requestParams);
  };

  const onToggleFilterSet = () => {
    const { filtersLocation } = props;

    const requestParams = getContextRequestParamsFromFilters({
      filters: filtersList[filtersLocation].filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: filtersList[filtersLocation].filterSet,
    });

    requestParams.filterSet =
      filtersList[filtersLocation].filterSet === "ALL" ? "BASIC" : "ALL";

    props.onChange(requestParams);
  };

  const onShowResults = () => {
    const { filtersLocation } = props;

    const requestParams = getContextRequestParamsFromFilters({
      filters: filtersList[filtersLocation].filters,
      sorting,
      selectedRegions,
      radius,
      filterSet: filtersList[filtersLocation].filterSet,
    });

    filtersContextFunctions.changeState(null, null, { isPopupShown: false });
    scrollWindow(0);
    if (isWidgetMode) {
      onScrollTo(0);
    }

    props.onChange(requestParams, true);
  };

  const {
    filtersLocation,
    selectedRegionPath,
    selectedBrandPath,
    selectedFilters,
    results,
    status,
    showFilterSetButton = false,
    showCleanButton = false,
  } = props;
  let filters = filtersList[filtersLocation].filters;

  if (filters.length === 0) {
    return null;
  }

  if (
    isWidgetMode &&
    widgetConfig &&
    widgetConfig.filtersList &&
    filtersLocation === "offersList"
  ) {
    const { selectedFiltersIdList, isAllEnable } = widgetConfig.filtersList;
    filters = filters.filter((filter, index) => {
      const isInFiltersList = selectedFiltersIdList.some(
        selectedFilterID => selectedFilterID === filter.id,
      );
      const isVisible = isAllEnable ? !isInFiltersList : isInFiltersList;
      if (!isVisible && filter.separate && filters[index + 1]) {
        filters[index + 1].separate = true;
      }
      return isVisible;
    });
  }

  return (
    <div className={isWidgetMode ? "c-filters widget" : "c-filters"}>
      <CleanAllButton
        itemsCount={selectedFilters.length}
        onClick={onDropFilters}
      />
      <div
        className={classNames(
          "s-content",
          !showFilterSetButton && "without-another-params",
        )}
      >
        <div className="filters-list">
          {filters.map(filter => (
            <Filter
              key={filter.id}
              filter={filter}
              selectedRegionPath={selectedRegionPath}
              selectedBrandPath={selectedBrandPath}
              isExpanded={
                filtersList[filtersLocation].expanded[filter.id] || false
              }
              onToggle={handleToggleFilter}
              onValueClick={onValueClick}
              onUnselectFilter={onUnSelectFilter}
              onSetNumbersEdge={onSetNumbersEdge}
            />
          ))}
        </div>
        {filters.length > 0 && (
          <div
            className="s-actions"
            style={{
              visibility: status === "SUCCESS" ? "visible" : "hidden",
            }}
          >
            {showFilterSetButton && (
              <div className="s-another-params">
                <TextButton
                  text={
                    filtersList[filtersLocation].filterSet === "ALL"
                      ? getLocalText("Популярные параметры")
                      : getLocalText("Другие параметры")
                  }
                  onClick={() => {
                    onToggleFilterSet();
                  }}
                />
              </div>
            )}
            <div className="s-bottom">
              <div className="s-buttons">
                {showCleanButton && (
                  <TextButton
                    className="clean-button"
                    text={getLocalText("Очистить")}
                    onClick={() => {
                      onDropFilters();
                    }}
                  />
                )}
                <TextButton
                  color="default"
                  className="show-button"
                  text={getLocalTextWithNumbers(
                    "Показать ${number} ${changeableText}",
                    [
                      {
                        id: "number",
                        value: results.totalCount,
                      },
                      {
                        id: "changeableText",
                        value: declOfNum(
                          results.totalCount,
                          props.isCarInsteadVariants
                            ? ["автомобиль", "автомобиля", "автомобилей"]
                            : ["вариант", "варианта", "вариантов"],
                        ),
                      },
                    ],
                  )}
                  onClick={() => {
                    onShowResults();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
