import React from "react";
import classNames from "classnames";

import "./styles.less";

const Pill = ({
  active = false,
  hideCloseBtn = false,
  onActive,
  onDelete,
  children,
  className,
}) => {
  const onActiveClick = () => {
    if (!active) {
      onActive();
    }
  };

  const onDeleteClick = () => {
    onDelete();
  };

  return (
    <div
      className={classNames("pill", className || "", {
        "pill--active": active,
        "hidden-close-btn": hideCloseBtn,
      })}
      onClick={onActiveClick}
    >
      {children}

      {active && !hideCloseBtn && (
        <span className="pill__delete" onClick={onDeleteClick}>
          <i className="icon icon-close" />
        </span>
      )}
    </div>
  );
};

export default Pill;
