import React from "react";

import {
  getContactBtnUrl,
  isWidgetMode,
  widgetConfig,
  isContactsModeEnable,
  sendParentEvent,
  getContactBtnData,
} from "app/utils/frameService";
import { useWindowSize } from "app/utils/windowSizeHook";
import {
  getLocalText,
  getLocalTextWithNumbers,
  getText,
} from "app/utils/i18nService";
import { getCreditData } from "app/utils/frameService";

import TextButton from "components/ui/TextButton";

import "./styles.less";

const CreditButton = ({
  offer,
  dealersPageUrl,
  lowPrice,
  children = null,
  useContactsMode = false,
}) => {
  const { isTabletWidth } = useWindowSize();
  const showWidgetButtons =
    isWidgetMode &&
    widgetConfig &&
    widgetConfig.contactBtn &&
    (!useContactsMode || (useContactsMode && !isContactsModeEnable));

  return (
    <div className="action-btn_container">
      {showWidgetButtons ? (
        <div className="credit-btn_container">
          <a
            className="widget-btn"
            target="_parent"
            href={getContactBtnUrl(offer, dealersPageUrl)}
            onClick={() => {
              sendParentEvent(
                "ilsa_contactBtnClick",
                getContactBtnData(offer, dealersPageUrl),
              );

              if (window.ilsaLayer) {
                window.ilsaLayer.push({
                  event: "universal",
                  category: "request",
                  action: "go",
                  label: "primary",
                });
              }
            }}
          >
            <TextButton
              color="orange"
              text={`${getLocalText(getText("common.dealersContacts"))}`}
            />
            {isTabletWidth && (
              <TextButton
                className="credit-price-btn"
                color="orange"
                text={`${getLocalText(getText("common.dealersContacts"))} ${
                  widgetConfig.creditPrice &&
                  widgetConfig.creditPrice.showCreditPrice
                    ? getLocalTextWithNumbers("от ${number} ₽/мес", [
                        {
                          id: "number",
                          value: getCreditData(lowPrice).price,
                        },
                      ])
                    : ""
                }`}
              />
            )}
          </a>
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default CreditButton;
