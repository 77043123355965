import React from "react";
import classNames from "classnames";

import "./styles.less";
import Tooltip from "components/ui/Tooltip";
import { getLocalText } from "app/utils/i18nService";

const Color = ({ tooltipContent = false, hex, className, ...props }) => {
  const color = (
    <span
      className={classNames(
        "color",
        !hex && "multicolor",
        hex === "#ffffff" && "white",
        className,
      )}
      style={{ ...(hex ? { backgroundColor: `${hex}` } : {}) }}
      {...props}
    />
  );
  return tooltipContent ? (
    <Tooltip content={getLocalText(tooltipContent)}>{color}</Tooltip>
  ) : (
    color
  );
};

export default Color;
