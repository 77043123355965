import React from "react";

import { getDealersPageUrl, getMapPageUrl } from "utils";
import { scrollWindow } from "app/utils/frameService";

import Link from "components/ui/Link";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

const tabs = [
  {
    id: "prices",
    label: "Цены",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getDealersPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        search,
      );
    },
  },
  {
    id: "map",
    label: "Карта",
    linkTo: (equipmentId, equipmentPath, selectedRegionName, search) => {
      return getMapPageUrl(
        equipmentId,
        equipmentPath,
        selectedRegionName,
        search,
      );
    },
  },
  {
    id: "short",
    label: "Комплектация",
    onClick: (props, tab) => {
      props.openTab(tab);
    },
  },
  {
    id: "technical",
    label: "Технические характеристики",
    onClick: (props, tab) => {
      props.openTab(tab);
    },
  },
];

const MobileTabsList = props => {
  const { offerId, offerPath, selectedRegion, search } = props;

  return (
    <div className="mobile-list">
      {tabs.map(tab => {
        return tab.linkTo ? (
          <Link
            key={tab.id}
            to={tab.linkTo(offerId, offerPath, selectedRegion, search)}
            className="nav-item"
            onClick={() => {
              scrollWindow(0);
            }}
          >
            <span>{getLocalText(tab.label)}</span>
            <div className="arrow" />
          </Link>
        ) : (
          <div
            onClick={() => {
              tab.onClick(props, tab.id);
            }}
            className="nav-item"
            key={tab.id}
          >
            <span>{getLocalText(tab.label)}</span>
            <div className="arrow" />
          </div>
        );
      })}
    </div>
  );
};

export default MobileTabsList;
