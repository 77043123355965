import React from "react";

import Price from "components/ui/Price";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";
import {isWidgetMode, widgetConfig} from "app/utils/frameService";

const PriceRange = ({ price }) =>
  !price || !price.low ? null : price.low === price.high ? (
    <div className="s-price">
      <div className="s-price-from">
        {isWidgetMode && widgetConfig.pricePrefix && (
          <span className="price-prefix">{widgetConfig.pricePrefix}</span>
        )}
        <span className="s-number margin-left-0">
          <Price value={price.low} />
        </span>
        {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
          <span className="s-currency">{getLocalText("₽")}</span>
        )}
      </div>
    </div>
  ) : (
    <div className="s-price">
      <div className="s-price-from">
        <span className="s-range-word">{getLocalText("от")}</span>
        {isWidgetMode && widgetConfig.pricePrefix && (
          <span className="price-prefix">{widgetConfig.pricePrefix}</span>
        )}
        <span className="s-number">
          <Price value={price.low} />
        </span>
        {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
          <span className="s-currency">{getLocalText("₽")}</span>
        )}
      </div>
      {price.high && (
        <div className="s-price-to">
          <span className="s-range-word">{getLocalText("до")}</span>
          {isWidgetMode && widgetConfig.pricePrefix && (
            <span className="price-prefix">{widgetConfig.pricePrefix}</span>
          )}
          <span className="s-number">
            <Price value={price.high} />
          </span>
          {(!isWidgetMode || (isWidgetMode && !widgetConfig.pricePrefix)) && (
            <span className="s-currency">{getLocalText("₽")}</span>
          )}
        </div>
      )}
    </div>
  );

export default PriceRange;
