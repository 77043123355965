import React from "react";
import classNames from "classnames";

import "./styles.less";

const IconButton = ({ icon, disabled = false, onClick, ...props }) => {
  return (
    <span
      {...props}
      className={classNames(
        "icon-button",
        disabled && "disabled",
        props.className && props.className,
      )}
      onClick={event => {
        if (!disabled && onClick) {
          onClick(event);
        }
      }}
    >
      <i className={`icon ${icon}`} />
    </span>
  );
};

export default IconButton;
