import React, { useState, useEffect } from "react";
import TextButton from "components/ui/TextButton";
import { LOCAL_STORAGE_INFO_NOTIFICATION_POPUP_KEY } from "app/constants";

import "./styles.less";

const InfoNotificationPopup = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(
      !JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_INFO_NOTIFICATION_POPUP_KEY),
      ),
    );
  });

  const onBtnClick = () => {
    setShow(false);
    window.localStorage.setItem(
      LOCAL_STORAGE_INFO_NOTIFICATION_POPUP_KEY,
      "true",
    );
  };

  if (!show) {
    return null;
  }

  return (
    <div className="info-notification-popup">
      <div className="text">{`Уважаемый посетитель сайта ilsa.ru! 
      Текущая ситуация на рынке не позволяет, к сожалению, предоставить Вам прозрачную информацию по стоимости автомобилей. 
      Мы по-прежнему показываем Вам цены, которые нам дали дилеры, но учитывайте, что в связи с дефицитом ходовых моделей 
      реальная цена может быть больше за счет установленных "допов" и прочих подобных нюансов.`}</div>
      <div className="buttonContainer">
        <TextButton
          className=""
          color="orange"
          text={`ОК`}
          onClick={onBtnClick}
        />
      </div>
    </div>
  );
};

export default InfoNotificationPopup;
