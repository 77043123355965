import Postmate from "postmate";
import elementResizeDetectorMaker from "element-resize-detector";
import { matchPath } from "react-router";

import { getQueryParams, removeURLParameter } from "utils";
import JsonToCss from "utils/jsonToCssService";

let parentPage = null;
export let parentUrl = null;
export let parentCookies = null;
export let isContactsModeEnable = false;
export let widgetConfig = null;
export let widgetConfigPromise = null;
export let parentScrollPosition = null;
export let localization = null;
let callingParentFuncInc = 0;
let widgetOnParentPageInitFinish = false;
let windowHistory = null;
let isGoByHistory = false;
let topMargin = 0;

let erd = null;
let element = null;

let prevPathname = null;
let prevSearch = null;

export let isIOS = null;
export let lastOffersIOSScrollPosition = 0;

export const isWidgetMode = (() => {
  try {
    //Detect SSR
    if (typeof window === "undefined") {
      return false;
    }
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();

export const createFrameHandshake = () => {
  return new Promise(async resolve => {
    isIOS =
      navigator &&
      !!navigator.platform &&
      /iPad|iPhone|iPod/.test(navigator.platform);

    document.body.classList.add("widget");

    parentPage = await new Postmate.Model({
      changeScroll: data => {
        parentScrollPosition = widgetConfig ? data.parentScroll + topMargin : 0;
        if (isIOS) {
          const body = document.getElementsByTagName("body")[0];
          body.style.position = "absolute";
          body.style.top = `-${data.scroll}px`;
          body.style.right = `0`;
          body.style.left = `0`;
          const matchOffersList =
            matchPath(
              location.pathname,
              "/:regionPath?/cars/new/:brand/:model?",
            ) || matchPath(location.pathname, "/:regionPath?/cars/new");
          if (matchOffersList && matchOffersList.isExact) {
            lastOffersIOSScrollPosition = data.scroll;
          }
        } else {
          window.scrollTo(0, data.scroll);
        }
      },
      replaceHistory: location => {
        windowHistory.replace(location);
      },
      pushHistory: location => {
        windowHistory.push(location);
      },
      callParentFuncCallback: callback => {
        let event = new CustomEvent(callback.eventName, {
          detail: callback.data,
        });
        window.dispatchEvent(event);
      },
      widgetOnParentPageInitFinish: flag => {
        widgetOnParentPageInitFinish = true;
      },
      getScroll: () => {
        onScrollTo(
          !isIOS ? window.scrollY + topMargin : lastOffersIOSScrollPosition,
        );
      },
      changeTopMargin: data => {
        topMargin = data;
      },
    });

    let interval = setInterval(
      () => {
        if (widgetOnParentPageInitFinish) {
          clearInterval(interval);
          parentCookies = parentPage.model.cookie;
          parentUrl = parentPage.model.parentUrl;
          isContactsModeEnable = parentPage.model.isContactsModeEnable;
          widgetConfigPromise = getWidgetConfig();
          widgetConfigPromise
            .then(async config => {
              widgetConfig = config;
              widgetConfigPromise = null;
              if (widgetConfig) {
                topMargin = widgetConfig.topMargin[0].margin;
                sendConfig({
                  topMargin: widgetConfig.topMargin,
                  autoScrollingToWidget: widgetConfig.autoScrollingToWidget,
                });

                const { clientCss } = widgetConfig;
                if (clientCss) {
                  let css = JsonToCss.toCSS(clientCss);
                  JsonToCss.toHEAD(css);
                }
                if (widgetConfig.localization) {
                  localization = await getLocalization(
                    widgetConfig.localization,
                  );
                }
              }
              resolve();
            })
            .catch(error => {
              sendConfig({
                topMargin: 0,
                autoScrollingToWidget: false,
              });
              resolve();
            })
        }
      },
      widgetOnParentPageInitFinish ? 0 : 5,
    );

    window.addEventListener("popstate", event => {
      isGoByHistory = true;
    });
  });
};

export const sendConfig = config => {
  parentPage.emit("getConfig", config);
};

export const onCookieChange = (name, value) => {
  parentPage.emit("changeCookie", { name, value });
};

export const onSessionStorageChange = (name, value) => {
  if (name === "ilsa_ReturnToContactsModeEnable" && value === "true") {
    isContactsModeEnable = true;
  }
  parentPage.emit("changeSessionStorage", { name, value });
};

export const onScrollTo = (
  countOfPixelsFromTop,
  disableIsManualScroll = false,
  isConsiderAddressBar = true,
  includeAndroidHack = false,
) => {
  parentPage.emit("scrollTo", {
    countOfPixelsFromTop,
    disableIsManualScroll,
    isConsiderAddressBar,
    includeAndroidHack,
  });
};

export const onHeightChange = height => {
  parentPage.emit("changeHeight", height);
};

export const callParentFunc = (functionName, functionParamsList) => {
  callingParentFuncInc += 1;
  const eventName = "callParentFuncEvent" + callingParentFuncInc;
  return new Promise(resolve => {
    window.addEventListener(
      eventName,
      event => {
        resolve(event.detail);
      },
      { once: true },
    );

    parentPage.emit("callParentFunction", {
      eventName,
      functionName,
      functionParamsList,
    });
  });
};

export const sendParentEvent = (eventName, eventData) => {
  parentPage.emit("createEvent", { eventName, eventData });
};

export const createResizeListener = () => {
  if (!erd) {
    erd = elementResizeDetectorMaker({
      strategy: "scroll",
    });
  } else {
    if (element) {
      erd.removeAllListeners(element);
    }
  }
  element = document.getElementById("main");
  if (
    element &&
    !element.classList.contains("not-found_page") &&
    document.getElementsByClassName("map").length === 0
  ) {
    erd.listenTo(element, () => {
      onHeightChange(element.scrollHeight + 50);
    });
  }
};

export const sendLocationChanged = location => {
  if (prevPathname !== location.pathname || prevSearch !== location.search) {
    let search = location.search;
    if (search.indexOf("disableSSR=true") !== -1) {
      search = removeURLParameter(search, "disableSSR");
    }
    let isGoByHistoryToOffersPage = false;
    if (isGoByHistory) {
      const firstMatch = matchPath(
        location.pathname,
        "/:regionPath?/cars/new/:brand/:model?",
      );
      const secondMatch = matchPath(
        location.pathname,
        "/:regionPath?/cars/new",
      );
      isGoByHistoryToOffersPage =
        (firstMatch && firstMatch.isExact) ||
        (secondMatch && secondMatch.isExact);
    }
    parentPage.emit("changeLocation", {
      isPushMode: prevPathname !== location.pathname,
      url: location.pathname + search,
      isGoByHistoryToOffersPage: isGoByHistoryToOffersPage,
    });
    prevPathname = location.pathname;
    prevSearch = search;
    isGoByHistory = false;
  }
};

export const getWidgetConfig = () => {
  return new Promise((resolve, reject) => {
    const xmlHttp = new XMLHttpRequest();
    let url = parentUrl
      .replace(/(^\w+:|^)\/\//g, "")
      .replace(/\/#(?!.*\/#)/g, "");
    xmlHttp.open("GET", `/getConfig?parentUrl=${url}`, false);
    xmlHttp.onload = () => {
      if (xmlHttp.status === 200) {
        resolve(JSON.parse(xmlHttp.response));
      } else {
        reject(
          xmlHttp.response
            ? xmlHttp.response
            : "Ошибка при загрузке конфигурационного файла",
        );
      }
    };
    xmlHttp.send(null);
  });
};

export const getLocalization = language => {
  return new Promise((resolve, reject) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", `/localization/${language}`, false);
    xmlHttp.onload = () => {
      if (xmlHttp.status === 200) {
        resolve(JSON.parse(xmlHttp.response));
      } else {
        reject(
          xmlHttp.response
            ? xmlHttp.response
            : "Ошибка при загрузке файла локализации",
        );
      }
    };
    xmlHttp.send(null);
  });
};

export const getContactBtnUrl = (offer, dealersUrl) => {
  let {
    url,
    defaultParams,
    customParams,
    returnToContactsModeEnable,
  } = widgetConfig.contactBtn;
  if (!url) {
    return null;
  }

  const splitUrl = url.split("#");
  url = splitUrl[0];
  const hashPart = splitUrl[1];
  let contactBtnUrl = `${url}${
    defaultParams.length > 0 || customParams.length > 0 ? "?" : ""
  }`;
  let dealersUrlParameter = null;
  if (defaultParams.length > 0) {
    defaultParams.forEach((param, index) => {
      if (index !== 0 && param !== "dealersUrl") {
        contactBtnUrl += "&";
      }
      switch (param) {
        case "brand":
          contactBtnUrl += `brand=${encodeURIComponent(offer.brand.name)}`;
          break;
        case "model":
          contactBtnUrl += `model=${encodeURIComponent(offer.model.name)}`;
          break;
        case "version":
          contactBtnUrl += `version=${encodeURIComponent(offer.versionName)}`;
          break;
        case "priceMin":
          contactBtnUrl += `price=${Math.floor(offer.offers.price.low)}`;
          break;
        case "photo":
          contactBtnUrl += `photo=${encodeURIComponent(
            offer.photo.replace("/x50/", "/").replace("/220x/", "/"),
          )}`;
          break;
        case "dealersUrl":
          let prefix = dealersUrl.indexOf("?") !== -1 ? "&" : "?";
          dealersUrlParameter = `dealersUrl=${encodeURIComponent(
            `${parentUrl}${dealersUrl}${
              returnToContactsModeEnable
                ? `${prefix}returnToContactsModeEnable=true/`
                : "/"
            }`,
          )}`;
          break;
      }
    });
  }
  if (customParams.length > 0) {
    customParams.forEach((customParam, index) => {
      if (index !== 0 || defaultParams.length > 0) {
        contactBtnUrl += "&";
      }
      switch (customParam.type) {
        case "constant":
          contactBtnUrl += `${customParam.name}=${customParam.value}`;
          break;
        case "coefficient":
          switch (customParam.defaultParamName) {
            case "priceMin":
              contactBtnUrl += `${customParam.name}=${Math.floor(
                offer.offers.price.low * customParam.value,
              )}`;
              break;
          }
          break;
        case "coefficientWithParam":
          switch (customParam.defaultParamName) {
            case "priceMin":
              contactBtnUrl += `${customParam.name}=${Math.floor(
                offer.offers.price.low * customParam.coefficient +
                  customParam.param,
              )}`;
              break;
          }
          break;
      }
    });
  }
  if (dealersUrlParameter) {
    let prefix =
      defaultParams.length > 0 || customParams.length > 0 ? "&" : "?";
    contactBtnUrl += `${prefix}${dealersUrlParameter}`;
  }
  return `${contactBtnUrl}${hashPart ? `#${hashPart}` : ""}`;
};

export const getContactBtnData = (offer, dealersUrl) => {
  let { returnToContactsModeEnable } = widgetConfig.contactBtn;
  let prefix = dealersUrl.indexOf("?") !== -1 ? "&" : "?";

  return {
    brand: offer.brand.name,
    model: offer.model.name,
    version: offer.versionName,
    price: Math.floor(offer.offers.price.low),
    photo: offer.photo
      .replace("/x50/", "/")
      .replace("/220x/", "/")
      .replace("/220/", "/"),
    dealersUrl: `${parentUrl}${dealersUrl}${
      returnToContactsModeEnable
        ? `${prefix}returnToContactsModeEnable=true/`
        : "/"
    }`,
  };
};

export const getCreditData = price => {
  let configPrice = widgetConfig.creditPrice.prices.find(item => {
    return price >= item.min && (price <= item.max || item.max === null);
  });
  let finishPrice = 0;
  switch (configPrice.type) {
    case "coefficient":
      finishPrice = (price * configPrice.value).toFixed();
      break;
    case "coefficientWithParam":
      finishPrice = (
        price * configPrice.coefficient +
        configPrice.param
      ).toFixed();
      break;
    default:
      finishPrice = price;
      break;
  }
  return {
    price: finishPrice,
    label: configPrice.label,
  };
};

export const getReturnToContactsModeEnable = () => {
  let url = window.location.search;
  return !!getQueryParams(url).returnToContactsModeEnable;
};

export const updateWindowHistory = history => {
  windowHistory = history;
};

export const scrollWindow = yPosition => {
  if (isIOS && isWidgetMode) {
    const body = document.getElementsByTagName("body")[0];
    body.style.top = `-${yPosition}px`;
  } else {
    window.scrollTo(0, yPosition);
  }
};
