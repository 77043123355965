import React from "react";
import classNames from "classnames";

import { declOfNum } from "utils";
import {
  getContactBtnUrl,
  isWidgetMode,
  widgetConfig,
} from "app/utils/frameService";

import Link from "components/ui/Link";
import PricesBlock from "components/shared/PricesBlock";
import CreditButton from "components/shared/CreditButton";
import OffersCount from "components/shared/PriceCard/OffersCount";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

const PriceCard = ({
  isDealersPage = false,
  offer,
  dealersPageUrl,
  btnComponent,
  filtersComponent,
  isFiltersPopupShown,
}) => {
  const price = offer.offers.price;
  const marketingPrice = offer.offers.marketingPrice;
  const offers = offer.offers;

  return (
    <div className="price-card">
      <div
        className={classNames(
          "price-card_text-container",
          isDealersPage && "hide-on-mobile",
        )}
      >
        <OffersCount
          offers={offers}
          dealersPageUrl={dealersPageUrl}
          isDealersPage={isDealersPage}
        />

        <div className="price-card_price-container">
          <PricesBlock price={price} marketingPrice={marketingPrice} isPriceCard={true}  />
        </div>
      </div>

      {offers.totalCount > 0 && (
        <CreditButton
          offer={offer}
          dealersPageUrl={dealersPageUrl}
          lowPrice={price.low}
          useCreditPriceBtn={false}
          useContactsMode={isDealersPage}
        >
          {btnComponent}
        </CreditButton>
      )}
      {(offers.totalCount > 0 || isDealersPage) &&
        (!isWidgetMode || widgetConfig.showDealersFilters) && (
          <div
            className={classNames(
              "filters-container",
              isFiltersPopupShown && "show-on-mobile",
            )}
          >
            {filtersComponent && (
              <div className="filters-container_label">{getLocalText('Фильтры')}</div>
            )}
            {filtersComponent}
          </div>
        )}
    </div>
  );
};

export default PriceCard;
