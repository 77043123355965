import React from "react";

import { isWidgetMode } from "utils/frameService";
import Link from "components/ui/Link";

import "./styles.less";

const Footer = ({ onClick }) => {
  const onLinkClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return !isWidgetMode ? (
    <footer className="footer">
      <Link className="link" to="/page/for_dealers" onClick={onLinkClick}>
        Дилерам
      </Link>
      <Link className="link" to="/page/contacts" onClick={onLinkClick}>
        Контакты
      </Link>
      <Link className="link" to="/page/privacy_policy" onClick={onLinkClick}>
        Политика конфиденциальности
      </Link>
      <Link className="link" to="/page/legal" onClick={onLinkClick}>
        Правовые аспекты
      </Link>
    </footer>
  ) : null;
};

export default Footer;
