import React from "react";
import Slider from "rc-slider";

import "./styles.less";
import { getLocalText } from "app/utils/i18nService";

const marks = {
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 500,
  5: 1000,
};

const Radius = ({ value, onChange }) => {
  const onChangeSliderValue = value => {
    value = String(value);
    for (let i in marks) {
      if (i === value) {
        onChange(marks[i]);
        break;
      }
    }
  };

  let newValue = 0;
  for (let i in marks) {
    if (marks[i] === value) {
      newValue = Number(i);
      break;
    }
  }

  return (
    <div className="s-radius">
      <div className="s-title">{getLocalText("радиус поиска (км)")}</div>
      <div className="s-range">
        <Slider
          max={5}
          min={0}
          value={newValue}
          onChange={onChangeSliderValue}
          step={null}
          marks={marks}
        />
      </div>
    </div>
  );
};

export default Radius;
