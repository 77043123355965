import React, { useEffect, useRef } from "react";
import Slider from "react-slick";

import CarouselArrow from "components/shared/CarouselArrow";
import Color from "components/ui/Color";
import CarImage from "components/ui/CarImage";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";
import { declOfNum } from "utils";

const MainCarousel = ({ slides }) => {
  const sliderRef = useRef();
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [slides.length]);

  const config = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    prevArrow: <CarouselArrow direction="left" />,
    nextArrow: <CarouselArrow direction="right" />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getColorsPostFix = number => {
    return number === 1 ? "а" : "ов";
  };

  if (!slides || !slides.length) return null;
  return (
    <div
      className={slides.length === 1 ? "main-carousel single" : "main-carousel"}
    >
      <Slider {...config} ref={sliderRef}>
        {slides.map((slide, index) => {
          return (
            <div key={slide.name}>
              <div className="slide-title">
                {getLocalTextWithNumbers(
                  "${number} из ${number2} ${changeableText}",
                  [
                    {
                      id: "number",
                      value: index + 1,
                    },
                    {
                      id: "number2",
                      value: slides.length,
                    },
                    {
                      id: "changeableText",
                      value: slides.length === 1 ? "цвета" : "цветов",
                    },
                  ],
                )}
              </div>
              <div className="slide-image">
                <CarImage src={slide.picture} />
              </div>
              <div className="slide-description">
                <Color hex={slide.hex} />
                <span className="slide-text">
                  {`${getLocalText(slide.name)}${
                    slides.some(
                      item =>
                        item.name === slide.name && item.code !== slide.code,
                    )
                      ? ` (${slide.code})`
                      : ""
                  }`}
                </span>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default MainCarousel;
