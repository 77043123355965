import React from "react";

import { declOfNum } from "utils";
import {
  getContactBtnUrl,
  isWidgetMode,
  widgetConfig,
} from "app/utils/frameService";

import Link from "components/ui/Link";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const OffersCount = ({ offers, dealersPageUrl, isDealersPage }) => {
  const offerCountText = getLocalTextWithNumbers(
    "${totalCount} ${changeableText} от ${dealerCount} ${changeableText2}",
    [
      {
        id: "totalCount",
        value: offers.totalCount,
      },
      {
        id: "changeableText",
        value: declOfNum(offers.totalCount, [
          "автомобиль",
          "автомобиля",
          "автомобилей",
        ]),
      },
      {
        id: "dealerCount",
        value: offers.dealerCount,
      },
      {
        id: "changeableText2",
        value: declOfNum(offers.dealerCount, ["дилера", "дилеров", "дилеров"]),
      },
    ],
  );

  return (
    <div className="offers-count">
      {offers.totalCount > 0 ? (
        <>
          {isWidgetMode &&
          widgetConfig &&
          widgetConfig.contactBtn &&
          !isDealersPage ? (
            <Link className="link" to={dealersPageUrl}>
              {offerCountText}
            </Link>
          ) : (
            offerCountText
          )}
        </>
      ) : (
        <div className="zero-results">{getLocalText("нет в продаже")}</div>
      )}
    </div>
  );
};

export default OffersCount;
