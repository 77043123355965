import React from "react";

import { declOfNum, removeURLParameter } from "utils";

import TextButton from "components/ui/TextButton";

import "./styles.less";
import { getLocalText, getLocalTextWithNumbers } from "app/utils/i18nService";

const SelectedDealers = ({
  selectedDealers = [],
  title,
  equipment,
  onBookDealer,
  unSelectAllDealers,
  history,
}) => {
  const selectedNumber = selectedDealers.length;

  const getPostFix = number => {
    return number === 1 ? "" : number <= 4 ? "а" : "ов";
  };

  const titlePostFix = number => {
    return number === 1 ? "" : "о";
  };

  const removeSelectedDealersFromUrl = () => {
    let location =
      removeURLParameter(window.location.search, "selectedDealers") ||
      window.location.pathname;
    history.replace(location);
  };

  return (
    <div className="selected-dealers">
      <div className="s-info">
        {selectedNumber > 0
          ? getLocalTextWithNumbers(
              "${selectedText} ${number} ${changeableText} для заявки на обратный звонок",
              [
                {
                  id: "selectedText",
                  value: selectedNumber === 1 ? "Выбран" : "Выбрано",
                },
                {
                  id: "number",
                  value: selectedNumber,
                },
                {
                  id: "changeableText",
                  value: declOfNum(selectedNumber, [
                    "дилер",
                    "дилера",
                    "дилеров",
                  ]),
                },
              ],
            )
          : getLocalText("Выберите дилеров")}
      </div>

      <div>
        <TextButton
          text={getLocalText("Заказать звонок")}
          color={selectedNumber > 0 ? "orange" : ""}
          disabled={!(selectedNumber > 0)}
          onClick={() => {
            if (selectedNumber !== 0) {
              onBookDealer(equipment.id, selectedDealers, title);
            }
          }}
        />
      </div>
      <div>
        <TextButton
          text={getLocalText("Очистить")}
          disabled={!(selectedNumber > 0)}
          onClick={() => {
            if (!selectedNumber) {
              return;
            }
            removeSelectedDealersFromUrl();
            unSelectAllDealers();
          }}
        />
      </div>
    </div>
  );
};

export default SelectedDealers;
