import React from "react";

import graphqlClient from "app/graphql/services/client";
import { getMainPageData } from "app/graphql/queries";
import { REQUEST_STATUS } from "app/constants";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";

export const MainPageContext = React.createContext();

class Main extends React.Component {
  isResetData = false;
  state = {
    dealersCount: this.props.dealersCount || null,
    brandsCount: this.props.brandsCount || null,
    regionsCount: this.props.regionsCount || null,
    defaultTotalCount: this.props.totalCount || null,
    defaultOfferCount: this.props.offerCount || null,
    totalCount: this.props.totalCount || null,
    offerCount: this.props.offerCount || null,
    defaultFiltersList: this.props.filtersList || [],
    priceFilter: this.props.priceFilter || null,
    filtersList: this.props.filtersList || [],
    selectedPriceRange: this.props.selectedPriceRange || null,
    status: this.props.status || REQUEST_STATUS.NONE,
  };

  getData = vars => {
    const {
      dealersCount,
      brandsCount,
      regionsCount,
      defaultTotalCount,
      defaultOfferCount,
      defaultFiltersList,
      selectedPriceRange,
    } = this.state;
    // this.setState({ status: REQUEST_STATUS.LOADING });
    this.isResetData = false;
    loadMainPageData(vars).then(
      response => {
        if (!this.isResetData) {
          const brandFilter = response.carsData.filters.find(
            filter => filter.id === "1101",
          );
          const priceFilter = response.carsData.filters.find(
            filter => filter.id === "1201",
          );
          const minFloor =
            isWidgetMode && widgetConfig.priceSliderRanges
              ? widgetConfig.priceSliderRanges.minFloor
              : 10000;
          const maxCeil =
            isWidgetMode && widgetConfig.priceSliderRanges
              ? widgetConfig.priceSliderRanges.maxCeil
              : 1000000;
          this.setState({
            dealersCount: dealersCount || response.statistic.dealerCount,
            brandsCount: brandsCount ? brandsCount : brandFilter.values.length,
            regionsCount: regionsCount || response.statistic.regionCount,
            priceFilter: {
              min: Math.floor(priceFilter.min / minFloor) * minFloor,
              max: Math.ceil(priceFilter.max / maxCeil) * maxCeil,
            },
            selectedPriceRange: selectedPriceRange
              ? selectedPriceRange
              : {
                  min: Math.floor(priceFilter.min / minFloor) * minFloor,
                  max: Math.ceil(priceFilter.max / maxCeil) * maxCeil,
                },
            defaultTotalCount: defaultTotalCount
              ? defaultTotalCount
              : response.carsData.search.totalCount,
            defaultOfferCount: defaultOfferCount
              ? defaultOfferCount
              : response.carsData.search.offerCount,
            totalCount: response.carsData.search.totalCount,
            offerCount: response.carsData.search.offerCount,
            defaultFiltersList:
              defaultFiltersList.length !== 0
                ? defaultFiltersList
                : response.carsData.filters,
            filtersList: response.carsData.filters,
            status: REQUEST_STATUS.SUCCESS,
          });
        }
      },
      () => {
        this.setState({ status: REQUEST_STATUS.FAIL });
      },
    );
  };

  resetStatus = () => {
    this.setState({ status: REQUEST_STATUS.NONE });
  };

  resetData = defaultPriceRange => {
    const {
      defaultTotalCount,
      defaultOfferCount,
      defaultFiltersList,
    } = this.state;

    this.isResetData = true;
    this.setState({
      filtersList: defaultFiltersList,
      totalCount: defaultTotalCount,
      offerCount: defaultOfferCount,
      selectedPriceRange: defaultPriceRange,
      defaultFiltersList: defaultFiltersList,
    });
  };

  updateSelectedPrice = priceRange => {
    this.setState({
      selectedPriceRange: { min: priceRange[0], max: priceRange[1] },
    });
  };

  render() {
    return (
      <MainPageContext.Provider
        value={{
          ...this.state,
          functions: {
            getData: this.getData,
            resetStatus: this.resetStatus,
            resetData: this.resetData,
            updateSelectedPrice: this.updateSelectedPrice,
          },
        }}
      >
        {this.props.children}
      </MainPageContext.Provider>
    );
  }
}

export const loadMainPageData = vars => {
  return graphqlClient.fetch(getMainPageData, vars);
};

export const MainPageProvider = Main;
