import React from "react";
import classNames from "classnames";

import FilterRange from "./filter-range";
import FilterSelectedValue from "./filter-selected-value";
import FilterValues from "./filter-values";
import IconButton from "components/ui/IconButton";

import { getText } from "utils/i18nService";

import { DATE_FILTERS_ID } from "app/constants";

import "./styles.less";
import {getLocalText} from "app/utils/i18nService";

export default class Filter extends React.PureComponent {
  state = {
    cleanPrice: false,
  };

  dropFilter = event => {
    const { filter } = this.props;

    this.props.onUnselectFilter(filter.id, event.target);
  };

  toggleFilter = () => {
    const { filter } = this.props;

    this.props.onToggle(filter.id);
  };

  render() {
    const {
      filter,
      isExpanded,
      selectedRegionPath,
      selectedBrandPath,
    } = this.props;

    let isOnceValue = filter.values
      ? filter.values.filter(value => value.count > 0).length <= 1
      : filter.max === filter.min;

    let filterContent;
    switch (filter.__typename) {
      case "EnumFilter":
        filterContent = (
          <FilterValues
            filter={filter}
            onUnselectFilter={this.props.onUnselectFilter}
            onValueClick={this.props.onValueClick}
            selectedRegionPath={selectedRegionPath}
            selectedBrandPath={selectedBrandPath}
          />
        );
        break;

      case "NumberFilter":
        filterContent = (
          <FilterRange
            filterId={filter.id}
            format={!DATE_FILTERS_ID.some(item => item === filter.id)}
            min={filter.min}
            max={filter.max}
            precision={filter.precision}
            from={filter.from}
            to={filter.to}
            onSetNumbersEdge={this.props.onSetNumbersEdge}
          />
        );
        break;
    }

    return (
      <fieldset
        className={classNames("c-filter", { separate: filter.separate })}
      >
        <div
          className={classNames("s-line", { selected: filter.selected })}
          onClick={this.toggleFilter}
        >
          <div className="clear-btn_container">
            <IconButton
              icon="icon-clear"
              onClick={event => this.dropFilter(event)}
            />
          </div>

          <div
            className={classNames("s-title-block", isOnceValue && "disabled")}
          >
            <div>
              <p>{getLocalText(getText(`offersList.filters.${filter.id}`, filter.name))}</p>
            </div>

            <FilterSelectedValue filter={filter} />
          </div>
          <div className="expand-btn_container">
            <IconButton
              icon={isExpanded ? "icon-down" : "icon-next"}
            />
          </div>
        </div>

        <div className={classNames("s-list", { active: isExpanded })}>
          {filterContent}
        </div>
      </fieldset>
    );
  }
}
