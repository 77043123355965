import React from "react";
import classNames from "classnames";
import Link from "components/ui/Link";

import "./styles.less";

const TextButton = ({
  color,
  loading = false,
  disabled = false,
  text = "",
  onClick = null,
  url = null,
  ...props
}) => {
  return (
    <span
      {...props}
      className={classNames(
        "text-button",
        color === "default" && "default",
        color === "orange" && "orange",
        color === "gray" && "gray",
        disabled && "disabled",
        props.className && props.className,
      )}
      onClick={() => {
        if (!disabled && !loading && onClick) {
          onClick();
        }
      }}
    >
      {url ? <Link to={url}>{text}</Link> : text}
      {loading && (
        <div className="loading_container">
          <img src={require("loader.gif").default} />
        </div>
      )}
    </span>
  );
};

export default TextButton;
