import { widgetConfig } from "./frameService";

/**
 * Get filter expressions combined with widget expressions
 * @param expressions from filters
 * @param geo from filters
 */
export const getDefaultExpressionsAndRegions = (expressions = []) => {
  let expressionsList = [];
  if (widgetConfig) {
    expressionsList = widgetConfig.filtersList.filtersList
      .filter(filter => {
        return filter.expressionsList.length > 0 && !filter.isDomainFilter;
      })
      .map(filter => {
        return `${filter.filterId}=${filter.expressionsList.toString()}`;
      });

    expressionsList.forEach(expressionFromConfig => {
      const expressionId = expressionFromConfig.slice(
        0,
        expressionFromConfig.indexOf("="),
      );
      if (
        !expressions.some(
          expression =>
            expression.slice(0, expression.indexOf("=")) === expressionId,
        )
      ) {
        expressions.push(expressionFromConfig);
      }
    });
  }
  return {
    expressionsList,
    expressions,
  };
};

/**
 * Get get domain filters from widget config
 */
export const getDefaultDomainFilters = customWidgetConfig => {
  let domain = [];
  let conditionData = {};
  const domainGeo = { ids: [] };
  const _widgetConfig = customWidgetConfig || widgetConfig;

  if (_widgetConfig) {
    domain = _widgetConfig.filtersList.filtersList
      .filter(filter => {
        return filter.expressionsList.length > 0 && filter.isDomainFilter;
      })
      .map(filter => {
        return {
          key: filter.filterId,
          values: filter.expressionsList,
          inverse: filter.isDomainInverse,
        };
      });

    if (_widgetConfig.regionFilter && !_widgetConfig.regionFilter.isAllEnable) {
      domainGeo.ids = _widgetConfig.regionFilter.selectedFiltersIdList;
    }

    if (_widgetConfig.condition) {
      conditionData = {
        condition: _widgetConfig.condition,
      };
    }
  }
  return {
    filters: domain,
    region: domainGeo,
    ...conditionData,
  };
};
